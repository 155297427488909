import React from "react";
import {AddAlert, RemoveRedEye}from "@material-ui/icons";
import {connect} from 'react-redux';
import {Tooltip} from "@material-ui/core";

import Header from "../../components/Header/Header";
import history from "../../history";
import {BrandLink} from "../../components/BrandLink";
import ScrutineerHeaderLinks from "../../components/Header/ScrutineerHeaderLinks";
import Loading from "../../components/Loading";
import ProfileModal from "../../components/Modals/ProfileModal";
import Footer from "../../components/Footer/FooterMain";
import Snackbar from "../../components/Snackbar/Snackbar";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Card from '../../components/Card/Card';
import CardHeader from "../../components/Card/CardHeader";
import CardBody from "../../components/Card/CardBody";
import Table from "../../components/Table/Table";
import Button from "../../components/CustomButtons/Button";
//actions
import {setEvent} from "../../Store/Actions/Scrutineer/EventActions";
import {updateProfile,checkLogin,logUserOut} from "../../Store/Actions/Auth";
import {chairGetEvents} from "../../Store/Actions/Chairperson";


class Chairperson extends React.Component{

    state={
        loading:false,
        profileModal:false,
        notif:false,
        notifMsg:'',
        search:''
    };

    componentWillMount() {
        const {role, isSignedIn}=this.props;

        if(!isSignedIn){
            this.props.checkLogin('chairperson',(res)=>{
                res.status !==1 && role!=='chairperson' ? history.push('/')  :this.props.chairGetEvents();
            });
        }else{
            this.props.chairGetEvents();
        }

    }

    onSubmit= (formValues)=> {
        this.setState({loading: true});
        formValues.role = this.props.role;
        this.props.updateProfile(formValues, (res) => {
            res.status === 1 ?
                this.setState({
                    loading: false,
                    modal: false,
                    error: '',
                    notif: true,
                    notifMsg: 'Profile Updated Successfully'
                }) : this.setState({loading: false, error: res.msg});
        });
    };

    openEvent=(id, title)=>{
        this.props.setEvent(id, title, ()=>history.push('/chairperson/event/dashboard'))
    };

    renderEvents=()=>{
        if(this.props.events !== null){
            const events = this.props.events;
            const search = this.state.search.toLocaleLowerCase();
            const tableData = Object.keys(events).reverse().reduce((a,v)=>{

                a.push([
                    events[v].title,
                    events[v].date,
                    events[v].scrutineer,
                    events[v].organization,
                    events[v].organizer,
                    events[v].status.toUpperCase(),
                    (<div>
                        <Tooltip title={'Open Event'}>
                            <Button onClick={()=>{this.openEvent(v,events[v].title)}}  justIcon size='sm' round color={'info'}><RemoveRedEye/></Button>
                        </Tooltip>
                    </div>)
                ]);

                return a;
            },[]);
            const tableHead=['Title','Date','Scrutineer','Organization','Organizer','Status','Actions'];
            return (
                <Table
                    tableHeaderColor={'info'}
                    tableData={tableData}
                    tableHead={tableHead}
                />
            )

        }else{
            return (
                <div>
                    No Events have been allocated to you yet.
                </div>
            )
        }
    };

    render(){
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={this.state.loading}
                />
                <ProfileModal
                    isVisible={this.state.profileModal}
                    heading={'Scrutineer Profile'}
                    onClose={()=>this.setState({profileModal:false, error:''})}
                    errorMessage={this.state.error}
                    profileData={{email:this.props.email,firstName:this.props.firstName, lastName:this.props.lastName}}
                    onSubmit={this.onSubmit}
                />
                <Header
                    absolute
                    color={"white"}
                    brand={<BrandLink/>}
                    rightLinks={
                        <ScrutineerHeaderLinks
                            profileClick={()=>this.setState({profileModal:true})}
                            signOutClick={()=>this.props.logUserOut(()=>history.push('/'))}
                        />
                    }
                    {...rest}
                />
                <GridContainer>
                    <GridItem xs={1}/>
                    <GridItem xs={10}>
                        <GridContainer>
                            <GridItem xs={12} style={{marginTop:60, justifyContent:'center'}} >
                                <h3>Welcome back {this.props.firstName}</h3>
                            </GridItem>
                            <GridItem xs={12}>
                                <Card>
                                    <CardHeader color={'info'}>
                                        <h3>Events</h3>
                                    </CardHeader>
                                    <CardBody>
                                        {this.renderEvents()}
                                    </CardBody>
                                </Card>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={1}/>
                </GridContainer>
                <Footer/>
            </div>
        )
    }
}
const mapStateToProps =state=>{
    const {isSignedIn, firstName,lastName,email,role}=state.Auth;

    return ({
        events:state.events.events,
        isSignedIn,
        firstName,
        lastName,
        email,
        role
    })
};

export default connect(mapStateToProps,{
    updateProfile,
    checkLogin,
    logUserOut,
    chairGetEvents,
    setEvent
})(Chairperson);
