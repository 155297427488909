import {auth, database}from '../../../Firebase';
import {AUTH_LOGIN, AUTH_LOGOUT, AUTH_SIGN_IN, AUTH_UPDATE} from "../../types";


export const authRegister =(formValues,callback)=>dispatch=>{
    auth.createUserWithEmailAndPassword(formValues.email,formValues.password)
        .then(user=>{
            const ref = database.ref(`users/${formValues.role}/${user.user.uid}`);
            ref.update({
                firstName:formValues.firstName,
                lastName:formValues.lastName,
                email:formValues.email,
                uid:user.user.uid,
                status:'disabled',
                country:formValues.country,
                created: Date.now(),
                lastLogin:Date.now()
            });
            callback({status:1,success:'Registration Successful, after assessment our team will be in touch.'})

        })
        .catch(err=>{
            if(err){
                callback({status:2,errMsg:err.message})
            }
        })
};

export const authLogin = (formValues, callback) => dispatch =>{
    auth.signInWithEmailAndPassword(formValues.email, formValues.password)
        .then(user=>{
            const role = formValues.role;
            const ref = database.ref(`users/${role}`);
            ref.once('value').then(snap=>{
                const rolePlayers = snap.val();

                //first Identify id user is signing up for the correct role
                if(Object.keys(rolePlayers).includes(user.user.uid)){
                    // Then check if user is enabled
                    const UserID = user.user.uid;
                    if(rolePlayers[UserID].status==='enabled'){
                        // Then update the users last login
                        const ref = database.ref(`users/${role}/${UserID}`);
                        ref.update({
                            lastLogin:Date.now()
                        })
                            //Then send the users details to store
                            .then(()=>{
                                const {firstName, lastName, country}=rolePlayers[UserID];
                                dispatch({
                                    type:AUTH_LOGIN,
                                    payload:{
                                        uid:user.user.uid,
                                        role,
                                        email:user.user.email,
                                        firstName,
                                        lastName,
                                        country
                                    }});
                                callback({status:1,msg:'Login Success',role:role});
                            })
                            .catch(err=>err?callback({status:2,msg:err.message}):null);
                    }
                    else{
                        callback({status:2,msg:'Your account has not been approved yet!'});
                    }
                }
                else{
                    callback({status:2,msg:'You have not been registered as a '+role});
                }
            });
        }).catch(err=>{
        callback({status:2,msg:err.message})
    })
};

export const updateProfile = (formValues, callback)=>dispatch=>{

    let user = auth.currentUser;
    const {firstName, lastName, email,country, role, uid}=formValues;

    user.updateEmail(email)
        .then(()=>{
            const ref = database.ref(`users/${role}/${uid}`);

            ref.update({
                firstName,
                lastName,
                country,
                updated:Date.now()
            }).then(()=>{
                dispatch({
                    type:AUTH_SIGN_IN,
                    payload:{
                        uid:user.uid,
                        role,
                        email:user.email,
                        firstName,
                        lastName,
                        country
                    }});
                callback({status:1,msg:'Profile Updated Successfully'})
            }).catch(err=>callback({status:2,msg:err.message}))
        }).catch(err=>callback({status:2,msg:err.message}));
};

export const checkLogin =(role, callback)=>dispatch=>{
    auth.onAuthStateChanged((user)=>{
        if(user){
            const ref = database.ref(`users/${role}`);
            ref.once('value')
                .then(snap=> {

                const snapShot = snap.val();


                if(Object.keys(snapShot).includes(user.uid)){
                    const people = user.uid;
                    if (snapShot[people].status === 'enabled') {
                        const ref = database.ref(`users/${role}/${user.uid}`);
                        ref.update({
                            lastLogin: Date.now()
                        }, err => {
                            if (err) {
                                callback({status: 2, msg: err.message});
                            }
                        });
                        const {firstName, lastName, country} = snapShot[people];

                        dispatch({
                            type: AUTH_LOGIN,
                            payload: {
                                uid: user.uid,
                                role,
                                email: user.email,
                                firstName,
                                lastName,
                                country
                            }
                        });
                        callback({status: 1, msg: 'Login Success'});
                    } else {
                        callback({status: 2, msg: 'Your account has not been approved yet!'})
                    }
                }else{
                    callback({status: 2, msg: 'You are not registered as a'+role})
                }
            });
        }
        else{
            callback({status:2})
        }
    })
};

export const logUserOut =(callback)=>dispatch=>{
    auth.signOut().then(()=>{
        dispatch({type:AUTH_LOGOUT});
        callback();
    })
};

export const forgotPassword=(formValues,callback)=>dispatch=>{

    auth.sendPasswordResetEmail(formValues.email)
        .then(()=>callback({status:1}))
        .catch(err=>callback({status:2,msg:err.message}))
};
