import React from 'react';
import Transition from "../../Functions/Transistion";
import withStyles from "@material-ui/core/styles/withStyles";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Button from "../CustomButtons/Button";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {connect} from 'react-redux';
import ReactSelectMaterialUi from "react-select-material-ui";
import CustomInput from "../CustomInput/CustomInput";
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import {Publish,Close,NoteAdd} from '@material-ui/icons';
import {InputAdornment} from "@material-ui/core";
import Danger from "../Typography/Danger";

const ballroomDance=['Waltz','Tango','Viennese waltz','Foxtrot','Quickstep','Argentine Tango'];
const latinDances =['Cha Cha', 'Samba', 'Rhumba', 'Paso Doble','Jive','Salsa',];
const singleDances=['Contemporary','Freestyle', 'Hip Hop','Slow', 'Bhangra','Disco','Kizomba','Boogie','Sokkie','West swing',];
const singleDances2=['Rock n Roll','Traditional', 'Jazz', 'Street','Show', 'solo','Bachata','east swing','mambo','Merengue'];
const specialDances=['exhibition','solo spot','showcase','showdance'];

//functions
class RoundModal extends React.Component{

    state={
        selectedDances:[],
        roundType:'',
        panel:'',
        itemNo:'',
        status:'',
        callBackLimit:'',
        error:'',
        editMode:false,
        addMode:false,
        otherDance:''
    };

    setMode=()=>{
        if(!this.state.editMode){
            if(this.props.roundData !== null){
                const {dances, roundType,panel,itemNo,status,callbackLimit}=this.props.roundData;
                // console.log(this.props.roundData);
                let actualPanel='';
                if(panel!==undefined){
                    actualPanel=panel.panel
                }
                this.setState({
                    selectedDances:dances,
                    roundType:roundType,
                    panel:actualPanel,
                    itemNo:itemNo,
                    status:status,
                    callBackLimit:callbackLimit,
                    editMode:true,
                    addMode:false
                })
            }else{
                if(!this.state.addMode){
                    this.setState({
                        selectedDances:[],
                        roundType:'',
                        panel:'',
                        itemNo:'',
                        status:'not-started',
                        callBackLimit:'',
                        error:'',
                        editMode:false,
                        addMode:true
                    })
                }
            }
        }
    };

    addDance=(dance)=>{
        const selectedDances = this.state.selectedDances;
        const {type}=this.props.sectionData;
        if(type !=='multiDance'){
            if(!selectedDances.includes(dance)){
                selectedDances.push(dance);
                this.setState({selectedDances,error:''})
            }else{
                const newArray = selectedDances.filter(val=>{
                    if(val !== dance){
                        return val
                    }
                });
                this.setState({selectedDances:newArray, error:''})
            }
        }else{
            if(selectedDances[0]===dance){
                this.setState({selectedDances: [],error:''})
            }else{
                this.setState({selectedDances:[dance],error:''});
            }

        }

    };

    renderButtons=(array)=>{
        return array.map(dance=>{
            return(
                <Button key={dance} onClick={()=>this.addDance(dance)} block size={'sm'}  round color={this.state.selectedDances.includes(dance)?'success':'info'} >
                    {dance}
                </Button>
            )
        })
    };

    renderPanel=()=>{
        const panels = this.props.panels;
        if(panels !== null && panels !== undefined){
            const panelOptions = Object.keys(panels).reduce((a,v, index)=>{

                a.push(index+1 +'-'+ Object.keys(panels[v]).reduce((ar,val)=>{
                    ar.push(panels[v][val].letter);
                    return ar;
                },[]));
                return a;
            },[]);
            return (
                <ReactSelectMaterialUi
                    defaultValue=''
                    label='Select Panel'
                    value={this.state.panel}
                    options={panelOptions}
                    style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                    onChange={this.panelChange}
                />
            )

        }
    };

    onTextChange=name=>event=>{
        this.setState({[name]:event.target.value, error:''})
    };
    roundTypeChange=(value)=>{
        this.setState({roundType:value, error:''});
    };
    panelChange = (value)=>{
        this.setState({panel:value, error:''})
    };
    statusChange=(value)=>{
        this.setState({status:value, error:''})
    };

    onSave=()=>{
        const {selectedDances, roundType, panel, itemNo,status, error,callBackLimit}=this.state;
        this.props.dancers >0?
            error ===''?
                selectedDances.length >0?
                    roundType!==''?
                        panel !==''?
                            itemNo !==''?
                                roundType === 'Final' || roundType==='Uncontested'|| roundType ==='Contested'?
                                    this.props.onSave({selectedDances, roundType, panel ,itemNo,status, callBackLimit})
                                    : callBackLimit !==''?
                                    this.props.onSave({selectedDances, roundType, panel,itemNo,status, callBackLimit})
                                    :this.setState({error: 'Please add a callback limit'})
                                :this.setState({error: 'Please add an item no!'})
                            :this.setState({error: 'Please select a panel!'})
                        :this.setState({error: 'Please select a round type!'})
                    : this.setState({error:'Please select a dance!'})
                :this.setState({error:'Cannot Submit error - '+error})
            :this.setState({error:'Please add dancers before creating rounds'});

        if(error ===''){
            this.setState({
                selectedDances:[],
                roundType:'none',
                panel:'none',
                itemNo:'',
                status:'not-started',
                callBackLimit:'',
                error:'',
                editMode:false,
                addMode:false
            });
        }

    };

    onClose=()=>{
        this.setState({
            selectedDances:[],
            roundType:'none',
            panel:'none',
            itemNo:'',
            status:'not-started',
            callBackLimit:'',
            error:'',
            editMode:false,
            addMode:false
        });
        this.props.onClose();
    };

    handleOtherDance=()=>{
        const {otherDance}=this.state;
        if(otherDance!==''){
            this.addDance(otherDance);
            this.setState({otherDance: ''})
        }else{
            alert("Please enter a dance!")
        }
    };

    render(){

        const statusOptions=['not-started','active','ready','marshalling','adjudicating','processing', 'checking', 'completed', 'canceled'];
        const roundTypeOptions=['Round', 'Semi-Final','Final', 'Uncontested'];
        const socialRoundTypeOptions=['Uncontested','Contested'];
        const multiDanceRoundTypeOption=['Final'];
        const {classes, sectionData}=this.props;
        return(
            <div>
                {this.setMode()}
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <GridContainer>
                            <GridItem xs={12}>
                                <Danger>{this.state.error}</Danger>
                                <GridContainer>
                                    <GridItem xs={12} sm={6}>
                                        <CustomInput
                                            labelText="Item No"
                                            id="itemNo"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value:(this.state.itemNo),
                                                onChange:(this.onTextChange('itemNo')),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <FormatListNumbered />
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <ReactSelectMaterialUi
                                            defaultValue='none'
                                            label='Status'
                                            value={this.state.status}
                                            options={statusOptions}
                                            style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                                            onChange={this.statusChange}
                                        />

                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        {this.renderPanel()}
                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        <ReactSelectMaterialUi
                                            defaultValue='none'
                                            label='Round Type'
                                            value={this.state.roundType}
                                            options={this.props.sectionData.type==='competitive'?roundTypeOptions:this.props.sectionData.type==='multiDance'?multiDanceRoundTypeOption:socialRoundTypeOptions}
                                            style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                                            onChange={this.roundTypeChange}
                                        />
                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        {this.state.roundType !=='Final' && this.props.sectionData.type !=='social' && this.state.roundType!=='Uncontested'?
                                            <CustomInput
                                                labelText="Callback Limit"
                                                id="callbackLimit"
                                                formControlProps={{
                                                    fullWidth: true
                                                }}
                                                inputProps={{
                                                    value:(this.state.callBackLimit),
                                                    onChange:(this.onTextChange('callBackLimit')),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Publish />
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />: null
                                        }
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <h5>Select Dances</h5>
                                {this.state.selectedDances.map(dance=>(
                                    <Button onClick={()=>this.addDance(dance)}  color='info' simple>{dance}</Button>
                                ))}
                                {sectionData.type==='multiDance'?<p>Only one dance per round may be selected!</p>:null}
                                <GridContainer>
                                    <GridItem xs={3}>
                                        <h6>Ballroom</h6>
                                        {this.renderButtons(ballroomDance)}
                                    </GridItem>
                                    <GridItem xs={3}>
                                        <h6>Latin</h6>
                                        {this.renderButtons(latinDances)}
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <h6>Solo/Duo/Group</h6>
                                        <GridContainer>
                                            <GridItem xs={6}>
                                                {this.renderButtons(singleDances)}
                                            </GridItem>
                                            <GridItem xs={6}>
                                                {this.renderButtons(singleDances2)}
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <CustomInput
                                            labelText="Other Dance"
                                            id="otherDance"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                value:(this.state.otherDance),
                                                onChange:(this.onTextChange('otherDance')),
                                            }}
                                        />
                                        <Button onClick={()=>this.handleOtherDance()} color='success' round size='sm'><NoteAdd/>Add Dance</Button>
                                    </GridItem>
                                    {this.state.roundType ==='Final' || this.state.roundType === 'Contested' || this.state.roundType === 'Uncontested' ?
                                        <GridItem xs={12}>
                                            <h6>TCIS Sections</h6>
                                            <p>TCIS sections are marked on a principle of 4 categories out 10, the scores are then added and places awarded from highest to lowest</p>
                                            <p>In contested round type, the places are scrutineered the traditional way</p>
                                            <p>In uncontested round type a percentage is given to each dancer.</p>
                                            <GridContainer>
                                                <GridItem xs={6}>
                                                    {this.renderButtons(specialDances)}
                                                </GridItem>
                                            </GridContainer>

                                        </GridItem>:''
                                    }

                                </GridContainer>
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='button'
                            color={'success'}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}
const mapStateToProps = state=>{
    let dancersNum=0;
    if(state.section.data.dancers !== null && state.section.data.dancers!== undefined){
        dancersNum=Object.keys(state.section.data.dancers).length;
    }

    return {
        dancers:dancersNum,
        panels:state.eventData.panels
    }
};

export default connect(mapStateToProps)(withStyles(modalStyle)(RoundModal));
/* Todo

*/