import React from 'react';
import {connect}from 'react-redux';
import {AddAlert, CheckCircle, Close, FormatListBulleted, PlaylistAddCheck} from "@material-ui/icons";

import history from "../../../../history";
import Loading from "../../../../components/Loading";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import Modal from "../../../../components/Modals/Modal";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import Tabs from '../../../../components/CustomTabs/CustomTabs';
import {removeMarks} from "../../../../Store/Actions/Scrutineer/SectionActions";
import {approveSectionResult} from "../../../../Store/Actions/Chairperson";
import PrintMultiDanceFinalMarks from "../../../../Marks/Components/PrintComponents/printMultiDanceFinalMarks";
import PrintMultiDanceFinalResult from "../../../../Marks/Components/PrintComponents/printMultDanceFinalResult";

class MultiDanceMarks extends React.Component{

    state={
        loading:false,
        notif:false,
        notfiMsg:'',
        modal:false,
        error:''
    };

    handleApprove=()=>{
        this.setState({loading:true});
        this.props.approveSectionResult(this.props.sectionId,res=>{
            res.status===1?
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Section results approved successfully',
                modal:false
            }):this.setState({
                loading:false,
                error:res.msg
                })
        })
    };

    closeMarks=()=>{
        this.props.removeMarks(()=>history.push('/chairperson/event/results'))
    };

    render(){
        const {loading,notifMsg, notif,modal, error}=this.state;
        const {sectionData, eventDetails}=this.props;

        return (
            <div>
                <Loading
                    show={loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={notifMsg}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                  errorMessage={error}
                  heading={'Section Approval'}
                  isVisible={modal}
                  onClose={()=>this.setState({modal:false})}
                  saveButtonText={'Approve Marks'}
                  onSave={this.handleApprove}
                  content={
                      <div>
                          <h5>Confirm approval of the results for this round</h5>
                      </div>
                  }
                />
                <GridContainer>
                    <GridItem xs={12} md={7}>
                        {sectionData.title}
                    </GridItem>
                    <GridItem xs={12} md={5}>
                        {!sectionData.checked?
                            <Button onClick={()=>this.setState({modal:true})} size='sm' round color='info'><CheckCircle/>Approve Marks</Button>:''
                        }
                        <Button onClick={()=>this.closeMarks()} size='sm' round><Close/>Close Marks</Button>
                    </GridItem>
                </GridContainer>
                <Tabs
                    headerColor={'info'}
                    tabs={[
                        {
                            tabName:'Marks',
                            tabIcon:PlaylistAddCheck,
                            tabContent:(
                                <PrintMultiDanceFinalMarks
                                    eventDetails={eventDetails}
                                    sectionData={sectionData}
                                />
                            )
                        },
                        {
                            tabName:'Result',
                            tabIcon:FormatListBulleted,
                            tabContent:(
                                <PrintMultiDanceFinalResult
                                    eventDetails={eventDetails}
                                    sectionData={sectionData}
                                />
                            )
                        }
                    ]}
                />
            </div>
        )
    }
}
export default connect(null,{removeMarks, approveSectionResult})(MultiDanceMarks);