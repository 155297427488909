import React from 'react';
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import withStyles from "@material-ui/core/styles/withStyles";
import Transition from "../../Functions/Transistion";
import RenderInputs from "../../Functions/RenderInputs";
//components
import Danger from "../Typography/Danger";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";

class EventModal extends React.Component{

    state={
        date:new Date()
    };

    onSubmit=(formValues)=>{
        this.props.onSubmit(formValues);
    };


    render(){
        const {classes}=this.props;
        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <Danger>{this.props.errorMessage}</Danger>
                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <Field id='title' type='text' placeholder='Title' name='title' label='Event Title' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <Field id='date' type='date' name='date'  label='Event Date' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={6} sm={6}>
                                    <Field id='organization' type='text' placeholder='Organization' name='organization' label='Organization' component={RenderInputs} />
                                </GridItem>
                                <GridItem xs={12} >
                                    <Field id='venue' type='text' placeholder='Venue' name='venue' label='Venue' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={12}>
                                            <h5>Organizer Info</h5>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <Field id='organizer' type='text' name='organizer' placeholder='Organizer' label='Organizer' component={RenderInputs}/>
                                        </GridItem>
                                        <GridItem xs={6}>
                                            <Field id='contact' type='text' name='contact' placeholder='011 010 1234' label='Contact' component={RenderInputs}/>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}
const validate =formValues=>{
    const errors ={};
    const {title, date, venue, contact, organizer, organization}=formValues;
    if(!title){
        errors.title="Please fill in a title"
    }
    if(!date){
        errors.date='Please select a date'
    }
    if(!venue){
        errors.venue ="Please fill in a venue"
    }
    if(!contact){
        errors.contact = "Please fill contact info"
    }
    if(!organization){
        errors.organization="Please fill in an organization"
    }
    if(!organizer){
        errors.organizer = 'Please fill in a name'
    }

    return errors;
};


const form =reduxForm({
    form:'newEvent',
    validate,
    enableReinitialize:true
})(EventModal);

const mapStateToProps = (state)=>{
    if(state.eventData.loaded){
        const {title, venue, date, organizer, organization}=state.eventData.info;
        return {
            initialValues:{
                title,
                venue,
                date,
                organizer:organizer.name,
                contact:organizer.contact,
                organization,
            }
        }
    }else{
        return{
            initialValues: {title:'',venue:'',date:'',organizer:'', contact:'', organization:''}
        }
    }
};

export default connect(mapStateToProps)(withStyles(modalStyle)(form));
