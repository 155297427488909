//@material icons
import Dashboard from "@material-ui/icons/Dashboard";
import SupervisedUserCircle from '@material-ui/icons/SupervisedUserCircle';
import Description from '@material-ui/icons/Description';
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Notes from '@material-ui/icons/Notes';
import BubbleChart from "@material-ui/icons/BubbleChart";
import EventNote from '@material-ui/icons/EventNote';
import Event from '@material-ui/icons/Event';
import ListAlt from '@material-ui/icons/ListAlt';
//components
import ScrutinAdjudicator from "./Views/adjudicators";
import ScrutinDetails from "./Views/details";
import ScrutinEntries from "./Views/entries";
import ScrutinDashboard from "./Views/dashboard";
import ScrutinPanels from "./Views/panels";
import ScrutinSections from "./Views/Sections/sections";
import ScrutinResults from "./Views/Results/results";
import ScrutinNotes from "./Views/notes";
import Rounds from "./Views/Rounds/rounds";


const eventRoutes =[
    {   path:'/dashboard',
        name:'Dashboard',
        Icon:Dashboard,
        layout:'/scrutineer/Screens',
        component:ScrutinDashboard
    },
    {
        path:'/details',
        name:'Details',
        Icon:Description,
        layout:'/scrutineer/Screens',
        component:ScrutinDetails
    },
    {
        path:'/adjudicators',
        name:'Adjudicators',
        Icon:SupervisedUserCircle,
        layout:'/scrutineer/Screens',
        component:ScrutinAdjudicator
    },
    {
        path:'/panels',
        name:'Panels',
        Icon:Event,
        layout:'/scrutineer/Screens',
        component:ScrutinPanels
    },
    {
        path:'/sections',
        name:'Sections',
        Icon:LibraryBooks,
        layout:'/scrutineer/Screens',
        component:ScrutinSections
    },
    {
        path:'/rounds',
        name:'Rounds',
        Icon:ListAlt,
        layout:'/scrutineer/Screens',
        component:Rounds
    },
    {
        path:'/results',
        name:'Results',
        Icon:BubbleChart,
        layout:'/scrutineer/Screens',
        component:ScrutinResults
    },
    {
        path:'/entries',
        name:'Entries',
        Icon:EventNote,
        layout:'/scrutineer/Screens',
        component:ScrutinEntries
    },
    {
        path:'/notes',
        name:'Notes',
        Icon:Notes,
        layout:'/scrutineer/Screens',
        component:ScrutinNotes
    }
];


export default eventRoutes;