import React from 'react';
import {Link} from 'react-router-dom';
import {get, keys} from 'lodash';
import moment from 'moment';
export const marksTable =(roundData,marks)=>{
    const totals = roundData.activeList.reduce((a,v)=>{

        Object.keys(roundData.marks).map(adj=>{
            roundData.dances.map(dance=>{

                if(marks[adj][dance].includes(v)){
                    if(!a[v]){
                        a[v]=1
                    }else{
                        a[v]+=1
                    }
                }
            })
        });

        return a;
    },{});

    const marksTable = roundData.activeList.map(dancer=>{

        return (
            <tr key={'something'+dancer} >
                <td>{dancer}</td>
                {roundData.dances.map(dance=>{
                    return Object.keys(marks).map(adj=>{
                        if(marks[adj][dance].includes(dancer)){
                            return (<td key={adj+'mark'+dancer}>X</td>)
                        }else{
                            return (<td key={adj+'mark'+dancer}></td>)
                        }
                    })
                })}
                <td>{totals[dancer]}</td>
            </tr>
        )

    });
    const tHeadDance=(
        <tr>
            <th>No</th>
            {roundData.dances.map(dance=>{
                return (
                    <th key={'panel'+dance} colSpan={roundData.panel.letters.split(',').length}>{dance}</th>
                );
            })}
            <th>Total</th>
        </tr>
    );
    const tHeadJudge=(
        <tr>
            <th></th>
            {roundData.dances.map(dance=>{
                return roundData.panel.letters.split(',').map(adj=>{
                    return (<th key={adj+dance}>{adj}</th>)
                })
            })}
            <th> </th>
        </tr>
    )

    const tableHead =(
        <thead>
        <tr>
            <th>No</th>
            {roundData.dances.map(dance=>{
                return (
                    <th key={'panel'+dance} colSpan={roundData.panel.letters.split(',').length}>{dance}</th>
                );
            })}
            <th>Total</th>
        </tr>
        <tr>
            <th></th>
            {roundData.dances.map(dance=>{
                return roundData.panel.letters.split(',').map(adj=>{
                    return (<th key={adj+dance}>{adj}</th>)
                })
            })}
            <th> </th>
        </tr>
        </thead>
    );
    const adjs =roundData.panel.letters.split(',').length ;

    const fontSize = adjs >6 ?8:10;

    return (
        <div>
            <style>
                {`  
                @media print{
                    table{
                        page-break-inside:auto
                        page-break-after:auto;
                    }
                    th{
                        page-break-inside:avoid;
                        page-break-after:auto;
                        page-break-before:auto
                    }
                    tr{
                        page-break-inside:avoid;
                        page-break-after:auto;
                        page-break-before:auto
                    }
                    td{
                        page-break-inside:avoid;
                        page-break-after:auto;
                        page-break-before:auto
                    }
                
                }
                    table{
                        border-collapse :collapse
                    }    
                   th{
                     border: 1px solid #99989C;
                     text-align: center;
                     padding:0.5vmin;
                     fontSize:${fontSize};
                       page-break-inside:avoid;
                        page-break-after:auto;
                        page-break-before:auto
                    }
                    tr {
                        page-break-inside:avoid;
                        page-break-after:auto;
                        page-break-before:auto
                    }
                    td{
                      border: 1px solid #99989C;
                      text-align: center;
                      padding:0.5vmin;
                      fontSize:${fontSize}
                      color:#000
                      page-break-inside:avoid;
                      page-break-after:auto;
                      page-break-before:auto
                    }
                    
                          `}
            </style>
            <table>

                <tbody>
                {tHeadDance}
                {tHeadJudge}
                {marksTable}
                </tbody>
            </table>
        </div>
    )
};


export const renderMarksDetails =(roundData, setPdfContent)=>{
    const marksLog = roundData.marksLog;

  return keys(marksLog).map(letter=>{
                return (
                    <tr key={letter+1}>
                        <td>{letter}</td>
                        <td>
                            {get(marksLog[letter],'device').substr(0,25)}
                            {get(marksLog[letter],'device').length >25 && ' ... '}
                        </td>
                        <td>{moment().utc(marksLog[letter].time).format('D/M/yyyy HH:mm:ss')}</td>
                        {
                            !! get(marksLog[letter],'pdf', false) ? (
                                    <td>
                                        <Link
                                            onClick={()=>setPdfContent(get(marksLog[letter],'pdf'))}
                                        >
                                            {`${letter} - Marks Sheet`}
                                        </Link>
                                    </td>
                                ):
                                (
                                    <td>Old Old Version</td>
                                )
                        }
                    </tr>
                )
            })


};

export const renderDancers =(dancers, roundData)=>{
    if(roundData.roundType !=='Final'){
        return roundData.results.sort().reduce((a,v)=>{
            Object.keys(dancers).map(key=>{
                if(dancers[key].number === v){

                    a.push([v,dancers[key].names])
                }
            });
            return a;
        },[])
    }
};
