import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
import RenderInputs from "../../Functions/RenderInputs";
import Danger from '../Typography/Danger';
import Transition from "../../Functions/Transistion";
import {validateFormEntries} from "../../Functions/Validation";

const FIELDS = [
    {
        name:'letter',
        placeholder:'A',
        label:'Letter',
        type:'text'
    },
    {
        name:'firstName',
        placeholder:'First Name..',
        label:'First Name',
        type:'text'
    },
    {
        name:'lastName',
        placeholder:'Last Name...',
        label:'Last Name',
        type:'text'
    }
]

class AdjModal extends React.Component{

    onSubmit=(formValues)=>{
        this.props.onSave(formValues);
    };

    render(){
        const {classes}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <div>
                                <Danger>{this.props.errorMessage}</Danger>
                                {FIELDS.map(value=>{
                                    const {name,type,placeholder,label}=value;
                                    return (
                                        <Field
                                            placeholder={placeholder}
                                            id={name}
                                            name={name}
                                            type={type}
                                            label={label}
                                            component={RenderInputs}
                                        />
                                    )
                                })}
                             </div>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}
const validate = formValues=>{
    return validateFormEntries(FIELDS,formValues);
};

const form =reduxForm({
    form:'adjEdit',
    validate,
    enableReinitialize:true
})(AdjModal);
const mapStateToProps=(state,ownProps)=>{


    let adjDetails='';

    if(ownProps.adj !== undefined && ownProps.adj !== null){
        const adjInfo = state.eventData.adjudicators[ownProps.adj];
        const [firstName, lastName] = adjInfo.name.split(/\s+/);

        adjDetails = {
            firstName:firstName,
            lastName:lastName,
            letter:adjInfo.letter,
        };
    }

    return{
        initialValues:adjDetails
    }
};

export default connect(mapStateToProps)(withStyles(modalStyle)(form))