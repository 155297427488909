import React from 'react';
import {connect} from 'react-redux';
import {reduxForm,Field} from "redux-form";
// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Transition from "../../Functions/Transistion";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";

import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import Danger from "../Typography/Danger.jsx";
import RenderInputs from "../../Functions/RenderInputs";
import {validateFormEntries} from "../../Functions/Validation";

const FIELDS=[
    {
        name:'firstName',
        type:'text',
        placeholder:'First Name...',
        label:'First Name',
    },
    {
        name:'lastName',
        type:'text',
        placeholder:'Last Name...',
        label:'Last Name',
    },
    {
        name:'country',
        type:'text',
        placeholder:'Country...',
        label:'Country',
    },
    {
        name:'email',
        type:'text',
        placeholder:'youremail@mail.com',
        label:'Email',
    }
]
class ProfileModal extends React.Component{


    onSubmit=(formValues)=>{
        this.props.onSubmit(formValues);
    };

    render(){
        const {classes}=this.props;
        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <Danger>{this.props.errorMessage}</Danger>
                            <GridContainer justify={'center'} alignItems={'center'}>
                                {FIELDS.map(value=>{
                                    const {name,type,placeholder,label}=value;
                                    return(
                                        <GridItem sm={12} md={6}>
                                            <Field
                                                type={type}
                                                id={name}
                                                name={name}
                                                width={true}
                                                placeholder={placeholder}
                                                label={label}
                                                component={RenderInputs}
                                            />
                                        </GridItem>
                                    )
                                })}
                            </GridContainer>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}

const validate=(formValues)=>{
    return validateFormEntries(FIELDS,formValues);
}

const form = reduxForm({
    form:'profile',
    validate,
    enableReinitialize:true
})(ProfileModal);
const mapStateToProps =(state,ownProps)=>{
    return{
        initialValues:state.Auth
    }
};


export default connect(mapStateToProps)(withStyles(modalStyle)(form));