import {AUTH_LOGIN, AUTH_LOGOUT} from "../types";

const INITIAL_STATE={
    isSignedIn:false,
    uid:'',
    role:'',
    firstName:'',
    lastName:'',
    country:'',
    email:''
}

export default (state=INITIAL_STATE,action)=>{

    switch(action.type){
        case AUTH_LOGIN:
            const {uid,role,firstName,lastName,country,email}=action.payload;
            return {...state,isSignedIn:true,uid,role,firstName,lastName,country,email}
        case AUTH_LOGOUT:
            return {isSignedIn: false,displayName: null,email:null}
        default:
            return {...state};

    }
}