import {combineReducers} from "redux";
import {reducer as FormReducer} from "redux-form";

// custom reducers
import Auth from './AuthReducer';
import Events from './EventsReducer';
import eventData from './EventDataReducer';
import eventId from './SingleEventReducers';
import Section from './SectionReducer';
import Marks from './MarksReducer';
import Officials from './OfficalsReducer';
import Disclaimers from "./DisclaimerReducer";

const index={
    form:FormReducer,
    Auth,
    events:Events,
    eventData,
    eventId,
    section:Section,
    marks:Marks,
    chairpersons:Officials,
    Disclaimers
}

export default combineReducers(index);