import React from 'react';
import {connect}from 'react-redux';
import {AddAlert, CheckCircle, Close, FormatListBulleted, PlaylistAddCheck} from "@material-ui/icons";


// components
import history from "../../../../history";

import Loading from "../../../../components/Loading";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import Modal from "../../../../components/Modals/Modal";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import GridContainer from "../../../../components/Grid/GridContainer";
import Tabs from "../../../../components/CustomTabs/CustomTabs";

import {approveResult}from '../../../../Store/Actions/Chairperson'
import {renderMarksDetails} from "../../../../Marks/Alogrithms/roundAlgo";
import {marksTableFinal} from "../../../../Marks/Alogrithms/finalAlgo";
import {unContestedTable, UncontestFinalSpecialTable} from "../../../../Marks/Alogrithms/uncontestedAlgo";
import {SpecialDanceTable} from "../../../../Marks/Functions";
import PrintFinalResult from "../../../../Marks/Components/PrintComponents/printFinalResult";
import {removeMarks} from "../../../../Store/Actions/Scrutineer/SectionActions";


class ChairSocialMarks extends React.Component{

    state={
        modal:false,
        loading:false,
        notif:false,
        notifMsg:''
    };
    closeMarks=()=>{
        this.props.removeMarks(()=>history.push('/chairperson/event/results'))
    };

    saveApproval=()=>{
        const {sectionId, roundId}=this.props;
        this.setState({loading:true});
        this.props.approveResult(roundId,sectionId,(res)=>{
            this.setState({
                modal:false,
                loading:false,
                notif:true,
                notifMsg:'Round Approved successfully'
            })
        })
    };

    renderMarks=()=>{
        const {roundType,dances}=this.props.roundData;
        const {roundData}=this.props;
        if(roundType==='Contested'){
            // treat as normal final
            return marksTableFinal(roundData);
        }else if(roundType==='Uncontested'){
            if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
                return UncontestFinalSpecialTable(roundData);
            }else{
                return unContestedTable(roundData)
            }
        }
    };

    renderSpecialDanceMark=()=>{
        const {specialMarks}=this.props.roundData;
        if(specialMarks!== undefined){
            return (SpecialDanceTable(specialMarks));
        }
    };

    render(){
        const {roundData, sectionData, eventDetails}=this.props;
        return (
            <div>
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                    heading='Round Approval'
                    isVisible={this.state.modal}
                    onClose={()=>this.setState({modal:false})}
                    onSave={this.saveApproval}
                    saveButtonText='Approve'
                    content={
                        <div>
                            <h5>Confirm approval of the results for this round</h5>
                        </div>
                    }
                />
                <GridContainer>
                    <GridItem xs={12} md={8}>
                        {roundData.itemNo +'  '+sectionData.title+'-'+roundData.roundType}
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        {!roundData.checked ?
                            <Button onClick={()=>this.setState({modal:true})} size='sm' round color='info'><CheckCircle/>Approve Marks</Button>:''
                        }
                        <Button onClick={()=>this.closeMarks()} size='sm' round><Close/>Close Marks</Button>
                    </GridItem>
                    <GridItem xs={12}>
                        <Tabs
                            headerColor={'info'}
                            tabs={[
                                {
                                    tabName:'Marks',
                                    tabIcon:PlaylistAddCheck,
                                    tabContent:(
                                        <div>
                                            {this.renderMarks(roundData,sectionData.title)}
                                            <GridContainer>
                                                {roundData.dances.includes('exhibition') ||
                                                roundData.dances.includes('showcase')||
                                                roundData.dances.includes('showdance')||
                                                roundData.dances.includes('solo spot') ?
                                                    <GridItem xs={12}>
                                                        <h3>TCIS Marks</h3>
                                                        {this.renderSpecialDanceMark()}
                                                    </GridItem>:''
                                                }
                                                <GridItem xs={12}>
                                                    <h4> Adjudicator Log</h4>
                                                </GridItem>
                                                <GridItem xs={12}>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <td>Letter</td>
                                                            <td>Device</td>
                                                            <td>Time</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {renderMarksDetails(roundData)}
                                                        </tbody>
                                                    </table>
                                                    <h5>Processed @ { new Date(roundData.processedTime).toString()}</h5>
                                                    {roundData.checked ? <h5>Checked @ {new Date(roundData.checkTime).toString()}</h5>:''}
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    )

                                },
                                {
                                    tabName:'Results',
                                    tabIcon:FormatListBulleted,
                                    tabContent:(
                                        <PrintFinalResult
                                            sectionType={sectionData.type}
                                            dancers={sectionData.dancers}
                                            data={eventDetails}
                                            sectionTitle={sectionData.title}
                                            roundData={roundData}
                                            sectionRounds={sectionData.rounds}
                                            ref={ele=>(this.componentRef=ele)}
                                        />
                                    )
                                }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
export default connect(null, {approveResult,removeMarks})(ChairSocialMarks)