import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Image from "material-ui-image/lib/components/Image/Image";


const PrintEventHead =({logo,title,date,venue,organization,organizer,scrutineer,sectionTitle,roundNo})=>{

    return (
        <GridContainer>
            <GridItem justify={'center'}  alignItems={'center'} xs={6}>
                <div style={{maxHeight: '250px', maxWidth: '250px',}}>
                    <Image
                        aspectRatio={'2/1'}
                        style={{
                            objectFit:'scale-down',
                        }}
                        src={logo}/>
                </div>
            </GridItem>
            <GridItem xs={6}>
                <GridContainer>
                    <GridItem xs={6} style={{textAlign:'right'}}>
                        Title:<br/>
                        Date:<br/>
                        Venue:<br/>
                        Organization:<br/>
                        Organizer:<br/>
                        Scrutineer:<br/>
                    </GridItem>
                    <GridItem xs={6}>
                        <b>{title}</b><br/>
                        {date}<br/>
                        {venue}<br/>
                        {organization}<br/>
                        {organizer.name}<br/>
                        {scrutineer.name}<br/>
                    </GridItem>
                </GridContainer>
            </GridItem>
            <GridItem xs={12}>
                <GridContainer>
                    <GridItem xs={8}>
                        <h4>{sectionTitle}</h4>
                    </GridItem>
                    <GridItem xs={4}>
                        <h4>Round No: {roundNo}</h4>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
    )

};

export default PrintEventHead;