import React from 'react';
import {connect} from 'react-redux';
import {AddAlert, Close, Forward, PlaylistAddCheck,LocalPrintshop} from "@material-ui/icons";
import ReactToPrint from "react-to-print";

// Components
import history from "../../history";
import Snackbar from "../../components/Snackbar/Snackbar";
import Loading from "../../components/Loading";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import Tabs from "../../components/CustomTabs/CustomTabs";
import Modal from "../../components/Modals/Modal";


import {MultiDanceSection, MultiDanceSectionResult} from "../Alogrithms/finalAlgo";
import {removeMarks, processMultiFinalMarks} from "../../Store/Actions/Scrutineer/SectionActions";

import PrintMultiDanceFinalMarks from "../Components/PrintComponents/printMultiDanceFinalMarks";
import PrintMultiDanceFinalResult from "../Components/PrintComponents/printMultDanceFinalResult";


class MarksMultiFinalComponent extends React.Component{

    state={
        notif:false,
        notifMsg:'',
        loading:false,
        modal:false,
        error:''
    };

    renderMarks=()=>{
        const {sectionData}=this.props;
       return MultiDanceSection(sectionData)
    };

    closeMarks=()=>{
        this.props.removeMarks(()=>history.push('/scrutineer/Screens/results'));
    };
    saveProcess=()=>{

        const result = MultiDanceSectionResult(this.props.sectionData);

        this.setState({loading:true});
        this.props.processMultiFinalMarks(result,this.props.id,res=>{
            res.status!==1?
                this.setState({loading:false,error:res.msg}):
                this.setState({
                    loading:false,
                    error:'',
                    modal:false,
                    notif:true,
                    notifMsg:'Section results processed successfully!'
                })
        })
    };


    render(){
        const {sectionData, eventDetails}=this.props;
        const {dancers,title,type,processed,checked,processedTime,checkTime}=sectionData;
        const {loading,notif,notifMsg, modal}=this.state;
        return (
            <div>
                <Modal
                    isVisible={modal}
                    onClose={()=>this.setState({modal:false})}
                    content={<div>Are you sure you want to confirm these results?</div>}
                    heading={'Process Results'}
                    onSave={()=>this.saveProcess()}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={notifMsg}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={loading}
                />
                <GridContainer>
                    <GridItem xs={7}>
                        {title}
                    </GridItem>
                    <GridItem xs={5}>
                        {processed=== undefined?
                            <Button onClick={()=>this.setState({modal:true})} size='sm' color='info' round><Forward/>Process Results</Button>:''
                        }
                       <Button onClick={()=>this.closeMarks()} size='sm' round><Close/>Close Section</Button>
                    </GridItem>
                </GridContainer>
                <Tabs
                    headerColor={'info'}
                    tabs={[
                        {
                            tabName:'Details',
                            tabIcon:PlaylistAddCheck,
                            tabContent:(
                                <div>
                                    {this.renderMarks()}
                                    {processed  ?
                                        <h5>Processed @ {new Date(processedTime).toString()}</h5>:''
                                    }
                                    {checked ?
                                        <h5>Checked @ {new Date(checkTime).toString()}</h5>:''
                                    }
                                </div>
                            )
                        },
                        {
                            tabName:'Print Marks',
                            tabIcon:LocalPrintshop,
                            tabContent:(
                                processed ?
                                        <div>
                                            <ReactToPrint
                                                trigger={()=><Button  type='button' round size='sm'>Print Marks</Button>}
                                                content={()=>this.componentRef}
                                             />
                                            <PrintMultiDanceFinalMarks
                                                eventDetails={eventDetails}
                                                sectionData={sectionData}
                                                ref={ele=>(this.componentRef=ele)}
                                            />
                                        </div>:
                                        <div>
                                            Marks not Processed yet!
                                        </div>
                            )
                        },
                        {
                            tabName:'Print Result',
                            tabIcon:LocalPrintshop,
                            tabContent:(
                                processed?
                                    <div>
                                        <ReactToPrint
                                            trigger={()=><Button  type='button' round size='sm'>Print Results</Button>}
                                            content={()=>this.componentRef}
                                        />
                                        <PrintMultiDanceFinalResult
                                            eventDetails={eventDetails}
                                            sectionData={sectionData}
                                            ref={el=>(this.componentRef=el)}
                                        />
                                    </div>:
                                    <div>
                                        Marks not Processed Yet!
                                    </div>
                            )
                        }
                    ]}
                />
            </div>
        )
    }
}
export default connect(null,{removeMarks,processMultiFinalMarks})(MarksMultiFinalComponent);