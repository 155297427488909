import React from "react";
import {marksTableFinal} from "../../../../../Marks/Alogrithms/finalAlgo";
import GridItem from "../../../../../components/Grid/GridItem";
import {SpecialDanceTable} from "../../../../../Marks/Functions";
import GridContainer from "../../../../../components/Grid/GridContainer";
import {unContestedTable, UncontestFinalSpecialTable} from "../../../../../Marks/Alogrithms/uncontestedAlgo";
import {DancersFunction} from "../functions";
import {DSDivider} from "../../../../../components/Divider";

export const ViewFinalAll =(props)=>{

    const {title,roundType,roundData,itemNo,dancers}=props.data;
    const {dances, specialMarks,results}=roundData;

    const renderSpecialMarks=(marks)=>{
        if(marks !== undefined){
            return SpecialDanceTable(marks)
        }
    };

    const Dancers = DancersFunction(dancers);

    const renderMarks = (roundData,roundType)=>{
        if(roundType==='Final'){
            return (marksTableFinal(roundData))
        }else{
            const dances = roundData.dances;
            if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
                return UncontestFinalSpecialTable(roundData);
            }else{
                return unContestedTable(roundData)
            }
        }
    };

    return (
        <div>
            <h5>{itemNo+' '+title+'-'+roundType}</h5>
            <GridContainer>
                <GridItem xs={12}>
                    {renderMarks(roundData,roundType)}
                </GridItem>
                    {dances.includes('exhibition') ||
                    dances.includes('showcase')||
                    dances.includes('showdance')||
                    dances.includes('solo spot') ?
                        <GridItem xs={12}>
                            <h3>TCIS Marks</h3>
                            <p>T-Technique C-Choreography I-Image S-Show </p>
                            {renderSpecialMarks(specialMarks)}
                        </GridItem>:null}
                <GridItem xs={12}>
                    <h3>Final Result</h3>
                    <div>

                    {Object.values(results).sort((a,b)=>a-b).map(place=>{

                    return  Object.keys(results).map(dancer=>{
                            if(place===results[dancer]){
                                if(Dancers[dancer]!== undefined){
                                    return (
                                        <p>{place+"  "}<b>{dancer}</b>   {Dancers[dancer].names+"   "+Dancers[dancer].studio}</p>
                                    )
                                }else{
                                    return <p>{place}</p>
                                }

                            }
                        })

                    })}

                    </div>
                </GridItem>
            </GridContainer>
            <DSDivider/>
        </div>
    )
};