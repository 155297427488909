import React from 'react';
import {connect} from 'react-redux';

import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardHeader from "../../../components/Card/CardHeader";
import Card from "../../../components/Card/Card";
import Button from "../../../components/CustomButtons/Button";
import AddBox from '@material-ui/icons/AddBox';
import CardBody from "../../../components/Card/CardBody";
import Loading from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import AddAlert from "@material-ui/icons/AddAlert";
import PanelModal from '../../../components/Modals/PanelModal';
import Table from "../../../components/Table/Table";
import {addPanel, editPanel, deletePanel} from "../../../Store/Actions/Scrutineer/SingleEventActions";
import Edit from '@material-ui/icons/Edit';
import Close from '@material-ui/icons/Close';
import Modal from '../../../components/Modals/Modal';
import {Grid} from "@material-ui/core";

class ScrutinPanels extends React.Component{
    state={
        modal:false,
        loading:false,
        notif:false,
        notifMsg:'',

        panelHeading:'',
        activePanel:'',
        panelError:'',
        panelId:'',
        deleteModal:false,
        deleteId:null
    };

    renderAdjudicators =()=>{
      if(this.props.adjudicators !== undefined){
            const tableHead = ['Letter', 'Name'];
            const letterArray = Object.values(this.props.adjudicators).reduce((a,v)=>{
                a[v.letter]=v.name;
                return a
            },{});
            const tableData = Object.keys(letterArray).sort().reduce((a,v)=>{
                a.push([v,letterArray[v]]);
                return a
            },[]);
            return (
                <Table
                    tableData={tableData}
                    tableHeaderColor={'primary'}
                    tableHead={tableHead}
                />
            )

      }else{
       return(
           <div>
               <h4>No Adjudicators saved yet</h4>
           </div>
       )
      }
    };

    onSave =(list)=>{
        const panel = Object.keys(list).reduce((a,v)=>{
            a[v]={
                name:list[v].name,
                letter:list[v].letter
            };

            return a;
        },{});
        this.setState({loading:true});
        if(this.state.activePanel === ''){
            this.props.addPanel(panel,(res)=>{
                res.status !==1 ?
                    this.setState({loading:false, panelError:res.msg}):
                    this.setState({modal:false, loading:false,panelError:'', notif:true, notifMsg:'Panel added'});
            })
        }else{
            //update panel
           this.props.editPanel(panel, this.state.panelId,(res)=>{
               res.status !==1 ?
               this.setState({loading:false, panelError:res.msg}):
               this.setState({
                   modal:false,
                   loading:false,panelError:'',
                   notif:true,
                   notifMsg:'Panel Updated',
                   activePanel:'',
                   panelId:''
               });
           })
        }

    };

    openEdit =(id)=>{
       this.setState({
           modal:true,
           activePanel:this.props.panels[id],
           panelHeading:'Edit panel',
           panelId:id
       })
    };
    openDelete=(id)=>{
      this.setState({deleteModal:true,deleteId:id});
    };

    renderPanels=()=>{
      if(this.props.panels !== undefined){
          const TableHead =['Number', 'Adjudicators', 'Actions'];
           const panels = this.props.panels;
           const tableData = Object.keys(panels).reduce((a,v,index)=>{
                a.push([index+1, Object.keys(panels[v]).reduce((ar,val)=>{
                    ar.push(panels[v][val].letter);
                    return ar;
                },[]),(
                    <div>
                        <Button color='info' round justIcon size='sm' onClick={()=>this.openEdit(v)}><Edit/></Button>
                        <Button color='danger'  round justIcon size='sm'  onClick={()=>this.openDelete(v)}><Close/></Button>
                    </div>
                )]);

               return a
           },[]) ;
           return (
               <Table
                tableHead={TableHead}
                tableData={tableData}
                tableHeaderColor={'warning'}
               />
           )
      }else{
       return (
           <div>
               <h5>No Panels added yet!</h5>
           </div>
       )
      }

    };

    onConfirmDelete=()=>{
        this.setState({loading:true});
       this.props.deletePanel(this.state.deleteId,(res)=>{
           if(res.status ===1){
               this.setState({
                   loading:false,
                   deleteModal:false,
                   deleteId:null,
                   notif:true,
                   notifMsg:'Panel deleted successfully!'
               })
           }
       })
    };

    render() {
        return(
            <div>
                <Modal
                    errorMessag={'Are you sure you want to delete this panel?'}
                    heading={'Delete Panel'}
                    isVisible={this.state.deleteModal}
                    onClose={()=>this.setState({deleteModal:false})}
                    onSave={this.onConfirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this panel?</p>}
                />
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <PanelModal
                    isVisible={this.state.modal}
                    heading={this.state.panelHeading}
                    onClose={()=>this.setState({modal:false, panelId:'', activePanel:'', panelHeading:''})}
                    onSave={this.onSave}
                    panelId={this.state.panelId}
                    errorMessage={this.state.panelError}
                    panel={this.state.activePanel}
                    adjList={this.props.adjudicators}
                />
                <GridContainer>
                    <GridItem xs={12} md={8}>
                        <Card>
                            <CardHeader color={'warning'}>
                                <Grid container xs={12}  alignItems="center" justify={'space-between'} direction={'row'}>
                                    <h4>Panels</h4>
                                    <Button color='white' round size='sm' onClick={()=>this.setState({panelHeading:'Add New Panel', modal:true})}>
                                        <AddBox/>
                                        Add New Panel
                                    </Button>
                                </Grid>
                            </CardHeader>
                            <CardBody>
                                {this.renderPanels()}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <Card>
                            <CardHeader color='primary'>
                                <h4>Adjudicators</h4>
                            </CardHeader>
                            <CardBody>
                                {this.renderAdjudicators()}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const mapStateToProps =state=>{
  return {
      adjudicators:state.eventData.adjudicators,
      panels:state.eventData.panels
  }
};


export default connect(mapStateToProps, {addPanel,editPanel, deletePanel})(ScrutinPanels);
