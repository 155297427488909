import React from "react";
import {connect}from 'react-redux';
import {PlaylistAddCheck, CheckCircle, FormatListBulleted, Close, AddAlert} from "@material-ui/icons";

import history from "../../../../history";
import Tabs from "../../../../components/CustomTabs/CustomTabs";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import Loading from "../../../../components/Loading";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import Modal from "../../../../components/Modals/Modal";

import {removeMarks} from "../../../../Store/Actions/Scrutineer/SectionActions";
import {marksTableFinal} from "../../../../Marks/Alogrithms/finalAlgo";
import {marksTable, renderDancers, renderMarksDetails} from "../../../../Marks/Alogrithms/roundAlgo";
import {unContestedTable, UncontestFinalSpecialTable} from "../../../../Marks/Alogrithms/uncontestedAlgo";
import {SpecialDanceTable} from "../../../../Marks/Functions";
import PrintFinalResult from "../../../../Marks/Components/PrintComponents/printFinalResult";
import PrintRoundResult from "../../../../Marks/Components/PrintComponents/printRoundResult";
import {approveResult} from "../../../../Store/Actions/Chairperson";
import MarksheetsModal from "../../../../components/Modals/MarksheetsModal";

class ChairCompetitiveMarks extends React.Component{

    state={
        modal:false,
        loading:false,
        notif:false,
        notifMsg:'',
        pdfModal: null
    };

    renderUncontestedMark=()=>{

        const {dances}=this.props.roundData;

        if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
            return UncontestFinalSpecialTable(this.props.roundData);
        }else{
            return unContestedTable(this.props.roundData)
        }


    };

    renderMarks=(roundData, title)=>{

        if(roundData.roundType ==='Final'){
            // marks for final
            return marksTableFinal(roundData);
        }
        else if(roundData.roundType ==='Uncontested'){
            return(this.renderUncontestedMark());
        }
        else{
            // marks for round
                return (
                    <div>

                        <GridContainer>
                            <GridItem xs={8}>
                                <h4>{roundData.itemNo} {title} - {roundData.roundType}</h4>
                            </GridItem>
                            <GridItem xs={4}>
                                <h5>Panel  {roundData.panel.panel} </h5>
                            </GridItem>
                            <GridItem xs={10}>
                                {marksTable(roundData, roundData.marks)}
                            </GridItem>
                        </GridContainer>

                    </div>
                )
        }
    };

    closeMarks=()=>{
        this.props.removeMarks(()=>history.push('/chairperson/event/results'))
    };

    renderResults=(roundData,sectionData, eventDetails)=>{
        const dancers = sectionData.dancers;
        if(roundData.roundType==='Final' || roundData.roundType==='Uncontested'){
            return (
                <PrintFinalResult
                    dancers={dancers}
                    data={eventDetails}
                    sectionTitle={sectionData.title}
                    roundData={roundData}
                    sectionRounds={sectionData.rounds}
                />
            )

        }
        else{
            return (
                <PrintRoundResult
                    callback={renderDancers(dancers,roundData)}
                    data={eventDetails}
                    sectionTitle={sectionData.title}
                    roundData={roundData}
                />
            )
        }
    };

    saveApproval=()=>{
      const {sectionId, roundId}=this.props;
      this.setState({loading:true});
      this.props.approveResult(roundId,sectionId,(res)=>{
          this.setState({
              modal:false,
              loading:false,
              notif:true,
              notifMsg:'Round Approved successfully'
          })
      })
    };

    renderSpecialDanceMark=()=>{
        const {specialMarks}=this.props.roundData;
        if(specialMarks!== undefined){
            return (SpecialDanceTable(specialMarks));
        }
    };


    render(){
        const {roundData,sectionId,roundId,sectionData, eventDetails}=this.props;
        return (
            <div>
                <MarksheetsModal
                    visible={!!this.state.pdfModal}
                    content={this.state.pdfModal}
                    onClose={()=>{
                        this.setState({pdfModal:null})
                    }}
                />
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                    heading='Round Approval'
                    isVisible={this.state.modal}
                    onClose={()=>this.setState({modal:false})}
                    onSave={this.saveApproval}
                    saveButtonText='Approve'
                    content={
                        <div>
                            <h5>Confirm approval of the results for this round</h5>
                        </div>
                    }
                />
                <GridContainer>
                    <GridItem xs={12} md={8}>
                        {roundData.itemNo +'  '+sectionData.title+'-'+roundData.roundType}
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        {!roundData.checked ?
                            <Button onClick={()=>this.setState({modal:true})} size='sm' round color='info'><CheckCircle/>Approve Marks</Button>:''
                        }
                        <Button onClick={()=>this.closeMarks()} size='sm' round><Close/>Close Marks</Button>
                    </GridItem>
                    <GridItem xs={12}>
                        <Tabs
                            headerColor={'info'}
                            tabs={[
                                {
                                    tabName:'Marks',
                                    tabIcon:PlaylistAddCheck,
                                    tabContent:(
                                        <div>
                                            {this.props.roundData.roundType !=='Final' && this.props.roundData.roundType!=='Uncontested' ?<h5>Dancers called back: {this.props.roundData.results !== undefined ? this.props.roundData.results.join(',  '):'Not-Processed'}</h5>:''}
                                            {this.renderMarks(roundData,sectionData.title)}
                                            <GridContainer>
                                                {this.props.roundData.dances.includes('exhibition') ||
                                                this.props.roundData.dances.includes('showcase')||
                                                this.props.roundData.dances.includes('showdance')||
                                                this.props.roundData.dances.includes('solo spot') ?
                                                    <GridItem xs={12}>
                                                        <h3>TCIS Marks</h3>
                                                        {this.renderSpecialDanceMark()}
                                                    </GridItem>:''
                                                }
                                                <GridItem xs={12}>
                                                    <h4> Adjudicator Log</h4>
                                                </GridItem>
                                                <GridItem xs={12}>
                                                    <table>
                                                        <thead>
                                                        <tr>
                                                            <td>Letter</td>
                                                            <td>Device</td>
                                                            <td>Time</td>
                                                            <td>Marksheet</td>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {renderMarksDetails(this.props.roundData, (pdfContent)=>{
                                                            this.setState({pdfModal:pdfContent})
                                                        })}
                                                        </tbody>
                                                    </table>
                                                    <h5>Processed @ { new Date(roundData.processedTime).toString()}</h5>
                                                    {roundData.checked ? <h5>Checked @ {new Date(roundData.checkTime).toString()}</h5>:''}
                                                </GridItem>
                                            </GridContainer>
                                        </div>
                                    )

                                },
                                {
                                    tabName:'Results',
                                    tabIcon:FormatListBulleted,
                                    tabContent:(
                                        <div>
                                            {this.renderResults(roundData,sectionData, eventDetails)}

                                        </div>
                                )
                                }
                            ]}
                        />
                    </GridItem>
                </GridContainer>

            </div>
        )
    }
}
export default connect(null,{removeMarks,marksTableFinal, approveResult})(ChairCompetitiveMarks);
