import {CLEAR_EVENT, SET_EVENT_DATA, UPDATE_EVENT_INFO} from "../types";
const INITIAL_STATE ={
    loaded:null,
    info:null,
    adjudicators:null,
    panels:null,
    sections:null,
    notes:null,
    status:null,
    publish:false,
    chairPublish:false,
    adjLog:null
};

export default (state=INITIAL_STATE, action)=>{
    switch(action.type){
        case UPDATE_EVENT_INFO:
            return {
                ...state,
                info:action.payload
            };
        case SET_EVENT_DATA:
            const {info,adjudicators,panels,sections,notes,status,publish,chairPublish,adjLog}=action.payload;
            return {
                loaded: true,
                info:info,
                adjudicators: adjudicators,
                panels: panels,
                sections:sections,
                notes:notes,
                status: status,
                publish:publish,
                chairPublish: chairPublish,
                adjLog:adjLog !== undefined?adjLog:null
            };
        case CLEAR_EVENT:
            return {loaded:false, date:null};
        default:
            return {...state}
    }
}