import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import {FormatListBulleted, Group, HourglassEmpty, ListAlt} from "@material-ui/icons";
import PropTypes from "prop-types";

//components
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Card from "../Card/Card";
import CardHeader from "../Card/CardHeader";
import CardIcon from "../Card/CardIcon";
import CardBody from "../Card/CardBody";
import dashboardStyle from "../../assets/jss/material-dashboard-react/views/dashboardStyle";
import Table from "../Table/Table";

class Dashboard extends React.Component{

    state={
        roundsAmount:0,
        dancersAmount:0,
        sectionsNum:0,
        complete:0,
        checked:false
    };

    renderAdjuds=()=>{
        if(this.props.eventData.adjudicators !== null && this.props.eventData.adjudicators!== undefined ){
            const {adjudicators} = this.props.eventData;
            const tableData = Object.keys(adjudicators).reduce((a,v)=>{
                const adj = adjudicators[v];
                a.push([
                    adj.letter,
                    adj.name,
                    adj.loggedIn.toString(),
                    adj.deviceNumber
                ]);

                return a;
            },[]);
            const tableHead = ['Letter','Name', 'Logged In','Device'];

            return (
                <Table
                    tableHead={tableHead}
                    tableData={tableData}
                    tableHeaderColor={'info'}
                />
            )

        }
    };

    renderPanels=()=>{
        if(this.props.eventData.panels !== null && this.props.eventData.panels !== undefined){
            const panels = this.props.eventData.panels;
            const tableData = Object.keys(panels).reduce((a,v,i)=>{
                a.push([
                    i+1,
                    Object.keys(panels[v]).reduce((ar, val)=>{

                        ar.push(panels[v][val].letter);


                        return ar
                    },[])
                ]);
                return a;
            },[]);
            const tableHead=['Number','Letters'];
            return (
                <Table
                    tableHeaderColor={'rose'}
                    tableData={tableData}
                    tableHead={tableHead}
                />
            )
        }
    };

    renderUpcoming=()=>{
        const {sections} =this.props.eventData;
        if(sections !== null && sections !== undefined){
            const sections = this.props.eventData.sections;
            const tableData = Object.keys(sections).reduce((a,v)=>{
                if(sections[v].rounds !== undefined){
                    Object.keys(sections[v].rounds).map(round=>{
                        const {itemNo,roundNo,roundType,status}=sections[v].rounds[round];
                        if(status === 'ready' ||status === 'active'){
                            a.push([
                                itemNo,
                                sections[v].title,
                                sections[v].type,
                                roundNo,
                                roundType,
                                status
                            ])
                        }
                    })
                }
                return a;
            },[]);
            const tableHead = ['Item','Section','Section Type','RoundNo','RoundType','Status'];
            return(
                <Table
                    tableHead={tableHead}
                    tableData={tableData}
                    tableHeaderColor={'info'}
                />
            )
        }
    };

    renderDancersNum =()=>{
        const {adjudicators,sections,info}=this.props.eventData;
        if(sections !== null && sections !== undefined){
            return Object.keys(sections).reduce((a,v)=>{

                if(sections[v].dancers !== undefined){
                    //a += Object.keys(sections[v].dancers).length;
                    Object.keys(sections[v].dancers).map(dancer=>{
                        const dancerNum = sections[v].dancers[dancer].number;
                        if(!a.includes(dancerNum)){
                            a.push(dancerNum);
                        }
                    })
                }



                return a;
            },[]).length;
        }else{
            return 0;
        }

    };

    renderSectionsNum=()=>{
        const {adjudicators,sections,info}=this.props.eventData;
        if(sections!== null && sections !== undefined){
            return Object.keys(sections).length
        }else{
            return 0;
        }
    };

    renderRoundsNum=()=>{
        const {adjudicators,sections,info}=this.props.eventData;
        if(sections !== null && sections !== undefined){

            return Object.keys(sections).reduce((a,v)=>{
                if(sections[v].rounds !== undefined){
                    a += Object.keys(sections[v].rounds).length
                }
                return a
            },0)

        }else{
            return 0;
        }
    };

    renderCompleteNum=()=>{
        const {sections}=this.props.eventData;
        if(sections !== null && sections !== undefined){
            const total =[];
            const completeNum= Object.keys(sections).reduce((a,v)=>{
                if(sections[v].rounds !== undefined){
                    Object.keys(sections[v].rounds).map(round=>{

                        if(sections[v].rounds[round].status==='completed') {
                            a += 1;
                        }
                        total.push(round)
                    })
                }
                return a
            },0);



            return Math.ceil((completeNum/total.length)*100)

        }else{
            return 0;
        }
    };



    render(){
        const { classes, ...rest } = this.props;
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color={'warning'} stats icon>
                                <CardIcon color={'warning'}>
                                    <Group/>
                                </CardIcon>
                                <p className={classes.cardCategory}>Dancers</p>
                                <h3 className={classes.cardTitle}>{this.renderDancersNum()}</h3>
                            </CardHeader>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color={'info'} stats icon>
                                <CardIcon color={'info'}>
                                    <FormatListBulleted/>
                                </CardIcon>
                                <p className={classes.cardCategory} >Sections</p>
                                <h3 className={classes.cardTitle}>{this.renderSectionsNum()}</h3>
                            </CardHeader>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color={'rose'} stats icon>
                                <CardIcon color={'rose'}>
                                    <ListAlt/>
                                </CardIcon>
                                <p className={classes.cardCategory}>Rounds</p>
                                <h3 className={classes.cardTitle}>{this.renderRoundsNum()}</h3>
                            </CardHeader>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color={'success'} stats icon>
                                <CardIcon color={'success'}>
                                    <HourglassEmpty/>
                                </CardIcon>
                                <p className={classes.cardCategory}>% Complete</p>
                                <h3 className={classes.cardTitle}>{this.renderCompleteNum()}%</h3>
                            </CardHeader>
                        </Card>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color={'info'}>
                                <h3>Upcoming Sections</h3>
                            </CardHeader>
                            <CardBody>
                                {this.renderUpcoming()}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardHeader color={'info'}>
                                <h4>Adjudicators</h4>
                            </CardHeader>
                            <CardBody>
                                {this.renderAdjuds()}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardHeader color={'rose'}>
                                <h4>Panels</h4>
                            </CardHeader>
                            <CardBody>
                                {this.renderPanels()}
                            </CardBody>
                        </Card>
                    </GridItem>

                </GridContainer>
            </div>
        )
    }
}
Dashboard.propTypes={
    classes:PropTypes.object.isRequired
};
export default withStyles(dashboardStyle)(Dashboard);
