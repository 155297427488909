import {database} from "../../../Firebase";
// add note
export const AddNote =(note,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const role = getState().Auth.role;
    const ref= database.ref(`events/${eventId}/notes/${role}`);
    ref.push({
        note,
        dateAdded:Date.now(),
        dateEdited:Date.now()
    },err=>{
        if(err){
            callback({status:2,msg:err.message})
        }
    }).then(()=>{
        callback({status:1})
    })
};
// edit note
export const UpdateNote = (note,noteId,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const role = getState().Auth.role;
    const ref = database.ref(`events/${eventId}/notes/${role}/${noteId}`);
    ref.update({
        note,
        dateEdited:Date.now()
    }, err=>{
        if(err){
            callback({status:2,msg:err.message})
        }
    }).then(()=>{
        callback({status:1})
    });
};

// delete note
export const DeleteNote = (noteId, callBack)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const role = getState().Auth.role;
    const ref = database.ref(`events/${eventId}/notes/${role}/${noteId}`);
    ref.remove().then(()=>{
        callBack()
    })
};

