import React from 'react';
import {connect}from 'react-redux';
import {AddAlert} from "@material-ui/icons";

// components
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Table from "../../../components/Table/Table";
import CustomSwitch from "../../../components/Switch";
import Modal from "../../../components/Modals/Modal";
import Danger from "../../../components/Typography/Danger";
import Snackbar from "../../../components/Snackbar/Snackbar";
import Loading from "../../../components/Loading";
import {UpdatePublishResults} from "../../../Store/Actions/Chairperson";

class EventDetails extends React.Component{

    state={
        loading:false,
        publishCheck:false,
        publishModal: false,
        publishHead:'',
        publishError: '',
        notif:false,
        notifMsg:''
    };
    componentDidMount=()=> {
        this.setState({publishCheck: this.props.publish});
    };
    renderInfo=()=>{
        if(this.props.loaded){
            const {title,scrutineer,venue, date,marshall,organizer,organization}=this.props.info;
            return (
                <div>
                    <h3>{title}</h3>
                    <table>
                        <tbody>
                            <tr>
                                <td>Date</td>
                                <td>{date}</td>
                            </tr>
                            <tr>
                                <td>Venue</td>
                                <td>{venue}</td>
                            </tr>
                            <tr>
                                <td>Organization</td>
                                <td>{organization}</td>
                            </tr>
                            <tr>
                                <td>Organizer</td>
                                <td>{organizer.name}</td>
                                <td>{organizer.contact}</td>
                            </tr>
                            <tr>
                                <td>Scrutineer</td>
                                <td>{scrutineer.name}</td>
                                <td>{scrutineer.contact}</td>
                            </tr>
                            <tr>
                                <td>Marshall</td>
                                <td>{marshall.name}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            )
        }
    };

    renderAdjudicators=()=>{
      if(this.props.adjuds !== null && this.props.adjuds !== undefined){
          const adjuds = this.props.adjuds;
            const tableData = Object.keys(adjuds).reduce((a,v)=>{
                a.push([
                    adjuds[v].letter,
                    adjuds[v].name,
                    adjuds[v].loggedIn.toString()
                ]);
                return a;
            },[]);

          const tableHead =['Letter','Name','Logged In'] ;
          return (
              <Table
                tableData={tableData}
                tableHead={tableHead}
                tableHeaderColor={'rose'}
              />
          )
      }
    };

    renderPanels=()=>{
      if(this.props.panels !== null && this.props.panels !== undefined){
            const panels = this.props.panels;

            const tableData = Object.keys(panels).reduce((a,v,i)=>{
                const adjudicators = Object.keys(panels[v]).reduce((ar,val)=>{
                    ar.push(panels[v][val].letter);
                    return ar;
                },[]);
                a.push([i+1,adjudicators]);

               return a;
            },[]);

            const tableHead=['Number','Adjudicators'];
            return (
                <Table
                    tableHeaderColor={'info'}
                    tableHead={tableHead}
                    tableData={tableData}
                />
            )
      }
    };

    confirmPublish=()=>{
        const {publishCheck}=this.state;
        this.setState({loading:true});
        this.props.UpdatePublishResults(!publishCheck,res=>{
            res.status!==1?
                this.setState({loading:false,publishError:res.msg}):
                this.setState({
                    loading:false,
                    publishError:'',
                    publishHead:'',
                    publishModal:false,
                    notif:true,
                    notifMsg:'Publish settings changed successfully!',
                    publishCheck: !publishCheck
                })
        })
    };

    render(){
        const {publishModal,publishHead, publishError, notif, notifMsg, loading}=this.state;
        return (
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={notifMsg}
                    open={notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={loading}
                />
                <Modal
                    content={<div>
                        <Danger>{publishError}</Danger>
                        <h4>Are you sure you want to change the publish status of this event?</h4>
                        <p><b>Note-by changing the status you are releasing the results to public viewing</b></p>
                    </div>}
                   isVisible={publishModal}
                   heading={publishHead}
                   errorMessage={publishError}
                   onClose={()=>this.setState({publishModal: false})}
                   onSave={this.confirmPublish}
                />
                <GridContainer>
                    <GridItem xs={12} md={this.props.status==='complete'?8:12}>
                        <Card>
                            <CardHeader color='info'>
                                <h5>Details</h5>
                            </CardHeader>
                            <CardBody>
                                {this.renderInfo()}
                            </CardBody>
                        </Card>
                    </GridItem>
                    {this.props.status ==='complete'?
                        <GridItem xs={12} md={4}>
                            <Card>
                                <CardHeader color='rose'>
                                    <h4>Publish results</h4>
                                </CardHeader>
                                <CardBody>
                                    <CustomSwitch
                                        label='Publish Results'
                                        checked={this.state.publishCheck}
                                        onChange={()=>this.setState({publishModal:true,publishHead:this.state.publishCheck?'Un publish results':'Publish results'})}
                                        value={'publish'}
                                    />
                                </CardBody>
                            </Card>
                        </GridItem>:null

                    }

                    <GridItem xs={12} md={8}>
                        <Card>
                            <CardHeader color={'rose'}>
                                <h5>Adjudicators</h5>
                            </CardHeader>
                            <CardBody>
                                {this.renderAdjudicators()}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <Card>
                            <CardHeader color={'info'}>
                                <h5>Panels</h5>
                            </CardHeader>
                            <CardBody>
                                {this.renderPanels()}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            </div>
        )
    }
}
const mapStateToProps=state=>{
    if(state.eventData.loaded){
        return{
            loaded:state.eventData.loaded,
            info:state.eventData.info,
            adjuds:state.eventData.adjudicators,
            panels:state.eventData.panels,
            status:state.eventData.status,
            publish:state.eventData.chairPublish!== undefined?state.eventData.chairPublish:false
        }
    }else{
        return {
            loaded:state.eventData.loaded
        }
    }

};


export default connect(mapStateToProps,{UpdatePublishResults})(EventDetails);