import React from 'react';
import {connect} from 'react-redux';
import {reduxForm, Field} from "redux-form";

// material-ui components
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
// @material-ui/icons
import Close from "@material-ui/icons/Close";


import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import CustomInput from "../CustomInput/CustomInput";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import Danger from "../Typography/Danger.jsx";
import Transition from "../../Functions/Transistion";



class LoginModal extends React.Component{

    onSubmit=(formValues)=>{
        this.props.onSubmit(formValues);
    };

    renderInputs =({input, label, type, placeholder, name, meta})=>{
        let errorStatus = false;
        meta.error ? errorStatus=true:errorStatus=false;
        return (
            <CustomInput
                labelText={label}
                id={name}
                formControlProps={{fullWidth:true}}
                inputProps={{...input,type:type}}
                placeholder={placeholder}
                error={errorStatus}
            />
        )
    };


    render(){
        const {classes} = this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <Danger>{this.props.errorMessage}</Danger>
                            <GridContainer>
                                <GridItem xs={12} sm={12} >
                                    <Field id='email' type='email' placeholder='youremail@mail.com' name='email' label='Email' component={this.renderInputs}/>
                                </GridItem>
                                <GridItem xs={12} sm={12}>
                                    <Field id='password' type='password' placeholder='******' name='password' label='Password' component={this.renderInputs}/>
                                    <Button simple size={'sm'} color="transparent" onClick={this.props.onPassword} >Forgot password?</Button>
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Login
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>

            </div>
        )
    }
}
const validate=(formValues)=>{
    const errors={};
    const {email, password}=formValues;

    if(!email){
        errors.email='Please enter a your email!';
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email address';
    }
    if(!password){
        errors.password ='You must enter a password'
    }
    return errors;
};


const form = reduxForm({
    form:'loginForm',
    validate
})(LoginModal);

export default connect(null)(withStyles(modalStyle)(form));