import React from 'react';
import {connect}from 'react-redux';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import Danger from "../../components/Typography/Danger";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import Transition from "../../Functions/Transistion";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import {DropzoneArea} from "material-ui-dropzone";

class ImageGalleryModal extends React.Component{

    state={
        selectedLogo:'',
        newImage:false,
        files:[],
        error:''
    };

    componentDidMount=()=>{
        if(this.props.selectedImage !== null){
            this.setState({selectedLogo:this.props.selectedImage})
        }
    };

    handleImageSelect=(id,name,url)=>{
        this.setState({
            selectedLogo:{logoId:id,name,url}
        })
    };

    renderImages=()=>{
        const {logos}=this.props;
        const {selectedLogo}=this.state;
        if(logos!== null){
            return(
                <GridContainer>
                    {Object.keys(logos).map(id=>{
                        const {name,url}=logos[id];
                        return(
                            <GridItem xs={12} md={4}>
                                {selectedLogo!=='' && selectedLogo.logoId === id?
                                    <Button onClick={()=>this.handleImageSelect(id,name,url)} color={'white'}>
                                        <img src={logos[id].url} style={{width:50}}/>
                                    </Button>:
                                    <Button onClick={()=>this.handleImageSelect(id,name,url)} color={'default'}>
                                        <img src={logos[id].url} style={{width:50}}/>
                                    </Button>
                                }

                            </GridItem>
                        )

                    })}

                    <GridItem xs={12}>
                        <Button onClick={()=>this.setState({newImage:true})} size='sm' color='info' round>
                            Upload a new logo
                        </Button>
                    </GridItem>
                </GridContainer>
            )
        }
    };

    renderNewImage=()=>{
        return (
            <div>
                <DropzoneArea
                    filesLimit={1}
                    maxFileSize={500000}
                    onChange={files=>this.handleImageLoad(files)}
                    acceptedFiles={['image/jpeg', 'image/png', 'image/bmp']}
                />
                <Button onClick={()=>this.setState({newImage:false,files:[]})} color={'info'} size={'sm'} round>
                    Image Gallery
                </Button>
            </div>
        )
    };

    handleImageLoad=(files)=>{
        this.setState({files})
    };

    handleSave=()=>{
        const {selectedLogo,newImage,files}=this.state;

        if(newImage){
            // new image
            if(files.length===1){
                //upload image
                this.props.onSave('','new',files)
            }else{
                this.setState({error:'Please upload or select an image!'})
            }
        }else{
            //existing image
            if(selectedLogo!==''){
                //set existing image
                this.props.onSave(selectedLogo);
            }else{
                this.setState({error:'Please select an image!'})
            }
        }
    };

    render(){
        const {classes,isVisible, onClose,errorMessage}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>Logo Gallery</h4>
                        <Danger>{errorMessage}</Danger>
                        <Danger>{this.state.error}</Danger>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <div>
                            {!this.state.newImage?
                                this.renderImages():
                                this.renderNewImage()
                            }
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.handleSave()}
                            type='submit'
                            color="success">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default connect(null)(withStyles(modalStyle)(ImageGalleryModal))

