export const AUTH_LOGIN='authLogin';
export const AUTH_LOGOUT='authLogout';
export const AUTH_SIGN_IN ='authSignIn';
export const AUTH_UPDATE='authUpdate';
export const CLEAR_ALL_EVENTS='clearAllEvents';
export const LOADED_EVENTS='loadEvents';
export const CLEAR_EVENT='clearEvent';
export const NO_EVENTS='no_Events';
export const SET_EVENT_DATA='setEventData';
export const SET_SINGLE_EVENT='setSingleEvent';
export const LOAD_SCRUTINEERS='loadScrutineers';
export const UPDATE_EVENT_INFO='updateEventInfo';
export const ADJ_SAVE='adjSave';
export const LOADED_CHAIR='loadedChair';
export const NO_CHAIR_LOADED='noChairLoaded';
export const DANCER_ADDED='dancerAdded';
export const LOAD_SECTION='loadSection';
export const REMOVE_MARKS='removeMarks';
export const REMOVE_SECTION='removeSection';
export const SECTION_ADDED='sectionAdded';
export const SET_MARKS='setMarks';
export const SET_MULTI_MARKS='setMulitMarks';
export const LOAD_TERMS='loadTerms';
export const LOAD_PRIVACY='loadPrivacy';