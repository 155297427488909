import React from 'react';
import {connect}from 'react-redux';
import withStyles from "@material-ui/core/styles/withStyles";
import ReactSelectMaterialUi from "react-select-material-ui";
//material icons
import {Edit, AddAlert, Save, Input} from '@material-ui/icons';
//material components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import Loading from "../../../components/Loading";
//my components
import Modal from "../../../components/Modals/Modal";
import EventModal from '../../../components/Modals/EventModal';
import ColorSelector from "../Components/ColorSelector";
import Danger from "../../../components/Typography/Danger";
//my functions
import {
    TransferScrutineer,
    getScrtuineers,
    updateColors,
    getLogos,
    updateLogo,
    uploadNewLogo,
    updatePublish, getEvents
} from "../../../Store/Actions/Scrutineer/EventActions";
import {updateEventInfo, updateStatus, updateChairperson, updateMarshall, getChairperson} from "../../../Store/Actions/Scrutineer/SingleEventActions";
import Snackbar from "../../../components/Snackbar/Snackbar";
import CustomSwitch from "../../../components/Switch";
import styles from '../../../assets/jss/material-kit-react/customCheckboxRadioSwitch.jsx';
import MarshalModal from "../../../components/Modals/MarshalModal";
import ImageGalleryModal from "../../../components/Modals/ImageGalleryModal";
import history from "../../../history";

class ScrutinDetails extends React.Component{

    componentWillMount=async()=> {
        this.props.getChairperson();
        this.props.getScrtuineers(res=>{
            res.status===1?
               console.log('working'):console.log(res.msg);
        });
        this.props.getLogos(res=>
            res.status ===1? this.setState({logos:res.logos}):null
        );

    };

    componentDidMount=async()=>{


        this.setState({
            publishCheck:this.props.publishState,
            chairPersonEnabled:this.props.info.chairPersonEnabled,
            chairPerson:this.props.info.chairPerson
        });

        const status = this.props.status;
        status !== null?
            status ==='not-started'? this.changeStatus('notStartedCheck'):
                status ==='live'?this.changeStatus('liveCheck'):
                    this.changeStatus('completeCheck'):
                    this.changeStatus('notStartedCheck');
    };


    state={
        selectedScrtuineer:'',
        transferModal:false,
        logos:null,
        infoModal:false,
        loading:false,
        eventInfoError:'',
        notification:false,
        notificationContent:'',
        completeCheck:false,
        notStartedCheck:false,
        liveCheck:false,
        publishCheck:false,
        chairPersonEnabled:true,
        modal:false,
        chairPerson:'',
        marshalModal:false,
        imageModal:false,
        imageError:'',
        publishModal:false,
        publishHead:'',
        publishError:''
    };
    onInfoSave=(formValues)=>{
        this.setState({loading:true});
       this.props.updateEventInfo(formValues,(res)=>{
           res.status !== 1 ?this.setState({loading:false, eventInfoError:res.msg}):
               this.setState({loading:false, eventInfoError:'', notification:true, notificationContent:'Details Saved', infoModal:false })
       })
    };

    changeStatus =(status)=>{
        status ==='notStartedCheck'?this.setState({completeCheck:false,notStartedCheck:true,liveCheck:false,}):
            status ==='liveCheck'? this.setState({completeCheck:false,notStartedCheck:false,liveCheck:true,}):
                this.setState({completeCheck:true,notStartedCheck:false,liveCheck:false,});
    };

    saveStatus =()=>{
        this.setState({loading:true});
        const {completeCheck, liveCheck, notStartedCheck}=this.state;
        let status ='';
        completeCheck ? status='complete':liveCheck ?status='live':status='not-started';

        this.props.updateStatus(status,(res)=>{
            res.status ===1 ?
                this.setState({loading:false, notification:true, notificationContent:'Status saved'}):
                this.setState({loading:false, notification:true, notificationContent:'Error with saving'})
        })
    };

    chairpersonsRender=()=>{
        if(this.props.info !== null){
            //need to build function to get all adjudicators
            if(this.props.chairpersons !== null){

                const chairpersonList = this.props.chairpersons.reduce((a,v)=>{

                    a.push(v[1]);
                    return a;
                },[]);

                return (
                    <div>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h5>Select a chairperson from the list below</h5><br/>
                                <ReactSelectMaterialUi
                                    style={{width:'100%'}}
                                    value={this.state.chairPerson}
                                    options={chairpersonList}
                                    onChange={(value)=>this.setState({chairPerson:value})}
                                />
                            </GridItem>
                        </GridContainer>
                    </div>
                )


            }else{
                return (
                    <div>
                        No Chairpersons have been loaded yet!
                    </div>
                )
            }

        }
    };

    saveChairman=()=>{
        if(this.props.chairpersons !== null){

            const chairpersonList = this.props.chairpersons.reduce((a,v)=>{

                a.push(v[1]);
                return a;
            },[]);


            this.setState({loading:true});
            const{chairPersonEnabled, chairPerson}=this.state;

            const chairId = this.props.chairpersons.reduce((a,v)=>{
                if(v[1]=== chairPerson){
                    a=v[0];
                }

                return a;
            },'');


            this.props.updateChairperson(chairPersonEnabled, chairPerson,chairId,()=>{
                this.setState({loading:false, modal:false, notification:true, notificationContent:'Chairperson settings saved'})
            })
        }

    };

    saveMarshall=(formValues)=>{
      this.setState({loading:true});
      this.props.updateMarshall(formValues,()=>{
          this.setState({loading:false,marshallModal:false,notification:true,notificationContent:'Marshall Updated'})
      })
    };

    renderImage=()=>{
      return(

              <GridContainer>
                  <GridItem xs={12}>
                      <img style={{height:100}} src={this.props.info.logo.url} alt={'Event Logo'}/>
                  </GridItem>
                  <GridItem xs={12}>
                      <Button onClick={()=>this.setState({imageModal:true})} size={'sm'} color={'info'} round><Edit/>Edit</Button>
                  </GridItem>
              </GridContainer>

      );

    };

    handleColorSave=(color,type)=>{
        this.setState({loading:true});
        this.props.updateColors(color,type,this.props.eventId,res=>{
            res.status!==1?
                this.setState({loading:false}):
                this.setState({
                    loading:false,
                    notification:true,
                    notificationContent:type+'color changed successfully'
                })
        })
    };

    handleSaveImage=(image,mode,files)=>{
        this.setState({loading:true});
        if(mode!=='new'){
            // use existing data

            this.props.updateLogo(image,this.props.eventId,res=>{
                res.status !== 1?
                    this.setState({loading:false,imageError:res.msg}):
                    this.setState({
                             loading:false,
                             imageModal:false
                         })

            })

        }else{
           // upload image first then use details
            this.props.uploadNewLogo(files,this.props.eventId,res=>{
                res.status !== 1?
                    this.setState({loading:false,imageError:res.msg}):
                    this.props.getLogos(res=>{
                        this.setState({
                            loading:false,
                            imageModal:false,
                            logos:res.logos
                        })
                    })
            })
        }

    };

    changePublish=()=>{
        const {publishCheck}=this.state;
        this.setState({loading:true});
        this.props.updatePublish(!publishCheck,this.props.eventId,res=>{
            res.status!==1?
                this.setState({loading:false,publishError:res.msg}):
                this.setState({
                    loading:false,
                    publishError:'',
                    publishHead:'',
                    publishModal:false,
                    notification:true,
                    notificationContent:'Publish settings changed successfully!',
                    publishCheck: !publishCheck
                })
        })
    };

    handleChange = (value) => {
        this.setState({selectedScrtuineer:value})
    };

    renderScrtuineers=()=>{

        const data = this.props.scrutineers;
        if(data.length>0){

            const ScrutineerOptions= data.reduce((a,v,i)=>{
                const {name}=data[i];
                a.push(name);
                return a;
            },[]);

            return (
                <div>
                    <ReactSelectMaterialUi
                        label={'Scrutineers'}
                        value={this.state.selectedScrtuineer}
                        style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                        options={ScrutineerOptions}
                        onChange={this.handleChange}
                    />
                    <Button onClick={()=>this.setState({transferModal:true})} color={'success'} size={'sm'} round>
                        <Input/>
                        Change Owner
                    </Button>
                </div>

            )

        }else{
            return (
                <div>
                    Loading...
                </div>
            )
        }


    };

    confirmChangeOwner=()=>{
      const selectedScrtuineer = this.state.selectedScrtuineer;
        this.setState({loading:true});
      if(selectedScrtuineer!==''){
          const scrutineers = this.props.scrutineers;
          const scrutineer =scrutineers.reduce((a,v,i)=>{

              const {name,uid,email}=scrutineers[i];

              if(name === selectedScrtuineer){
                  a={
                      uid,
                      email,
                      name
                  }
              }
             return a;
          },{});

          this.props.TransferScrutineer(scrutineer,this.props.eventId,res=>{
              if(res.status===1){
                  this.props.getEvents();
                  history.push('/officials/scrutineer')
              }else{
                  console.log(res.msg)
              }
          })


      }else{
          alert('Please select a scrutineer to transfer to!')
      }

    };

    render() {

        const {info, classes, ...rest } = this.props;
        const {logos,imageModal, imageError, publishHead,publishModal,publishError, transferModal}=this.state;
        return(
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notificationContent}
                    open={this.state.notification}
                    close
                    closeNotification={()=>this.setState({notification:false,notificationContent:''})}
                />
                <Loading
                    show={this.state.loading}
                />
                <EventModal
                    errorMessag={this.state.eventInfoError}
                    heading={'Edit Event'}
                    isVisible={this.state.infoModal}
                    onClose={()=>this.setState({infoModal:false})}
                    onSubmit={this.onInfoSave}
                    saveButtonColor={'success'}
                />
                <Modal
                    isVisible={this.state.modal}
                    heading={'Chairperson'}
                    onClose={()=>this.setState({modal:false})}
                    onSave={this.saveChairman}
                    content={this.chairpersonsRender()}
                />
                <Modal
                    isVisible={publishModal}
                    heading={publishHead}
                    onClose={()=>this.setState({publishModal:false})}
                    content={<div>
                        <Danger>{publishError}</Danger>
                        <h4>Are you sure you want to change the publish status of this event?</h4>
                        <p><b>Note-by changing the status you are releasing the results to public viewing</b></p>
                    </div>}
                    onSave={this.changePublish}
                />
                <Modal
                    isVisible={transferModal}
                    heading={'Confirm Transfer'}
                    onClose={()=>this.setState({transferModal:false})}
                    content={<div><Danger>Are you sure you want to change ownership of this event? This cannot be undone and you will loose all rights to view,edit,update the event!</Danger></div>}
                    onSave={this.confirmChangeOwner}
                />
                <MarshalModal
                    isVisible={this.state.marshalModal}
                    heading={'Marshall'}
                    onClose={()=>this.setState({marshalModal:false})}
                    onSave={this.saveMarshall}
                />
                <ImageGalleryModal
                    selectedImage={info.logo!== undefined?info.logo:null}
                     logos={logos}
                     isVisible={imageModal}
                     errorMessage={imageError}
                     onClose={()=>this.setState({imageModal: false})}
                     onSave={this.handleSaveImage}
                />
                <GridContainer>
                    {/* details card*/}
                    <GridItem xs={12} md={8}>
                        <Card>
                            <CardHeader color={'primary'}>
                                <h4>Details</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12}>
                                        <table>
                                            <tbody>
                                                <tr>
                                                    <td>Title</td>
                                                    <td>{this.props.info !== null ? this.props.info.title: ''}</td>
                                                </tr>
                                                <tr>
                                                    <td>Date</td>
                                                    <td>{this.props.info !== null? this.props.info.date:''}</td>
                                                </tr>
                                            <tr>
                                                <td>Venue</td>
                                                <td>{this.props.info !== null? this.props.info.venue:''}</td>
                                            </tr>
                                            <tr>
                                                <td>Organization</td>
                                                <td>{this.props.info !== null ? this.props.info.organization:''}</td>
                                            </tr>
                                            <tr>
                                                <td>Organizer</td>
                                                <td>{this.props.info !== null? this.props.info.organizer.name:''}</td>
                                                <td>{this.props.info !== null? this.props.info.organizer.contact:''}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div style={{textAlign:'right'}}>
                                            <Button onClick={()=>this.setState({infoModal:true})} size={'sm'} round color={'info'}><Edit/>Edit</Button>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <h5>Transfer Ownership</h5>
                                        <p>You can transfer your event to another scrutineer here.</p>
                                        {this.renderScrtuineers()}
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>
                    {/* status card card*/}
                    <GridItem xs={12}  md={4}>
                        <Card>
                            <CardHeader color={"success"}>
                                <h4>Status</h4>
                                <p>Change the status of the event here, for other parties to access the event ensure it is saved to live.</p>
                            </CardHeader>
                            <CardBody>
                                <CustomSwitch
                                    label={'Not Started'}
                                    checked={this.state.notStartedCheck}
                                    onChange={()=>this.changeStatus('notStartedCheck')}
                                    value={'not-started'}
                                />
                                <CustomSwitch
                                    label={'Live'}
                                    checked={this.state.liveCheck}
                                    onChange={()=>this.changeStatus('liveCheck')}
                                    value={'live'}
                                />
                                <CustomSwitch
                                    label={'Complete'}
                                    checked={this.state.completeCheck}
                                    onChange={()=>this.changeStatus('completeCheck')}
                                    value={'complete'}
                                />
                                {this.props.status==='complete'?
                                    <div>
                                        <CustomSwitch
                                            label='Publish Results'
                                            checked={this.state.publishCheck}
                                            onChange={()=>this.setState({publishModal:true,publishHead:this.state.publishCheck?'Un publish results':'Publish results'})}
                                            value={'publish'}
                                        />
                                    </div>
                                    :null
                                }
                                <div>
                                    <Button onClick={()=>this.saveStatus()} color={'info'} size='sm' round><Save/>Update Status</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/* Marshall Card card*/}
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardHeader color={'info'}>
                                <h4>Marshal Details</h4>
                                <p>Marshal settings for the event</p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        Name
                                    </GridItem>
                                    <GridItem xs={8}>
                                        {this.props.info.marshall.name}
                                    </GridItem>
                                    <GridItem xs={4}>
                                       Login Code
                                    </GridItem>
                                    <GridItem xs={8}>
                                        {this.props.info.marshall.code}
                                    </GridItem>
                                </GridContainer>
                                <div style={{textAlign:'right'}}>
                                    <Button onClick={()=>this.setState({marshalModal:true})}   size={'sm'} round color={'info'} ><Edit/>Edit</Button>
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                    {/* chairperson card card*/}
                    <GridItem xs={12} md={6}>
                        <Card>
                            <CardHeader color={'warning'}>
                                <h4>ChairPerson</h4>
                            </CardHeader>
                            <CardBody>
                                <CustomSwitch
                                    label='Chairperson Enabled'
                                    checked={this.state.chairPersonEnabled}
                                    onChange={()=>this.setState({chairPersonEnabled:!this.state.chairPersonEnabled})}
                                    value={'chairPerson'}
                                />
                                {this.state.chairPersonEnabled ?
                                    (
                                        <GridContainer>
                                            <GridItem xs={4}>
                                                Chairman
                                            </GridItem>
                                            <GridItem xs={4}>
                                                {this.props.info.chairPerson}
                                            </GridItem>
                                            <GridItem xs={4}>
                                                <Button onClick={()=>this.setState({modal:true})}  color={'info'} size={'sm'} round><Edit/>Edit</Button>
                                            </GridItem>
                                        </GridContainer>
                                    ): <Button onClick={()=>this.saveChairman()}  color={'success'} size={'sm'} round><Save/>Save</Button>
                                }

                            </CardBody>
                        </Card>
                    </GridItem>
                    {/* Design card card*/}
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color={'rose'}>
                                <h4>Design Settings</h4>
                                <p>Event design settings to display logo and background colors for live page</p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} md={4}>
                                        <h4>Logo</h4>
                                        {info.logo!==undefined?
                                            this.renderImage():<Button onClick={()=>this.setState({imageModal:true})} color={'info'} round size={'sm'}>Add Logo</Button>
                                        }
                                    </GridItem>
                                </GridContainer>

                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

            </div>
        )
    }
}

const mapStateToProps = state=>{
  return {
      eventId:state.eventId.eventId,
      info:state.eventData.info,
      status:state.eventData.status,
      chairpersons:state.chairpersons.chairpersons,
      scrutineers:state.chairpersons.scrutineers,
      publishSate:state.eventData.publish!== undefined?state.eventData.publish:false
  }
};

export default connect(mapStateToProps, {
    updateEventInfo,
    updateStatus,
    updateChairperson,
    updateMarshall,
    getChairperson,
    updateColors,
    getLogos,
    updateLogo,
    uploadNewLogo,
    updatePublish,
    getScrtuineers,
    TransferScrutineer,
    getEvents
})(withStyles(styles)(ScrutinDetails));
