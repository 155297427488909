import React from 'react';
import {connect} from 'react-redux';
import {InputAdornment, Tooltip} from "@material-ui/core";
import {Search, PlaylistAddCheck, Reorder, FilterList, List, Close} from "@material-ui/icons";


import Button from "../../../components/CustomButtons/Button";
import Table from "../../../components/Table/Table";
import history from "../../../history";
import {setMarks, setMultiDanceMarks} from "../../../Store/Actions/Scrutineer/SectionActions";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import Tabs from '../../../components/CustomTabs/CustomTabs';

class EventResults extends React.Component{

    state={
        search:'',
        searchFinal:'',
        searchSection:''
    };

    handleChange = name=>event=>{
        const value = event.target.value;
        this.setState({[name]:value})
    };

    viewMarks=(sectionId, roundId,roundData)=>{
        this.props.setMarks(sectionId,roundId,roundData,()=>history.push('/chairperson/event/results/marks'))
    };

    renderRounds =()=>{
        const sections  = this.props.sections;
        const search = this.state.search.toLocaleLowerCase();
        if(sections !== null && sections !== undefined){
            const tableData =Object.keys(sections).reduce((a,v)=>{

                if(sections[v].rounds !== undefined){
                    const rounds = sections[v].rounds;
                    Object.keys(rounds).map((ar)=>{
                        const info = rounds[ar];
                        if(info.marks !== undefined){
                            if(sections[v]?.title?.toLocaleLowerCase().includes(search)
                                || info.itemNo.includes(search)
                                || info.roundType.toLocaleLowerCase().includes(search)
                                || info.status.toLocaleLowerCase().includes(search)
                                || sections[v].type.toLocaleLowerCase().includes(search)
                            ){
                                a.push([
                                    parseInt(info.itemNo),
                                    sections[v].title,
                                    info.roundType,
                                    info.roundNo,
                                    info.panel.panel,
                                    sections[v].type,
                                    info.status,
                                    (<div>

                                        {
                                            info.processed ?(
                                                <Tooltip title='View Results'>
                                                    <Button onClick={()=>this.viewMarks(v,ar,info)} color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                                </Tooltip>
                                            ):''
                                        }
                                    </div>)
                                ])
                            }
                        }

                    })
                }

                return a;
            },[]).sort((a,b)=>a[0]-b[0]);

            const tableHead = ['Item no','Section', 'Round Type','Round No','Panel','Type', 'Status', 'Action'];
            return (
                <Table
                    tableHeaderColor={'rose'}
                    tableHead={tableHead}
                    tableData={tableData}
                />
            )
        }
    };

    renderFinalRound=()=>{
        const sections  = this.props.sections;
        const search = this.state.searchFinal.toLocaleLowerCase();
        if(sections !== null && sections !== undefined){
            const tableData =Object.keys(sections).reduce((a,v)=>{

                if(sections[v].rounds !== undefined){
                    const rounds = sections[v].rounds;
                    Object.keys(rounds).map((ar)=>{
                        const info = rounds[ar];
                        if(info.marks !== undefined){
                            if(sections[v]?.title?.toLocaleLowerCase().includes(search)
                                || info.itemNo.includes(search)
                                || info.roundType.toLocaleLowerCase().includes(search)
                                || info.status.toLocaleLowerCase().includes(search)
                                || sections[v].type.toLocaleLowerCase().includes(search)
                            ){
                                if(info.roundType ==='Final'|| info.roundType==='Uncontested'|| info.roundType==='Contested'){
                                    a.push([
                                        parseInt(info.itemNo),
                                        sections[v].type==='multiDance'?sections[v].title+'-'+info.dances[0]:sections[v].title,
                                        info.roundType,
                                        info.roundNo,
                                        sections[v].type,
                                        info.status,
                                        (<div>
                                            {
                                                info.processed?(
                                                    <Tooltip title='View Results'>
                                                        <Button onClick={()=>this.viewMarks(v,ar,info)} color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                                    </Tooltip>
                                                ):''
                                            }
                                        </div>)
                                    ])
                                }

                            }
                        }

                    })
                }

                return a;
            },[]).sort((a,b)=>a[0]-b[0]);

            const tableHead = ['Item no','Section', 'Round Type','Round No','Type', 'Status', 'Action'];
            return (
                <Table
                    tableHeaderColor={'warning'}
                    tableHead={tableHead}
                    tableData={tableData}
                />
            )
        }
    };

    renderSections=()=>{
        const sections  = this.props.sections;
        const search = this.state.searchSection.toLocaleLowerCase();

        if(sections !== null && sections !== undefined){

            const tableData = Object.keys(sections).reduce((a,v)=>{


                const {type,title,processed,finalResult, rounds}=sections[v];

                if(type==='multiDance'){
                    if(title?.toLocaleLowerCase().includes(search)){

                        if(rounds!== undefined){

                            const marksCheck =[];
                            Object.keys(rounds).map(round=>{
                                const {processed}=rounds[round];
                                if(processed){
                                    marksCheck.push(round);
                                }
                            });

                            a.push([
                                title,
                                type,
                                processed!== undefined?processed.toString():'False',
                                (<div>
                                    {marksCheck.length === Object.keys(rounds).length?
                                        <Tooltip title={'View Marks'}>
                                            <Button onClick={()=>this.viewSectionMarks(v,sections[v])}  color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                        </Tooltip>:''
                                    }
                                </div>)
                            ])
                        }

                    }

                }

                return a;
            },[]);

            if(tableData.length >0){

                const tableHead=['Title','Type','Processed','Actions'];

                return (
                    <Table
                        tableHead={tableHead}
                        tableData={tableData}
                        tableHeaderColor={'warning'}
                    />
                )
            }else{
                return (
                    <div>No Multi Dance sections!</div>
                )
            }




        }else{
            return (<div>No Sections added yet!</div>)
        }


    };

    viewSectionMarks=(sectionId,sectionData)=>{
        this.props.setMultiDanceMarks(sectionId,sectionData,()=>{
            history.push('/chairperson/event/results/marks')
        })
    };


    render(){
        return (
            <div>
                <Tabs
                    headerColor={'rose'}
                    tabs={[
                        {
                            tabName:'Round Results',
                            tabIcon:Reorder,
                            tabContent:(
                                <div>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8}/>
                                        <GridItem xs={12} sm={4}>
                                            <CustomInput
                                                style={{justifyContent:'flex-start'}}
                                                value={this.state.search}
                                                labelText={'Search'}
                                                id={'search'}
                                                formControlProps={{
                                                    fullWidth: false
                                                }}
                                                inputProps={{
                                                    autoComplete:'off',
                                                    value:this.state.search,
                                                    onChange:(this.handleChange('search')),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Search/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {this.renderRounds()}
                                </div>
                            )
                        },
                        {
                            tabName:'Final Results',
                            tabIcon:FilterList,
                            tabContent:(
                                <div>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8}/>
                                        <GridItem xs={12} sm={4}>
                                            <CustomInput
                                                style={{justifyContent:'flex-start'}}
                                                value={this.state.search}
                                                labelText={'Search'}
                                                id={'searchFinal'}
                                                formControlProps={{
                                                    fullWidth: false
                                                }}
                                                inputProps={{
                                                    autoComplete:'off',
                                                    value:this.state.searchFinal,
                                                    onChange:(this.handleChange('searchFinal')),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Search/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {this.renderFinalRound()}
                                </div>
                            )
                        },
                        {
                            tabName:'Multi Dance Section Results',
                            tabIcon:List,
                            tabContent:(
                                <div>
                                    <GridContainer>
                                        <GridItem xs={12} sm={8}/>
                                        <GridItem xs={12} sm={4}>
                                            <CustomInput
                                                style={{justifyContent:'flex-start'}}
                                                value={this.state.searchSection}
                                                labelText={'Search'}
                                                id={'searchSection'}
                                                formControlProps={{
                                                    fullWidth: false
                                                }}
                                                inputProps={{
                                                    autoComplete:'off',
                                                    value:this.state.searchSection,
                                                    onChange:(this.handleChange('searchSection')),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Search/>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </GridItem>
                                    </GridContainer>
                                    {this.renderSections()}
                                </div>
                            )
                        }
                    ]}
                />
            </div>
        )
    }
}
const mapStateToProps = state=>{
  return {
      sections:state.eventData.sections
  }
};


export default connect(mapStateToProps,{setMarks,setMultiDanceMarks})(EventResults);
