import React from 'react';
import {connect}from 'react-redux';
import {PlaylistAddCheck, AssignmentInd,GroupWork, List, AddAlert, Search, CheckCircle, Edit} from '@material-ui/icons';
import {InputAdornment, Tooltip} from "@material-ui/core";
// components
import CardHeader from "../../../../components/Card/CardHeader";
import CardBody from "../../../../components/Card/CardBody";
import Card from '../../../../components/Card/Card';
import Table from "../../../../components/Table/Table";
import Button from "../../../../components/CustomButtons/Button";
import Modal from "../../../../components/Modals/Modal";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import ManualMarks from "../../../../Marks/ManualMarks";
import ManualMarks2 from "../../../../Marks/ManualMarks2";
import Loading from "../../../../components/Loading";
import {addManualMarks, setMarks, updateStartList,viewSection} from "../../../../Store/Actions/Scrutineer/SectionActions";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import history from "../../../../history";
import ActiveDancersModal from "../../../../components/Modals/ActiveDancersModal";

class Rounds extends React.Component{

    state={
      modal:false,
      list:[],
      modalHeading:'',
        manualModal:false,
        roundData:'',
        sectionData:null,
        sectionId:'',
        roundId:'',
        notif:false,
        notifMsg:'',
        loading:false,
        manualMarksError:'',
        search:'',
        startList:null,
        activeList:[],
        activeDancersModal:false,
        activeListModalHeading:'',
        activeListId:null,
        dancers:'',
    };
    viewMarks=(sectionId, roundId,roundData)=>{
        this.props.setMarks(sectionId,roundId,roundData,()=>history.push('/scrutineer/Screens/results/marks'))
    };

    openSection=(id)=>{
        this.props.viewSection(id,()=>{
            history.push('/scrutineer/Screens/sections/view')
        })
    };

    renderRounds =()=>{
      const sections = this.props.sections;
      const search = this.state.search.toLocaleLowerCase();
      if(sections !== null && sections !== undefined){
          const tableData =Object.keys(sections).reduce((a,v)=>{

              if(sections[v].rounds !== undefined){
                  const rounds = sections[v].rounds;
                  Object.keys(rounds).map((ar)=>{
                      const info = rounds[ar];
                      if(sections[v]?.title.toLocaleLowerCase().includes(search) || info.itemNo.toLocaleLowerCase().includes(search)||
                          info.roundType.toLocaleLowerCase().includes(search) || info.status.toLocaleLowerCase().includes(search) ){


                          a.push([
                              parseInt(info.itemNo),
                              sections[v].type==='multiDance'?sections[v].title+'-'+info.dances[0]:sections[v].title,
                              info.roundType,
                              info.roundNo,
                              info.panel !== undefined? info.panel.panel:'Unknown-ERROR',
                              sections[v].type,
                              info.marks !== undefined && info.status==='ready'? Object.keys(info.marks).length+'/'+info.panel.letters.split(',').length:info.status,
                              (<div>
                                  <Tooltip title='Edit Section'>
                                      <Button onClick={()=>this.openSection(v)} color='primary' round size='sm' justIcon>
                                          <Edit/>
                                      </Button>
                                  </Tooltip>
                                  {info.startList !== undefined ? (
                                      <Tooltip title='View Start List'>
                                          <Button onClick={()=>this.setState({
                                              modal:true,
                                              list:info.startList,
                                              modalHeading:sections[v].title+'-'+info.roundType+'-Start List'
                                          })} color='info' round size='sm' justIcon><List/></Button>
                                      </Tooltip>
                                  ):'No Start list Set'
                                  }
                                  {info.activeList !== undefined && info.panel !== undefined ?
                                      ( <Tooltip title='View Active List'>
                                          <Button onClick={()=>this.setState({
                                              modal:true,
                                              list:info.activeList,
                                              modalHeading:sections[v].title+'-'+info.roundType+'-Active List'
                                          })} color='rose' round size='sm' justIcon><CheckCircle/></Button>
                                      </Tooltip>):info.startList !== undefined ?
                                          (
                                              <Tooltip title={'Set Active List'}>
                                                  <Button onClick={()=>this.setState({
                                                      dancers:sections[v].dancers,
                                                      sectionId:v,
                                                      activeListId:ar ,
                                                      startList:info.startList,
                                                      activeList:info.activeList !== undefined ?info.activeList:[],
                                                      activeDancersModal:true,
                                                      activeListModalHeading:'Active List '+info.itemNo+' '+sections[v].title+'-'+info.roundType
                                                  })} color='primary' round size='sm' justIcon><GroupWork/></Button>
                                              </Tooltip>
                                          ):''
                                  }{
                                  info.marks !== undefined?(
                                      <Tooltip title='Results'>
                                          <Button onClick={()=>this.viewMarks(v,ar,info)}   color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                      </Tooltip>
                                  ):''
                              }
                                  {
                                      info.activeList !== undefined && info.marks === undefined ?
                                          (
                                              <Tooltip title='Manual Marks'>
                                                  <Button onClick={()=>this.setState({roundId:ar, sectionData: sections[v], sectionId:v,roundData:rounds[ar], manualModal:true})} color='warning' round size='sm' justIcon><AssignmentInd/></Button>
                                              </Tooltip>
                                          ):''
                                  }
                              </div>)
                          ])
                      }

                  })
              }

             return a;
          },[]).sort((a,b)=>a[0]-b[0]);

          const tableHead = ['Item No','Title','Round Type','Round No','Panel','Type','Status', 'Actions'];
          return (
              <Table
                tableData={tableData}
                tableHead={tableHead}
                tableHeaderColor={'info'}
              />
          )
      }else{
          return (
              <div>
                  <h5>No Rounds Added Yet</h5>
              </div>
          )
      }
    };

    renderLists =()=>{
       return this.state.list.map((item)=>{
           return (
               <GridItem key={item} xs={4}>
                   <h3>{item}</h3>
               </GridItem>
           )
        })
    };

    saveMarks=(sectionType,marks,specialMarks)=>{

        this.setState({loading:true});
       const {sectionId, roundId}= this.state;

           this.props.addManualMarks(sectionId,roundId,marks,specialMarks,(res)=>{
               res.status === 1? this.setState({
                   sectionId: '',
                   roundId: '',
                   loading:false,
                   sectionData:'',
                   notfi:true,
                   notifMsg:'Manual marks added successfully',
                   roundData:'',
                   manualModal:false
               }): this.setState({loading:false, manualMarksError:res.message})
           })

    };

    setActiveList=(values)=>{
        this.setState({loading:true});
        const data = {activeList: values,id:this.state.activeListId};
        const sectionId = this.state.sectionId;
        this.props.updateStartList(data,sectionId,()=>{
            this.setState({
                loading:false,
                startList:null,
                activeList:[],
                activeDancersModal:false,
                activeListModalHeading:'',
                activeListId:null,
                notif:true,
                notifMsg:'Start list updated successfully'
            })
        })
    };

    handleChange = name=>event=>{
        const value = event.target.value;
        this.setState({[name]:value})
    };

    render(){
        return (
            <div>
                <ActiveDancersModal
                    dancers={this.state.dancers}
                    startList={this.state.startList}
                    activeList={this.state.activeList}
                    heading={this.state.activeListModalHeading}
                    isVisible={this.state.activeDancersModal}
                    onClose={()=>this.setState({
                        dancers:'',
                        activeListId:null,
                        activeListModalHeading:'',
                        startList:null,
                        activeList:[],
                        activeDancersModal:false})}
                    onSave={this.setActiveList}
                />
                <ManualMarks
                    sectionData={''}
                    roundData={''}
                    isVisible={false}
                    onClose={()=>this.setState({roundId:'',sectionData:null, manualModal:false, roundData:'', sectionId:'',})}
                    onSave={this.saveMarks}
                />
                <ManualMarks2
                    sectionData={this.state.sectionData}
                    roundData={this.state.roundData}
                    isVisible={this.state.manualModal}
                    onClose={()=>this.setState({roundId:'',sectionData:null, manualModal:false, roundData:'', sectionId:''})}
                    onSave={this.saveMarks}
                />
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                    heading={this.state.modalHeading}
                    isVisible={this.state.modal}
                    onClose={()=>this.setState({modal:false, list:[],modalHeading:''})}
                    saveButtonColor={'info'}
                    saveButtonText={'Close'}
                    onSave={()=>this.setState({modal:false, list:[],modalHeading:''})}
                    content={
                            <GridContainer>
                                {this.renderLists()}
                            </GridContainer>
                    }
                />
               <Card>
                   <CardHeader color='info'>
                       <h4>Rounds</h4>
                   </CardHeader>
                   <CardBody>
                       <GridContainer>
                           <GridItem xs={12} md={8}/>
                           <GridItem xs={12} md={4}>
                               <CustomInput
                                   style={{justifyContent:'flex-start'}}
                                   value={this.state.search}
                                   labelText={'Search'}
                                   id={'search'}
                                   formControlProps={{
                                       fullWidth: false
                                   }}
                                   inputProps={{
                                       autoComplete:'off',
                                       value:this.state.search,
                                       onChange:(this.handleChange('search')),
                                       endAdornment: (
                                           <InputAdornment position="end">
                                               <Search/>
                                           </InputAdornment>
                                       )
                                   }}
                               />
                           </GridItem>
                           <GridItem xs={12}>
                               {this.renderRounds()}
                           </GridItem>
                       </GridContainer>

                   </CardBody>
               </Card>
            </div>
        )
    }
}
const mapStateToProps =(state)=>{
  return {sections: state.eventData.sections}
};

export default connect(mapStateToProps, {addManualMarks, setMarks, updateStartList,viewSection})(Rounds);
