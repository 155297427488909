import React from 'react';
import {withStyles} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import ReactSelectMaterialUi from "react-select-material-ui";

// components
import Button from "../CustomButtons/Button";
import Transition from "../../Functions/Transistion";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import Danger from "../Typography/Danger";

// algorithms
import {returnLeftPLacing} from "../../Marks/Alogrithms/finalAlgo";

class RoundProcessModal extends React.Component{

    state={
        callback:'',
        nextRound:'',
        error:''
    };

    statusChange=(value)=>{
        this.setState({callback:value, error:''})
    };
    roundChange=(value)=>{
        this.setState({nextRound:value,error:''})
    };

    renderSelect=()=>{
        if(this.props.totals !== null){

            const sortedCallback =Object.entries(this.props.totals).sort((a,b)=>b[1]-a[1]).reduce((a,v,i)=>{

                if(a[v[1]]!==undefined){
                    a[v[1]]=a[v[1]]+1
                }else{
                    a[v[1]]=i+1
                }

                return a;
            },{});

            const options=Object.keys(sortedCallback).reverse().reduce((a,v)=>{
                a.push(v+'-'+sortedCallback[v]);
                return a;
            },[]);

            return(
                <ReactSelectMaterialUi
                    label='Callback - Dancers'
                    value={this.state.callback}
                    options={options}
                    style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                    onChange={this.statusChange}
                />
            )

        }

    };

    renderNextRound =()=>{
        const rounds = this.props.nextRound;
        if(rounds !== null && rounds !== undefined){
            if(rounds.length !==0){
                const roundOptions=rounds;
                return (
                    <ReactSelectMaterialUi
                        label='Set Next Round'
                        value={this.state.nextRound}
                        options={roundOptions}
                        style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                        onChange={this.roundChange}
                    />
                )
            }else{
                return (
                    <Danger>Next Round cannot be found, please setup the next round to continue!</Danger>
                )
            }
        }

    };

    onSave=()=>{
        const {callback,nextRound}=this.state;
        const results = this.props.totals;
        const activeDancers = this.props.roundData.activeList;
        if(callback !=='' && nextRound!==''){

            const callbackNum = callback .split('-')[0];
            const CallbackArray =Object.keys(results).reduce((a,v)=>{
                if(results[v]>=callbackNum){
                    a.push(v);
                }

                return a
            },[]);

            const placingResult = returnLeftPLacing(results,activeDancers,CallbackArray);

            this.props.onSave(CallbackArray,nextRound, placingResult);

        }else{
            this.setState({error:'Callback or Next Round not set!'})
        }

    };

    render(){
        const {classes}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>Processing</h4>
                        <h4 className={classes.modalTitle}>{this.props.heading}</h4>
                        <Danger>{this.state.error}</Danger>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        {this.renderSelect()}
                        {this.renderNextRound()}
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={this.props.onClose}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='submit'
                            color='success'>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}export default withStyles(modalStyle)(RoundProcessModal);