import React from 'react';
import {connect}from 'react-redux';
//components
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardHeader from "../../../components/Card/CardHeader";
import Button from "../../../components/CustomButtons/Button";
import Card from '../../../components/Card/Card';
import {AddBox, AddAlert,Edit,Close} from '@material-ui/icons';
import CardBody from "../../../components/Card/CardBody";
import {AddNote,UpdateNote,DeleteNote} from "../../../Store/Actions/CrossActions";
import NotesModal from '../../../components/Modals/NotesModal';
import Modal from "../../../components/Modals/Modal";
import Table from "../../../components/Table/Table";
import Loading from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";

class ScrutinNotes extends React.Component{
    state={
        modal:false,
        loading:false,
        notif:false,
        notifMsg:'',
        errorMessage:'',
        noteId:null,
        noteData:'',
        deleteModal:false,
        deleteId:'',
        noteHeading:''
    };

    saveNote=(note)=>{
        this.setState({loading:true});
        this.state.noteId === null ?
        this.props.AddNote(note,res=>{
            res.status !==1 ?
                this.setState({loading:false,errorMessage:res.msg}):
                this.setState({
                    modal:false,
                    loading:false,
                    errorMessage:'',
                    notif:true,
                    notifMsg:'Note Added Successfully!'
                })
        }):
           this.props.UpdateNote(note, this.state.noteId,(res)=>{
               res.status !==1 ?
                   this.setState({loading:false,errorMessage:res.msg}):
                   this.setState({
                       modal:false,
                       loading:false,
                       errorMessage:'',
                       notif:true,
                       notifMsg:'Note Updated Successfully!',
                       noteId:null,
                       noteData:''
                   })
           });
    };

    renderNotes =()=>{
      const notes = this.props.notes;
      if(notes !== null && notes !== undefined){
          const tableData= Object.keys(notes).reduce((a,v,i,b)=>{
              a.push([i+1,
                  notes[v].note.substring(0,15)+'...',
                  new Date(notes[v].dateAdded).toDateString()+' '+new Date(notes[v].dateAdded).toLocaleTimeString()  ,
                  new Date(notes[v].dateEdited).toDateString()+' '+ new Date(notes[v].dateEdited).toLocaleTimeString(),
                  (<div>
                      <Button onClick={()=>this.setState({deleteModal:true,deleteId:v})} color='danger' round justIcon size='sm'><Close/></Button>
                      <Button onClick={()=>this.setState({modal:true,noteData:notes[v].note, noteId:v, noteHeading:'Edit Note'})} color='warning' round justIcon size='sm'><Edit/></Button>
                  </div>)
              ]);
            return a;
          },[]);
          const TableHead =['Number','Note','Date Added', 'Date Edited','Actions'];
          return (
              <Table
                tableHeaderColor='info'
                tableData={tableData}
                tableHead={TableHead}
              />
          )

      }else{
          return(
              <div><h4>No notes added yet!</h4></div>
          )
      }
    };

    onConfirmDelete=()=>{
        this.setState({loading:true});
        this.props.DeleteNote(this.state.deleteId,(res)=>{
            this.setState({
                deleteModal:false,
                loading:false,
                errorMessage:'',
                notif:true,
                notifMsg:'Note Deleted Successfully!',
                deleteId:''
            })
        })
    };

    render() {
        return(
            <div>
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <NotesModal
                    heading={this.state.noteHeading}
                    isVisible={this.state.modal}
                    onClose={()=>this.setState({modal:false, noteData:'', noteId:null})}
                    onSave={this.saveNote}
                    noteData={this.state.noteData}
                />
                <Modal
                    errorMessag={'Are you sure you want to delete this note?'}
                    heading={'Delete Note'}
                    isVisible={this.state.deleteModal}
                    onClose={()=>this.setState({deleteModal:false, deleteId:''})}
                    onSave={this.onConfirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this note?</p>}
                />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color='info'>
                                <h4>Notes</h4>
                                <Button color='white' size='sm' round onClick={()=>this.setState({modal:true, noteHeading:'New Note'})}   ><AddBox/>Add new Note</Button>
                            </CardHeader>
                            <CardBody>
                                {this.renderNotes()}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}

const mapStateToProps =state=>{
    if(state.eventData.notes !== undefined){
        if(state.eventData.notes.scrutineer !== undefined){
            return {notes:state.eventData.notes.scrutineer}
        }else{
            return {notes:null}
        }
    }else{
        return {notes:null}
    }

};

export default connect(mapStateToProps, {AddNote,UpdateNote,DeleteNote})(ScrutinNotes);