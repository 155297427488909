import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import {connect}from 'react-redux';
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
import Danger from "../Typography/Danger";
import Transition from "../../Functions/Transistion";


class ActiveDancersModal extends React.Component{

    state={
        activeList:[],
        editMode:false,
        error:''
    };

    handleButton =(number)=>{
        const activeList = this.state.activeList;
        if(!activeList.includes(number)){
            activeList.push(number);
            this.setState({activeList, error:''});
        }else{
            const newActiveList = activeList.filter(val=>{
                if(val !==number){
                    return val
                }
            });
            this.setState({activeList: newActiveList, error:''});
        }
    };

    componentDidUpdate=(prevProps, prevState)=>{
        if(!prevState.editMode){
            this.setState({activeList:this.props.activeList, editMode:true})
        }
    };

    renderDancers =()=>{
        const startList =this.props.startList;
        const dancers = this.props.loadedDancers;
        //console.log(startList);
        if(startList !== null){
            return startList.map(dancer=>{
                return Object.keys(dancers).sort((a,b)=>a-b).map(key=>{
                    if(dancers[key].number === dancer) {
                        return (
                            <GridItem xs={12} key={key}>
                                <GridContainer>
                                    <GridItem xs={4}>
                                        <Button
                                            onClick={()=>this.handleButton(dancer)}
                                            round
                                            block
                                            size='sm'
                                            color={this.state.activeList.includes(dancer)?'success':'info'}>
                                            {dancer}
                                        </Button>
                                    </GridItem>
                                    <GridItem xs={8}>
                                        {dancers[key].names}
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                        )
                    }
                })
            })
        }

    };

    onSave=()=>{
        this.state.activeList.length >0?
            this.props.onSave(this.state.activeList):
            this.setState({error:'Please select some dancers!'})
    };

    onClose=()=>{
        this.setState({editMode:false});
        this.props.onClose();
    };
    render(){

        const {classes}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <div>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                            <Danger>{this.state.error}</Danger>
                        </div>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <GridContainer>
                            <GridItem>
                                <h4>Select Dancers for this round</h4>
                                <Button onClick={()=>this.setState({activeList:this.props.startList})}  size='sm' round color='rose'>Select All</Button>
                            </GridItem>
                            {this.renderDancers()}
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='submit'
                            color='success'>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = (state,ownProps)=>{
    let dancers= '';
    if(state.section.data !== null){
        dancers =state.section.data.dancers;
    }else{
        dancers = ownProps.dancers
    }
    return {

        loadedDancers:dancers
    }
        ;
};


export default connect(mapStateToProps)(withStyles(modalStyle)(ActiveDancersModal));
