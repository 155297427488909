import React from "react";
import  {connect}from 'react-redux';
import ReactToPrint from "react-to-print";

import Button from "../../../../components/CustomButtons/Button";
import Tabs from "../../../../components/CustomTabs/CustomTabs";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Footer from "../../../../components/Footer/FooterMain";
import {DSDivider} from "../../../../components/Divider";

import {ViewFinalAll} from "./contested/ViewFinal";
import {ViewResultRoundAll} from "./contested/ViewSemiFinal";
import {SocialViewAllMarls} from "./Social/social";
import {MultiDanceViewAll} from "./MultiDance/multiDanceViewAll";


class ViewAllMarks extends React.Component{

    renderSections=()=>{
      const {sections,adjudicators,info, panels}=this.props;

      const AdjuList = Object.keys(adjudicators).reduce((a,v)=>{
          const {letter, name}=adjudicators[v];
          a[letter]=name;
          return a;
      },{});

      const PanelList = Object.keys(panels).reduce((a,v,i)=>{

         a[i+1]= Object.keys(panels[v]).reduce((ar,val)=>{

             const {letter}=panels[v][val];

             ar.push(letter);
            return ar;
         },[]);


          return a;
      },{});

      let count = 1;

      const sortedSections = Object.keys(sections).reduce((a,v,i)=>{

          const {rounds,title,type,dancers,}=sections[v];


          if(rounds !== undefined){
              Object.keys(rounds).map(roundId=>{
                  const {status,processed, roundType,itemNo}=rounds[roundId];

                  if(status ==='completed'|| status==='checking'){
                      if(processed){

                          if(a[itemNo]){
                              a[parseFloat(itemNo+'.'+count)]={
                                  roundData:rounds[roundId],
                                  title,
                                  type,
                                  dancers,
                                  roundType,
                                  itemNo
                              };
                              count+=1;
                          }else{
                              a[itemNo]={
                                  roundData:rounds[roundId],
                                  title,
                                  type,
                                  dancers,
                                  roundType,
                                  itemNo
                              };
                          }


                      }
                  }
              });
          }
         return a;
      },{});

    const {title,date,venue,organization,organizer,scrutineer,chairperson,logo}=info;
    return (
        <div>
            <ReactToPrint
                trigger={()=><Button color={'info'} type='button' round size='sm'>Print All</Button>}
                content={()=>this.componentRef}/>
            <div ref={ele=>(this.componentRef=ele)} style={{padding:'2%'}}>
                <GridContainer>
                    <GridItem xs={6} md={6} container justify={'center'} alignItems={'center'}>
                        <img src={logo.url} alt={'logo'} height={100}/>
                    </GridItem>
                    <GridItem xs={6} md={6} container justify={'center'} alignItems={'center'}>
                        Title: {title}<br/>
                        Date:{date}<br/>
                        Venue:{venue}<br/>
                        Organization:{organization}<br/>
                        Organizer:{organizer.name}<br/>
                        Chairperson:{chairperson}<br/>
                        Scrutineer:{scrutineer.name}
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <h5>Adjudicator List</h5>
                        {Object.keys(AdjuList).sort().map(letter=>{
                            return (
                                <div key={letter}>
                                    <p>{letter} - {AdjuList[letter]}</p>
                                </div>
                            )
                        })}
                    </GridItem>
                    <GridItem xs={12}>
                        <h5>Panels</h5>
                        {Object.keys(PanelList).map(key=>{
                            return <p key={key}>{key+"-"+PanelList[key].join(', ')}</p>
                        })}
                    </GridItem>
                </GridContainer>
                <DSDivider/>
            {Object.keys(sortedSections).sort((a,b)=>a-b).map(key=>{

                const {type,roundType}=sortedSections[key];

                if(type ==='competitive'|| type ==='multiDance'){
                        if(roundType==='Final'|| roundType==='Uncontested'){
                            return (
                                <ViewFinalAll
                                    key={key}
                                    data={sortedSections[key]}
                                />
                                )
                        }else{
                            // semi final
                             return <ViewResultRoundAll
                                key={key}
                                data={sortedSections[key]}
                            />
                        }

                }else if (type==='social'){
                    return <SocialViewAllMarls
                                key={key}
                                data={sortedSections[key]}
                            />
                }
            })}
            </div>
            <p>Results compiled with Dance Suite, visit dance-suite.com for more information.</p>
            <Footer/>
        </div>
    )
    };

    renderMultiDanceSections=()=>{
        const {sections,adjudicators,info, panels}=this.props;

        const AdjuList = Object.keys(adjudicators).reduce((a,v)=>{
            const {letter, name}=adjudicators[v];
            a[letter]=name;
            return a;
        },{});

        const PanelList = Object.keys(panels).reduce((a,v,i)=>{
            a[i+1]= Object.keys(panels[v]).reduce((ar,val)=>{
                const {letter}=panels[v][val];
                ar.push(letter);
                return ar;
            },[]);
            return a;
        },{});

        const sortedSection =  Object.keys(sections).reduce((a,v)=>{
            const {rounds,type}=sections[v];
            if(type==='multiDance' && rounds !== undefined){
                const roundCheck  = Object.keys(rounds).reduce((ar,val)=>{
                    const {status}=rounds[val];
                    if(status==='complete'|| status==='checking'){
                        ar.push(val)
                    }
                    return ar;
                },[]);
                if(roundCheck.length === Object.keys(rounds).length){
                    a[v]=sections[v]
                }
            }
            return a;
        },{});




        const {title,date,venue,organization,organizer,scrutineer,chairperson,logo}=info;
        return (
            <div>
                <ReactToPrint
                    trigger={()=><Button color={'info'} type='button' round size='sm'>Print All</Button>}
                    content={()=>this.componentRef}/>
                <div>
                    <div ref={el=>(this.componentRef=el)} style={{padding:'2%'}}>
                        <GridContainer>
                            <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                                <img src={logo.url} alt={'logo'} height={100}/>
                            </GridItem>
                            <GridItem xs={12} md={6} container justify={'center'} alignItems={'center'}>
                                Title: {title}<br/>
                                Date:{date}<br/>
                                Venue:{venue}<br/>
                                Organization:{organization}<br/>
                                Organizer:{organizer.name}<br/>
                                Chairperson:{chairperson}<br/>
                                Scrutineer:{scrutineer.name}
                            </GridItem>
                        </GridContainer>
                        <GridContainer>
                            <GridItem xs={12}>
                                <h5>Adjudicator List</h5>
                                {Object.keys(AdjuList).sort().map(letter=>{
                                    return (
                                        <div key={letter}>
                                            <p>{letter} - {AdjuList[letter]}</p>
                                        </div>
                                    )
                                })}
                            </GridItem>
                            <GridItem xs={12}>
                                <h5>Panels</h5>
                                {Object.keys(PanelList).map(key=>{
                                    return <p key={key}>{key+"-"+PanelList[key].join(', ')}</p>
                                })}
                            </GridItem>
                        </GridContainer>
                        <DSDivider/>

                        {Object.keys(sortedSection).map(key=>{

                           return <MultiDanceViewAll
                                    data={sortedSection[key]}
                                    key={key}
                                    />
                        })}
                    </div>
                </div>
                <p>Results compiled with Dance Suite, visit dance-suite.com for more information.</p>
                <Footer/>
            </div>
        )
    };

    render(){
        return (
            <div>
                <Tabs
                    headerColor='info'
                    tabs={[
                        {
                            tabName:"All Marks",
                            tabContent:this.renderSections()
                        },
                        {
                            tabName:"View all MultiSection Marks",
                            tabContent:this.renderMultiDanceSections()
                        }
                    ]}
                />
            </div>
        )
    }
}
const mapStateToProps =state=>{
        const {info,sections,panels,adjudicators}=state.eventData;
    return {
        info,
        sections,
        panels,
        adjudicators
    }

};
export default connect(mapStateToProps)(ViewAllMarks);
