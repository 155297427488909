import {database} from "../../../Firebase";
import {LOADED_EVENTS, NO_EVENTS, SET_MARKS} from "../../types";

export const chairGetEvents = ()=>async (dispatch,getState)=>{

    const uid = await getState().Auth.uid;

    if(uid){
        const ref = database.ref(`events`);
        ref.on('value', snap=>{
            ref.off();
            const snapShot= snap.val();

            const events = Object.keys(snapShot).reduce((a,v)=>{
                const event = snapShot[v];
                if(event.info.chairPersonEnabled){
                    if(event.info.chairId === uid){
                        a[v]={
                            title:event.info.title,
                            status:event.status,
                            scrutineer:event.info.scrutineer.name,
                            date:event.info.date,
                            organizer:event.info.organizer.name,
                            organization:event.info.organization
                        }

                    }
                }
                return a;
            },{});

            if(Object.keys(events).length>0){

                dispatch({type:LOADED_EVENTS, payload:events});
            }else{
                dispatch({type:NO_EVENTS});
            }
        });
    }




};

// approve marks
export const approveResult = (roundId,sectionId, callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
    ref.update({
        checked:true,
        checkTime:Date.now(),
        status:'completed'
    }).then(()=>{
        const roundData = getState().eventData.sections[sectionId].rounds[roundId];
        dispatch({type:SET_MARKS, payload:{sectionId, roundId, roundData}});
        callback();
    })
};
// approve multiMarks
export const approveSectionResult=(sectionId,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}`);
    ref.update({
        checked:true,
        checkTime:Date.now()
    },err=>err?callback({status:2,msg:err.message}):null)
        .then(()=>callback({status:1}))


};

//update publish results
export const UpdatePublishResults=(publish,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}`);
    ref.update({
        chairPublish:publish
    },err=>err?callback({status:2,msg:err.message}):null)
        .then(()=>callback({status:1}))
};