import React from 'react';
import {connect}from 'react-redux';
import {InputAdornment, Tooltip} from "@material-ui/core";
import {List,PlaylistAddCheck,CheckCircle,Search} from "@material-ui/icons";


import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Button from "../../../components/CustomButtons/Button";
import Table from "../../../components/Table/Table";
import Card from "../../../components/Card/Card";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";
import GridContainer from "../../../components/Grid/GridContainer";
import history from "../../../history";
import {setMarks} from "../../../Store/Actions/Scrutineer/SectionActions";
import Modal from "../../../components/Modals/Modal";

class  EventRounds extends React.Component{

    state={
        search:'',
        list:[],
        StartListModal:false,
        modalHeading:''
    };

    renderRounds =()=>{
        const sections = this.props.sections;
        const search = this.state.search.toLocaleLowerCase();
        if(sections !== null && sections !== undefined){
            const tableData =Object.keys(sections).reduce((a,v)=>{

                if(sections[v].rounds !== undefined){
                    const rounds = sections[v].rounds;
                    Object.keys(rounds).map((ar)=>{
                        const info = rounds[ar];
                        if(sections[v]?.title.toLocaleLowerCase().includes(search) || info?.itemNo.toLocaleLowerCase().includes(search)||
                            info?.roundType.toLocaleLowerCase().includes(search) || info?.status.toLocaleLowerCase().includes(search) ){
                            a.push([
                                parseInt(info.itemNo),
                                sections[v].title,
                                info.roundType,
                                info.roundNo,
                                info.panel !== undefined? info.panel.panel:'Unknown-Panel-ERROR',
                                sections[v].type,
                                info.status,
                                (<div>
                                    {info.startList !== undefined ? (
                                        <Tooltip title='View Start List'>
                                            <Button onClick={()=>this.setState({
                                                StartListModal:true,
                                                list:info.startList,
                                                modalHeading:sections[v].title+'-'+info.roundType+'-Start List'
                                            })} color='info' round size='sm' justIcon><List/></Button>
                                        </Tooltip>
                                    ):'No Start list Set'
                                    }
                                    {info.activeList !== undefined && info.panel !== undefined ?
                                        ( <Tooltip title='View Active List'>
                                            <Button onClick={()=>this.setState({
                                                StartListModal:true,
                                                list:info.activeList,
                                                modalHeading:sections[v].title+'-'+info.roundType+'-Active List'
                                            })} color='rose' round size='sm' justIcon><CheckCircle/></Button>
                                        </Tooltip>):''
                                    }{
                                    info.marks !== undefined && info.processed ? (
                                        <Tooltip title='Results'>
                                            <Button onClick={() => this.viewMarks(v, ar, info)} color='success' round
                                                    size='sm' justIcon><PlaylistAddCheck/></Button>
                                        </Tooltip>
                                    ) : ''
                                }
                                </div>)
                            ])
                        }

                    })
                }

                return a;
            },[]).sort((a,b)=>a[0]-b[0]);

            const tableHead = ['Item No','Title','Round Type','Round No','Panel','Type','Status', 'Actions'];
            return (
                <Table
                    tableData={tableData}
                    tableHead={tableHead}
                    tableHeaderColor={'info'}
                />
            )
        }else{
            return (
                <div>
                    <h5>No Rounds Added Yet</h5>
                </div>
            )
        }
    };

    viewMarks=(sectionId, roundId,roundData)=>{
        this.props.setMarks(sectionId,roundId,roundData,()=>history.push('/chairperson/event/results/marks'))
    };

    renderLists =()=>{
        return this.state.list.map((item)=>{
            return (
                <GridItem key={item} xs={4}>
                    <h3>{item}</h3>
                </GridItem>
            )
        })
    };
    handleChange = name => event => {
        this.setState({[name]:event.target.value})
    };
    render(){
       return (
           <div>
               <Modal
                   heading={this.state.modalHeading}
                   isVisible={this.state.StartListModal}
                   onClose={()=>this.setState({StartListModal:false, list:[],modalHeading:''})}
                   saveButtonColor={'info'}
                   saveButtonText={'Close'}
                   content={
                       <GridContainer>
                           {this.renderLists()}
                       </GridContainer>
                   }
               />
              <Card>
                  <CardHeader color={'info'}>
                      <h4>Rounds</h4>
                  </CardHeader>
                  <CardBody>
                      <GridContainer>
                          <GridItem xs={12} md={8}/>
                          <GridItem xs={12} md={4}>
                              <CustomInput
                                  labelText="Search"
                                  id="Search"
                                  formControlProps={{
                                      fullWidth: true
                                  }}
                                  inputProps={{
                                      onChange:(this.handleChange('search')),
                                      endAdornment: (
                                          <InputAdornment position="end">
                                              <Search/>
                                          </InputAdornment>
                                      )
                                  }}
                              />
                          </GridItem>
                      </GridContainer>
                      {this.renderRounds()}
                  </CardBody>
              </Card>
           </div>
       )
    }
}
const mapStateToProps = state=>{
    return {sections:state.eventData.sections}
};


export default connect(mapStateToProps,{setMarks})(EventRounds);
