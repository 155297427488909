import {ADJ_SAVE, LOADED_CHAIR, NO_CHAIR_LOADED, UPDATE_EVENT_INFO} from "../../types";

import {database} from "../../../Firebase";
//update info
export const updateEventInfo =(formValues, callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/info`);
    ref.update({
        venue:formValues.venue,
        date:formValues.date,
        title:formValues.title,
        organization:formValues.organization,
        organizer:{
            name:formValues.organizer,
            contact:formValues.contact
        }
    },err=>{if(err){callback({status:2,msg:err.message})}})
        .then(()=>{
            const ref2 =database.ref(`events/${eventId}/info`);
            ref2.once('value', snap=>{
                dispatch({type:UPDATE_EVENT_INFO, payload:snap.val()});
                callback({status:1})
            });


        })
};
//update status function
export const updateStatus =(status, callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}`);
    ref.update({
        status:status
    }, err=>{if(err){callback({status:2, msg:err.message})}})
        .then(()=>callback({status:1}))
};
// get chairpersons
export const getChairperson = ()=>(dispatch, getState)=>{
    const chairpersons = getState().chairpersons.chairpersons;

    if(chairpersons === null){
        const ref = database.ref(`users/chairperson`);
        ref.on('value',snap=>{
            ref.off();
            const snapShot = snap.val();
            const chairpersons = Object.keys(snapShot).reduce((a,v)=>{

                a.push([
                    v,
                    snapShot[v].firstName+' '+snapShot[v].lastName,
                ]);

                return a;
            },[]);

            if(chairpersons.length>0){
                // make a call to reducer for loaded chairperson
                dispatch({type:LOADED_CHAIR, payload:chairpersons})
            }else{
                // indicate there are none
                dispatch({type:NO_CHAIR_LOADED})
            }
        })
    }


};
// update chairperson
export const updateChairperson = (enabled, chairPerson,chairId, callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`/events/${eventId}/info`);
    ref.update({
        chairId,
        chairPerson:chairPerson,
        chairPersonEnabled:enabled
    }).then(()=>callback())
};
//update marshall
export const updateMarshall =(formValues, callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`/events/${eventId}/info/marshall`);
    ref.update({
        name:formValues.name
    }).then(()=>callback())
};

//add adjudicator
export const addAdjudicator =(formValues, callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const code = Math.floor(1000 +Math.random()*9000);
    const ref = database.ref(`events/${eventId}/adjudicators`);
    ref.push({
        name:formValues.firstName +' '+formValues.lastName,
        code:code,
        letter:formValues.letter,
        loggedIn:false,
        deviceNumber:'unknown'
    }, err=>{if(err){callback({status:2,msg:err.message})}}).then(
        ()=>{
            dispatch({type:ADJ_SAVE});
            callback({status:1});
        }
    )
};
// update adjudicator
export const updateAdjudicator = (formValues, callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/adjudicators/${formValues.id}`);
    ref.update({
        name:formValues.firstName+' '+formValues.lastName,
        letter:formValues.letter
    },err=>{if(err){callback({status:2,msg:err.message})}}).then(()=>callback({status:1}))
};
//delete adjudicator
export const deleteAdjudicator =(id, callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/adjudicators/${id}`);
    ref.remove().then(()=>callback({status:1}));
};
//add panel
export const addPanel = (list,callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/panels`);
    ref.push(list, err=>{if(err){callback({status:2,msg:err.message})}}).then(()=>{
        callback({status:1})
    })
};
//edit panel
export const editPanel = (list, id, callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/panels/${id}`);
    ref.set(list,err=>{if(err){callback({status:2,msg:err.message})}}).then(()=>{
        callback({status:1})
    })
};
//delete panel
export const deletePanel =(id,callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/panels/${id}`);
    ref.remove().then(()=>{
        callback({status:1});
    })
};
