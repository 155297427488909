import React from 'react';
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import RenderInputs from "../../Functions/RenderInputs";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import ReactSelectMaterialUi from 'react-select-material-ui';
import Danger from '../Typography/Danger';
//functions
import Transition from "../../Functions/Transistion";

class EditSectionModal extends React.Component{

    state={
        type:'',
        nextRound:'',
        editMode:false
    };
    setEditMode=()=>{
        if(!this.state.editMode){
            const {nextRound,type}=this.props.sectionData;
            this.setState({editMode:true,nextRound,type})
        }
    };

    renderNextRound=()=>{
        if(this.props.rounds !== undefined && this.props.rounds !== null){
            const rounds = this.props.rounds;
            const roundOptions= Object.keys(rounds).reduce((a,v)=>{
                a.push(rounds[v].roundNo +' - '+rounds[v].roundType);
                return a;
            },[]);
            return(
                <ReactSelectMaterialUi
                    label="Next Round"
                    value={this.state.nextRound}
                    options={roundOptions}
                    style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                    onChange={this.handleNextRound}
                />
            )
        }
    };
    handleNextRound=(value)=>{
        this.setState({nextRound:value});
    };

    onClose=()=>{
        this.setState({nextRound:''}) ;
        this.props.onClose();
    };

    onSubmit=(formValues)=>{
        formValues.type=this.state.type;
        formValues.nextRound = this.state.nextRound;
        this.props.onSave(formValues);

    };

    handleChange = (value) => {
        this.setState({type:value})
    };




    render(){
        const {classes}=this.props;
        const options= ['competitive', 'social', 'multiDance'];
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <div style={{width:400}}>
                                {this.setEditMode()}
                                <Danger>{this.props.errorMessage}</Danger>
                                <GridContainer>
                                    <GridItem xs={12} md={8} lg={8}>
                                        <Field id='title' name='title' label='Section Title' component={RenderInputs}/>
                                    </GridItem>
                                    <GridItem xs={12} md={4}>
                                        <Field id='roundNo' name='roundNo' label='Round No' component={RenderInputs}/>
                                    </GridItem>
                                    <GridItem md={6} xs={12}>
                                        <ReactSelectMaterialUi
                                            label="Section Type"
                                            value={this.state.type}
                                            options={options}
                                            style={{paddingTop:25,paddingBottom:25,width:"100%"}}
                                            onChange={this.handleChange}
                                        />
                                    </GridItem>
                                    <GridItem md={6} xs={12}>
                                        {this.renderNextRound()}
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <div style={{fontSize:10}}>
                                            <p><b>Competitive</b>- Normal competitive competition</p>
                                            <p><b>Social/Pro-am</b>- Social/Pro-Am competition, professional dances with multiple students</p>
                                            <p><b>MultiDance</b>- Each Dance is a round, final summary marks are compiled at then end of the section</p>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </div>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={()=>this.onClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color='success'>
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}
const validate =formValues=>{
    const errors={};
    if(!formValues.title){
        errors.title='Please enter a title!'
    }
    return errors;
};

const form =reduxForm({
    form:'sectionFormEdit',
    validate,
    enableReinitialize:true
})(EditSectionModal);


const mapStateToProps=(state,ownProps)=>{
    let title ='';
    let roundNo =0;
    if(ownProps.sectionData !== undefined && ownProps.sectionData !== null){
        title=ownProps.sectionData.title;
        roundNo=ownProps.sectionData.roundNo
    }

    return {
        rounds:state.section.data.rounds,
        initialValues:{title:title, roundNo:roundNo}
    }
};


export default connect(mapStateToProps)(withStyles(modalStyle)(form))

