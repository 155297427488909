import React, {useEffect, useState, useRef} from 'react';
import {withStyles} from "@material-ui/core";
import Transition from "../../Functions/Transistion";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {get} from 'lodash';
import {useReactToPrint} from "react-to-print";
import nodeBase64 from 'nodejs-base64-converter';
import Interweave from 'interweave';


const MarksheetsModal =({classes, content, visible,onClose})=>{

    const [pdfContent, setPdfContent]=useState(null);

    const printRef = useRef();

    useEffect(()=>{
        if(content){
            setPdfContent(nodeBase64.decode(content))
        }else{
            setPdfContent(null);
        }
    },[content, visible]);


    const handlePrint= useReactToPrint({
        content:()=>printRef.current
    })

    return (
        <Dialog
            classes={{
                root: classes.center,
                paper: classes.modal
            }}
            maxWidth={'lg'}
            open={visible}
            TransitionComponent={Transition}
            keepMounted
            onClose={onClose}
            aria-labelledby="modal-slide-title"
            aria-describedby="modal-slide-description">
            <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}>
                <IconButton
                    className={classes.modalCloseButton}
                    key="close"
                    aria-label="Close"
                    color="inherit"
                    onClick={onClose}>
                    <Close className={classes.modalClose} />
                </IconButton>
                <h4 className={classes.modalTitle}>Judge marks sheet</h4>
            </DialogTitle>
            <DialogContent
                id="modal-slide-description"
                className={classes.modalBody}>
                <div
                    ref={printRef}
                    style={{marginVertical:25, marginHorizontal:25}}
                >
                    <Interweave
                        content={pdfContent}
                    />
                </div>

            </DialogContent>
            <DialogActions
                className={classes.modalFooter +" " +classes.modalFooterCenter}>
                <Button
                    type={'button'}
                    onClick={handlePrint}
                    color={'rose'}
                >
                    Print
                </Button>
                <Button
                    type='button'
                    onClick={onClose}
                >
                    Cancel
                </Button>

            </DialogActions>
        </Dialog>
    )
}

export default withStyles(modalStyle)(MarksheetsModal);
