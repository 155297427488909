import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {connect}from 'react-redux';
import Danger from '../Typography/Danger';
import Info from '../Typography/Info';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Success from "../Typography/Success";
import Transition from "../../Functions/Transistion";

class PanelModal extends React.Component{

    state={
        activeAdju:{},
        error:'',
        editModeEntered:false
    };
    setEditPanel=()=>{
        const {editMode,editModeEntered}= this.state;
        if(!editModeEntered){
            this.setState({activeAdju:this.props.panel,editModeEntered:true})
        }
    };

    onAdjuAdd=(id)=>{
        const activeList = this.state.activeAdju;
        if(!activeList[id]){
            activeList[id]=this.props.adjList[id];
            this.setState({activeAdju:activeList, error:''});
        }else{
            delete activeList[id];
            this.setState({activeAdju:activeList, error:''})
        }
    };

    renderAdju=()=>{
        if(this.props.adjList !== undefined){
            const adjArray = Object.keys(this.props.adjList).reduce((a,v)=>{
                const adj = this.props.adjList[v];
                adj.id=v;
                a.push(adj);
                return a;
            },[]);
            return adjArray.map(adj=>{
                return (
                    <div>
                        <Button color={this.state.activeAdju[adj.id]?'success':'default'}

                                onClick={()=>this.onAdjuAdd(adj.id)}>
                            {adj.letter+' - '+adj.name}

                        </Button>
                    </div>

                )
            })
        }else{
            return (
                <div>You have not added any adjudicators</div>
            )
        }
    };

    onSubmit=()=>{
        const activeList = this.state.activeAdju;
        if(Object.keys(activeList).length>0){
            this.setState({activeAdju: {}});
            this.props.onSave(activeList);
        }else{
            this.setState({error:'No Adjudicators selected!'})
        }
    };

    onClose=()=>{
        this.setState({activeAdju:{}, error:'',editMode:false,editModeEntered:false});
        this.props.onClose();
    };
    render(){
        if(this.props.panel !==''){
            this.setEditPanel()
        }
        const {classes}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <div>
                            <div>
                                <GridContainer>
                                    <GridItem xs={6}>
                                        <Info>Not selected</Info>
                                    </GridItem>
                                    <GridItem xs={6}>
                                        <Success>Selected</Success>
                                    </GridItem>
                                    <GridItem xs={12}>
                                        <p>Click on Adjudicator to select or deselect</p>
                                    </GridItem>
                                </GridContainer>

                            </div>
                            <Danger>{this.props.errorMessage}</Danger>
                            <Danger>{this.state.error}</Danger>
                            <div>
                                {this.renderAdju()}
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSubmit()}
                            color="success">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps =state=>{
    return {
        adjudicators:state.eventData.adjudicators,
    }
};

export default connect(mapStateToProps)(withStyles(modalStyle)(PanelModal))