import React from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Footer from "../../../components/Footer/FooterMain";
import PrintEventHead from "./PrintEventHead";
class PrintRoundMarks extends React.Component{

    render(){
        const {logo,title,date,venue,organization,organizer,scrutineer}=this.props.data.info;
        const {roundData, marks,sectionTitle,panel,sectionData}=this.props;

        return(
            <div>
                <GridContainer style={{padding:25}}>
                    <GridItem xs={12}>
                        <PrintEventHead
                            logo={logo.url}
                            title={title}
                            date={date}
                            venue={venue}
                            organizer={organizer}
                            organization={organization}
                            scrutineer={scrutineer}
                            sectionTitle={roundData.itemNo +' '+sectionTitle+"-"+roundData.roundType}
                            roundNo={roundData.roundNo}
                        />
                        <h5>Panel -  {roundData.panel.panel}</h5>
                        {panel}
                    </GridItem>
                    <GridItem xs={12}>
                        {marks}
                    </GridItem>
                    <GridItem xs={12}>
                        <h4>Dancers Called back: <b>{roundData.results.join(',  ')}</b></h4>
                        <p>Processed @: {new Date(roundData.processedTime).toString()}</p>
                    </GridItem>
                </GridContainer>
                <div  style={{position:'absolute',bottom:0, alignItems:'center'}}>
                <Footer/>
                </div>
            </div>
        )
    }
}


export default PrintRoundMarks;