import React from "react";
import {connect}from 'react-redux';
import history from "../../history";

// components
import MarksCompetitiveComponent from "../Components/MarksCompetitiveComponent";
import MarksSocialComponent from "../Components/MarksSocialComponent";
import MarksMultiFinalComponent from "../Components/MarksMultiFinalComponent";

class Marks extends React.Component{

    render(){
        const {eventDetails, marks,sectionId,sectionType,roundId,title,roundData,adjudicators,dancers,sectionRounds,sectionData}=this.props;
        if(this.props.sectionType ==='competitive'){
            return (
                <MarksCompetitiveComponent
                eventDetails={eventDetails}
                marks={marks}
                sectionId={sectionId}
                sectionType={sectionType}
                roundId={roundId}
                title={title}
                roundData={roundData}
                adjudicators={adjudicators}
                dancers={dancers}
                sectionRounds={sectionRounds}
                sectiondata={sectionData}
            />)
        }
        else if(this.props.sectionType ==='social') {
            return (
               <MarksSocialComponent
                eventDetails={eventDetails}
                marks={marks}
                sectionId={sectionId}
                sectionType={sectionType}
                roundId={roundId}
                title={title}
                roundData={roundData}
                adjudicators={adjudicators}
                dancers={dancers}
                sectionRounds={sectionRounds}

               />
            )
        }
        else{
            if(this.props.multiComplete){
                return (
                    <MarksMultiFinalComponent
                        id={sectionId}
                        sectionData={sectionData}
                        eventDetails={eventDetails}
                    />
                )
            }else{
                return (
                    <MarksCompetitiveComponent
                        eventDetails={eventDetails}
                        marks={marks}
                        sectionId={sectionId}
                        sectionType={sectionType}
                        roundId={roundId}
                        title={title}
                        roundData={roundData}
                        adjudicators={adjudicators}
                        dancers={dancers}
                        sectionRounds={sectionRounds}
                    />
                )
            }

        }
    }
}

const mapStateToProps=state=>{
    if(state.marks.sectionId === null ){
        history.push('/scrutineer');
        return {eventDetails: null}
    }else{
        return {
            eventDetails:state.eventData,
            marks:state.marks.roundData !== null?state.marks.roundData.marks:null,
            sectionId:state.marks.sectionId,
            sectionType: state.eventData.sections[state.marks.sectionId].type,
            roundId:state.marks.roundId,
            title:state.eventData.sections[state.marks.sectionId].title,
            roundData:state.marks.roundData,
            adjudicators:state.eventData.adjudicators,
            dancers: state.eventData.sections[state.marks.sectionId].dancers,
            sectionRounds:state.eventData.sections[state.marks.sectionId].rounds,
            multiComplete:state.marks.multiComplete,
            sectionData:state.marks.sectionData
        }
    }
};

export default connect(mapStateToProps)(Marks);