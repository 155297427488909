import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
import Danger from '../Typography/Danger';
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import RenderInputs from "../../Functions/RenderInputs";
//functions
import Transition from "../../Functions/Transistion";

class DancersModal extends React.Component{

    state={
        error:''
    };

    onSubmit=(formValues)=>{
        let check =false;
        if(this.props.dancers !== null && this.props.dancers!== undefined){
            const numberArray = Object.keys(this.props.dancers).reduce((a,v)=>{
                a.push(this.props.dancers[v].number);
                return a
            },[]);



            if(!numberArray.includes(formValues.number)){
                this.setState({error:''});
                check=true;
            }else if(this.props.dancersData !== null){
                if(formValues.number === this.props.dancersData.number){
                    check=true
                }else{
                    this.setState({error:"Cannot change dancers number, rather delete and create new!"})
                }
            }
            else{
                this.setState({error:"Dancers cannot have the same number!"})
            }
        }else{

            this.setState({error:''});
            check=true;
        }

        if(check){
            if(this.props.sectionData.type ==='social'){
                formValues.heat !== ''?
                    formValues.teacher !==''?
                        this.props.onSave(formValues):
                        this.setState({error:'Teacher cant  be left blank'}):
                    this.setState({error:'Heat cant be left blank'})

            }else{
                this.setState({error:''});
                this.props.onSave(formValues);
            }
        }


    };

    onClose=()=>{
        this.setState({error:''});
        this.props.onClose();
    };

    render(){
        const {classes}=this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <div>
                                <Danger>{this.state.error}</Danger>
                                <Danger>{this.props.errorMessage}</Danger>
                                <GridContainer>
                                    <GridItem xs={12} sm={2}>
                                        <Field id='number' name='number' label='Number' component={RenderInputs}/>
                                    </GridItem>
                                    <GridItem xs={12} sm={6}>
                                        <Field id='names' name='names' placeholder='names' label='Name(s)' component={RenderInputs} />
                                    </GridItem>
                                    <GridItem xs={12} sm={4}>
                                        <Field id='studio' name='studio' placeholder='studio' label='Studio' component={RenderInputs}/>
                                    </GridItem>
                                    {this.props.sectionData.type ==='social'?
                                        <GridItem xs={12}>
                                            <GridContainer>
                                                <GridItem xs={12} sm={6}>
                                                    <Field id='heat' name='heat' placeholder='heat' label='Heat' component={RenderInputs}/>
                                                </GridItem>
                                                <GridItem xs={12} sm={6}>
                                                    <Field id='teacher' name='teacher' placeholder='Teacher' label='Teacher' component={RenderInputs}/>
                                                </GridItem>
                                            </GridContainer>
                                        </GridItem>:''
                                    }
                                </GridContainer>
                            </div>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={()=>this.onClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}
const validate = (formValues, props)=>{
    const errors={};
    const {number, names} =formValues;
    if(!number){
        errors.number="Please enter a number"
    }

    if(!names){
        errors.names="Please enter names"
    }

    return errors;
};

const form =reduxForm({
    form:'dancers',
    validate,
    enableReinitialize:true
})(DancersModal);

const mapStateToProps=(state,ownProps)=>{
    let dancers = null;
    if(state.section.data !== null){
        if(state.section.data.dancers !== null && state.section.data.dancers!== undefined){
            dancers = state.section.data.dancers
        }
    }

    return{
        dancers:dancers,
        initialValues:ownProps.dancersData
    }
};

export default connect(mapStateToProps)(withStyles(modalStyle)(form))