import React from 'react';
import {withStyles} from "@material-ui/core";
import {deepPurple} from "@material-ui/core/colors";
import {Switch} from "@material-ui/core";
import {FormControlLabel} from "@material-ui/core";

const PurpleSwitch = withStyles({
    switchBase:{
        color: deepPurple[300],
        '&$checked': {
            color: deepPurple[500],
        },
        '&$checked + $track': {
            backgroundColor: deepPurple[500],
        },
    },
    checked:{},
    track:{backgroundColor: deepPurple[500]}
})(Switch);

const CustomSwitch =({checked,value, label, onChange})=> {
        return(
            <FormControlLabel
                control={
                    <PurpleSwitch
                        checked={checked}
                        onChange={onChange}
                        value={value}
                    />
                }
                label={label}


            />
        )


}
export default CustomSwitch;

