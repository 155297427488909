
import {Dashboard, Description,LibraryBooks,Notes,BubbleChart,ListAlt} from "@material-ui/icons";

// components
import EventDashboard from "./Views/EventDashboard";
import EventDetails from "./Views/EventDetails";
import EventSections from "./Views/EventSections";
import EventRounds from "./Views/EventRounds";
import EventResults from './Views/EventResults';
import EventNotes from "./Views/EventNotes";

const EventRoutes=[
    {
      path:'/dashboard',
        name:'Dashboard',
        Icon:Dashboard,
        layout:'/chairperson/event',
        component:EventDashboard
    },
    {
        path:'/details',
        name:'Details',
        Icon:Description,
        layout:'/chairperson/event',
        component:EventDetails
    },
    {
        path:'/sections',
        name:'Sections',
        Icon:LibraryBooks,
        layout: '/chairperson/event',
        component:EventSections
    },
    {
        path:'/rounds',
        name:'Rounds',
        Icon:ListAlt,
        layout: '/chairperson/event',
        component: EventRounds
    },
    {
        path:'/results',
        name:'Results',
        Icon:BubbleChart,
        layout: '/chairperson/event',
        component:EventResults
    },
    {
        path:'/notes',
        name:'Notes',
        Icon:Notes,
        layout: '/chairperson/event',
        component:EventNotes
    }
]
export default EventRoutes;