import React from 'react';
import {connect}from 'react-redux';
import {withStyles} from "@material-ui/core";
import Transition from "../../Functions/Transistion";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Danger from "../Typography/Danger";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {CSVReader} from "react-papaparse";
import Success from "../Typography/Success";

class ImportModal extends React.Component{
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
    }

    state={
        error:'',
        data:[],
        sortedData:null,
        sortedSections:null
    };

    renderData =()=>{
        if(this.state.sortedData){
            const displayData = Object.keys(this.state.sortedData).reduce((a,v)=>{
                a.push([v," - "+this.state.sortedData[v].length +' dancers']);
                return a;
            },[]);
            return displayData.map(item=>{
                return (
                    <GridItem key={item[0]} xs={12}>
                        {item}
                    </GridItem>
                )
            })

        }
    };

    sortData =(data)=>{
        //console.log(data);
        if(data.length >0){
            const sortedData = data.reduce((a,v,i)=>{
                if(!a[data[i].data[3]] && data[i].data[3] !== ''){
                    if(data[i].data[4]==='competitive'|| data[i].data[4]==='multiDance'){
                        a[data[i].data[3]]=[{number:data[i].data[0], names:data[i].data[1], studio:data[i].data[2],addedBy:'Import',timeStamp:Date.now()}]
                    }else if(data[i].data[4]==='social'){
                        a[data[i].data[3]]=[{number:data[i].data[0], names:data[i].data[1], studio:data[i].data[2],teacher:data[i].data[5],heat:data[i].data[6],addedBy:'Import',timeStamp:Date.now()}]
                    }
                }else{
                    if(a[data[i].data[3]] !== undefined && data[i].data[0] !== ''){
                        if(data[i].data[4]==='competitive'|| data[i].data[4]==='multiDance'){
                            a[data[i].data[3]].push({number:data[i].data[0], names:data[i].data[1], studio:data[i].data[2],addedBy:'Import',timeStamp:Date.now()})
                        }else if(data[i].data[4]==='social'){
                            a[data[i].data[3]].push({number:data[i].data[0], names:data[i].data[1], studio:data[i].data[2],teacher:data[i].data[5],heat:data[i].data[6],addedBy:'Import',timeStamp:Date.now()})
                        }

                    }
                }
                return a;
            },{});

            const sortedSections = data.reduce((a,v,i)=>{

                if(!a[data[i].data[3]]&& data[i].data[3]!==''){
                    if(data[i].data[4]==='competitive'|| data[i].data[4]==='multiDance'){
                        a[data[i].data[3]]={type:'competitive',number:i};

                    }else if(data[i].data[4]==='social'){
                        a[data[i].data[3]]={type:'social', number:i};
                    }
                }

                return a;
            },{});

            console.log('srotedSections',sortedSections);

            this.setState({sortedData, error:'', sortedSections})
        }
    };

    handleReadCSV = (data) => {
        this.sortData(data);
    };

    handleOnError = (err, file, inputElem, reason) => {
        this.setState({error:err})
    };

    handleImportOffer = () => {
        this.fileInput.current.click();
    };

    onClose=()=>{
        this.setState({sortedData:null});
        this.props.onClose();
    };

    onSave=()=>{
        const {sortedData,sortedSections}=this.state;
        if(sortedData !== null && sortedSections!== null){
            this.props.onSave(sortedData,sortedSections);
        }else{
            this.setState({error:'No data to import!'})
        }

    };

    renderInput =()=>{
        if(!this.state.sortedData){
            return (
                <div>
                    <CSVReader
                        onFileLoaded={this.handleReadCSV}
                        inputRef={this.fileInput}
                        //style={{display: 'none'}}
                        onDrop={this.handleReadCSV}
                        onError={this.handleOnError}
                        noClick
                        addRemoveButton
                        //onRemoveFile={this.handleOnRemoveFile}
                    >
                        <span>Drop CSV file here to upload.</span>
                    </CSVReader>
                </div>
            )
        }else{
            return (
                <GridContainer>
                    <GridItem>
                        <Success>{Object.keys(this.state.sortedData).length} Sections Found</Success>
                    </GridItem>
                    {this.renderData()}
                </GridContainer>
            )
        }
    };

    render(){
        const {classes}=this.props;
        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    maxWidth={'md'}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <div>
                            <h4 className={classes.modalTitle}>Import Sections</h4>
                            <Danger>{this.state.error}</Danger>
                        </div>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        {this.renderInput()}
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='button'
                            color={'success'}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

export default connect(null)(withStyles(modalStyle)(ImportModal));
