import React from 'react';
import {connect}from 'react-redux';
import {AddAlert, AddBox, Close, Edit} from "@material-ui/icons";
import {get}from 'lodash';
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import Modal from "../../../components/Modals/Modal";
import NotesModal from "../../../components/Modals/NotesModal";
import Loading from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import Button from "../../../components/CustomButtons/Button";
import {AddNote,UpdateNote,DeleteNote} from "../../../Store/Actions/CrossActions";
import Table from "../../../components/Table/Table";

class EventsNotes extends React.Component{

    state={
        modal:false,
        loading:false,
        notif:false,
        notifMsg:'',
        errorMessage:'',
        noteId:null,
        noteData:'',
        deleteModal:false,
        deleteId:'',
        noteHeading:''
    };

    saveNote=(note)=>{
        this.setState({loading:true});
        if(this.state.noteId === null){
            // create new note
            this.props.AddNote(note,(res)=>{
                res.status === 1 ?
               this.setState({
                   loading:false,
                   notif:true,
                   notifMsg:'Note Added Successfully',
                   modal:false,
                   errorMessage:'',
                   noteHeading:''
               }) :
                    this.setState({
                        loading:false,
                        errorMessage:res.msg
                    });
            })
        }else{
            // update note
            this.props.UpdateNote(note,this.state.noteId,res=>{
                res.status !==1 ?
                    this.setState({loading:false,errorMessage:res.msg}):
                    this.setState({
                        modal:false,
                        loading:false,
                        errorMessage:'',
                        notif:true,
                        notifMsg:'Note Updated Successfully!',
                        noteId:null,
                        noteData:''
                    })
            })
        }

    };
    onConfirmDelete=()=>{
        this.setState({loading:true});
        this.props.DeleteNote(this.state.deleteId,res=>{
            this.setState({
                deleteModal:false,
                loading:false,
                errorMessage:'',
                notif:true,
                notifMsg:'Note Deleted Successfully!',
                deleteId:''
            })
        })
    };

    renderNotes=()=>{
        const chairNotes = get(this.props.notes,'chairperson',null);
      if(chairNotes){
          const notes = chairNotes
          const tableData = Object.keys(notes).reduce((a,v,i)=>{
              a.push([
                  i+1,
                  notes[v].note.substring(0,15)+'...',
                  new Date(notes[v].dateAdded).toDateString()+' '+new Date(notes[v].dateAdded).toLocaleTimeString()  ,
                  new Date(notes[v].dateEdited).toDateString()+' '+ new Date(notes[v].dateEdited).toLocaleTimeString(),
                  (<div>
                      <Button onClick={()=>this.setState({deleteModal:true,deleteId:v})} color='danger' round justIcon size='sm'><Close/></Button>
                      <Button onClick={()=>this.setState({modal:true,noteData:notes[v].note, noteId:v, noteHeading:'Edit Note'})} color='warning' round justIcon size='sm'><Edit/></Button>
                  </div>)
              ]) ;


            return a;
          },[]);
          const tableHead = ['Number', 'Note', 'Date Added','Date Edited','Actions'];
          return (
              <Table
                tableHeaderColor={'rose'}
                tableData={tableData}
                tableHead={tableHead}
              />
          )
      }else{
          return <div>No Notes added yet.</div>
      }
    };

    render(){
        return (
            <div>
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <NotesModal
                    heading={this.state.noteHeading}
                    isVisible={this.state.modal}
                    onClose={()=>this.setState({modal:false, noteData:'', noteId:null})}
                    onSave={this.saveNote}
                    noteData={this.state.noteData}
                />
                <Modal
                    errorMessag={'Are you sure you want to delete this note?'}
                    heading={'Delete Note'}
                    isVisible={this.state.deleteModal}
                    onClose={()=>this.setState({deleteModal:false, deleteId:''})}
                    onSave={this.onConfirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this note?</p>}
                />
                <Card>
                    <CardHeader color='rose'>
                        <h4>Notes</h4>
                        <Button color='white' size='sm' round onClick={()=>this.setState({modal:true, noteHeading:'New Note'})}   ><AddBox/>Add new Note</Button>
                    </CardHeader>
                    <CardBody>
                        {this.renderNotes()}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
const mapStateToProps =state=>{

    if(state.eventData.notes !== undefined){
        return {notes:state.eventData.notes}
    }else{
        return {notes:null}
    }
};

export default connect(mapStateToProps, {AddNote,UpdateNote,DeleteNote})(EventsNotes);
