import {LOAD_SCRUTINEERS, LOADED_CHAIR, NO_CHAIR_LOADED} from "../types";


const INITIAL_STATE={
    chairpersons: null,
    scrutineers:[]
};

export default (state=INITIAL_STATE, action)=>{
    switch (action.type) {
        case LOADED_CHAIR:
            return {...state,chairpersons: action.payload};
        case NO_CHAIR_LOADED:
            return {...state,chairpersons: null};
        case LOAD_SCRUTINEERS:
            return {...state,scrutineers:action.payload};

        default:
            return {...state}
    }
}