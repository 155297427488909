import React from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {MultiDanceSection} from "../../Alogrithms/finalAlgo";
import Footer from "../../../components/Footer/FooterMain";
import PrintEventHead from "./PrintEventHead";

class PrintMultiDanceFinalMarks extends React.Component{


    renderPanels=()=>{
      const {sectionData,eventDetails}=this.props;
      const {rounds}=sectionData;

      const adjudicators = Object.keys(eventDetails.adjudicators).reduce((a,v)=>{

          const {letter,name}=eventDetails.adjudicators[v];
          a[letter]=name;

          return a;
      },{});

      const panels = Object.keys(rounds).reduce((a,v)=>{

          const {number,letters,panel} = rounds[v].panel;

          const adjNames = letters.split(',').reduce((ar,va)=>{

              ar[va]=adjudicators[va];

              return ar;
          },{});
          a[number]={panel, adjNames};

          return a;
      },{});


      return (
          <GridContainer>
              {Object.keys(panels).map(pan=>{
                  const size=(12/Object.keys(panels).length);
                  const {panel,adjNames}=panels[pan];
                    return(
                        <GridItem xs={size} key={pan}>
                            <h5>{panel}</h5>
                            {Object.keys(adjNames).map(adj=>(
                                <p key={adj}>{adj+'-'+adjNames[adj]}</p>
                            ))}
                        </GridItem>
                    )
              })}
          </GridContainer>
      )




    };


    renderMarks=()=>{
      const {sectionData}=this.props;
      return MultiDanceSection(sectionData);
    };


    render(){
        const {sectionData, info,eventDetails}=this.props;
        const {title,date,venue,organization,organizer,scrutineer,logo}=eventDetails.info;

        return (
            <div style={{padding:25}}>
                <GridContainer>
                    <GridItem xs={12}/>
                    <GridItem xs={12}>
                        <PrintEventHead
                            logo={logo.url}
                            title={title}
                            date={date}
                            venue={venue}
                            organizer={organizer}
                            organization={organization}
                            scrutineer={scrutineer}
                            sectionTitle={sectionData.title}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        {this.renderPanels()}
                    </GridItem>
                    <GridItem xs={12}>
                        {this.renderMarks()}
                    </GridItem>
                </GridContainer>
                <div  style={{position:'absolute',bottom:0}}>
                    <Footer/>
                </div>
            </div>
        )
    }


}
export default PrintMultiDanceFinalMarks;