import {database, auth, storage} from "../../../Firebase";
import colors from "../../../Variables";
import {
    LOADED_EVENTS,
    NO_EVENTS,
    SET_EVENT_DATA,
    SET_SINGLE_EVENT,
    CLEAR_EVENT,
    LOAD_SCRUTINEERS,
    CLEAR_ALL_EVENTS
} from "../../types";
//create an Screens
export const createEvent =(formValues, callback)=>(dispatch, getState)=>{

    const {uid, firstName,lastName, email}=getState().Auth;
    database.ref(`/events`).push({
        info:{
            venue:formValues.venue,
            date:formValues.date,
            title:formValues.title,
            organization:formValues.organization,
            organizer:{
                name:formValues.organizer,
                contact:formValues.contact
            },
            chairPerson: 'unknown',
            chairPersonEnabled:true,
            eventPrimaryColor:colors.dSBlue,
            eventSecondaryColor:colors.dSGold,
            logo:{
                logoId:'-Lp8csnM6A64_BCkBTuO',
                name:"Main_Logo.png",
                url:"https://firebasestorage.googleapis.com/v0/b/dance-suite.appspot.com/o/organizersLogo%2FMain_Logo.png?alt=media&token=f8a57d73-5fc3-4cdc-b218-220899e0bb87"
            },
            marshall:{
                name:'unknown',
                code:Math.floor(1000 +Math.random()*9000)
            },
            scrutineer:{
                uid:uid,
                name:firstName+' '+lastName,
                contact:email
            },
            adjudicators:{

            },
            sections:{

            },
            panels:{},
            notes:{
                chairperson:{},
                scrutineer:{}
            }
        },
        status:'not-started'
    }, err=>{
        if(err){
            callback({status:2, msg:err.message})
        }
    }).then(snap=>{
        callback({status:1,key:snap.key})
    })

};
//getEvents
export const getEvents =()=> async (dispatch,getState)=>{

    const uid = await getState().Auth.uid;
    if(uid){
        const ref = database.ref('/events');
        ref.on('value',snapshot=>{
            ref.off();
            const events =[];
            snapshot.forEach(event=>{
                if(event.val().info.scrutineer.uid === uid){
                    const id = event.ref.key;
                    const info=event.val();
                    const eventInfo={
                        title:info.info.title,
                        date:info.info.date,
                        id:id,
                        status:info.status,
                        organization: info.info.organization
                    };
                    events.push(eventInfo)
                }
            });


            if(events.length>0){

                dispatch({type:LOADED_EVENTS, payload:events});
            }else{
                dispatch({type:NO_EVENTS});
            }
        })
    }

};
// get a list of scrutineers
export const getScrtuineers=(callback)=>(dispatch,getState)=>{
    const uid = getState().Auth.uid;
    const ref = database.ref(`users/scrutineer`);
    ref.once('value').then(snap=>{
        const scrutineers = snap.val();

        const data = Object.keys(scrutineers).reduce((a,v)=>{

            const {firstName,lastName,status,email}=scrutineers[v];

            if(status==='enabled' && uid!==v){
                a.push({
                    name:firstName+' '+lastName,
                    email,
                    uid:v
                })
            }
            return a;
        },[]);
        dispatch({type:LOAD_SCRUTINEERS,payload:data});
        callback({status:1,data,msg:'loaded successfully'});


    }).catch(err=>callback({status:2,msg:err.message,data:[]}));
};

export const TransferScrutineer=(newScrutineer, eventId,callback)=>(dispatch,getState)=>{
    const {uid,email,name}=newScrutineer;

    const ref = database.ref(`events/${eventId}/info/scrutineer`);

    ref.update({
        uid,
        contact:email,
        name
    }).then(()=>{
        dispatch({type:CLEAR_ALL_EVENTS});
        callback({status:1})
    })
        .catch(err=>callback({status:2,msg:err.message}))

};

//set eventId
export const setEvent =(id,title, callback)=>dispatch=>{
    dispatch({type:SET_SINGLE_EVENT, payload:{id,title}});
    callback();
};

//get single Event data
export const getEventData=(id)=>async (dispatch,getState)=>{
    const ref = database.ref(`/events/${id}`);
    ref.on('value',snapshot=>{
        dispatch({type:SET_EVENT_DATA, payload:snapshot.val()})
    })
};

//clear Screens from memory
export const clearEvent =(callback)=>(dispatch)=>{
    dispatch({type:CLEAR_EVENT});
    callback();
};

//delete Screens
export const deleteEvent =(id,callback)=>(dispatch)=>{
    const ref = database.ref(`events/${id}`);
    ref.remove().then(()=>callback());
};
//archive Screens
export const archiveEvent = (id,status,callback)=>(dispatch)=>{
    const ref = database.ref(`events/${id}`);
    ref.update({
        status:status
    }).then(()=>{
        callback();
    })
};

//update colors
export const updateColors=(color,type,eventId,callback)=>(dispatch)=>{

    const ref = database.ref(`events/${eventId}/info`);
    let colorType='';
    type==='primary'?colorType="eventPrimaryColor":colorType="eventSecondaryColor";

    ref.update({
        [colorType]:color
    },err=>err?callback({status:2,msg:err.message}):null)
        .then(()=>callback({status:1}))

};

export const getLogos =(callback)=>(dispatch)=>{

    const ref = database.ref(`logos`);
    ref.once('value').then(snap=>{
        callback({status:1,logos:snap.val()});
    })


};

//update logo for Screens
export const updateLogo=(image,eventId,callback)=>(dispatch)=>{

    const ref = database.ref(`events/${eventId}/info/logo`);
    const {logoId,name,url}=image;
    ref.update({
        logoId,
        name,
        url
    },err=>err?callback({status:2,msg:err.message}):null)
        .then(()=>callback({status:1}))
};

export const uploadNewLogo=(files,eventId,callback)=>(dispatch)=>{

    storage.child(`organizersLogo/${files[0].name}`).put(files[0])
        .then(snap=>{
            snap.ref.getDownloadURL()
                .then(dwnUrl=>{

                    const url = dwnUrl;
                    const name = files[0].name;

                    const logoRef=database.ref(`logos`);
                    logoRef.push({
                        name,
                        url
                    },err=>err?callback({status:2,msg:err.message}):null);

                    const imageId=logoRef.key;

                    const eventLogoRef=database.ref(`events/${eventId}/info/logo`);
                    eventLogoRef.update({
                        logoId:imageId,
                        name,
                        url
                    }).then(()=>callback({status:1}))


                })



        }).catch(err=>err?callback({status:2,msg:err.message}):null)


};

// change publish
export const updatePublish=(publish,eventId,callback)=>(dispatch)=>{

    const ref = database.ref(`events/${eventId}`);
    ref.update({
        publish:publish
    },err=>err?callback({status:2,err:err.message}):null)
        .then(()=>callback({status:1}))

};