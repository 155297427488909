import {CLEAR_EVENT, SET_SINGLE_EVENT} from "../types";

const INITIAL_STATE={
    eventId:null,
    eventTitle:null
};
export default (state=INITIAL_STATE, action)=>{
    switch(action.type){
        case SET_SINGLE_EVENT:
            return {eventId: action.payload.id, eventTitle:action.payload.title};
        case CLEAR_EVENT:
            return {eventId: null, eventTitle: null};
        default:
            return {...state};
    }
}