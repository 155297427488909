import React from 'react';
import {connect}from 'react-redux'
import ChairCompetitiveMarks from "./components/competitiveMarks";
import ChairSocialMarks from "./components/socialMarks";
import history from "../../../history";
import MultiDanceMarks from "./components/MultiDanceMarks";


class EventMarks extends React.Component{



    render(){
        const {roundData,sectionId,roundId,sectionData, eventDetails, multiComplete}=this.props;
        if(sectionData.type ==='competitive'){
            return <ChairCompetitiveMarks
                eventDetails={eventDetails}
                roundData={roundData}
                sectionId={sectionId}
                roundId={roundId}
                sectionData={sectionData}
            />
        }else if(sectionData.type ==='social'){
            return <ChairSocialMarks
                    eventDetails={eventDetails}
                    roundData={roundData}
                    sectionId={sectionId}
                    roundId={roundId}
                    sectionData={sectionData}
            />

        }else{
            if(multiComplete){
                return <MultiDanceMarks
                        sectionId={sectionId}
                        eventDetails={eventDetails}
                        sectionData={sectionData}
                />
            }else{
                return <ChairCompetitiveMarks
                    eventDetails={eventDetails}
                    roundData={roundData}
                    sectionId={sectionId}
                    roundId={roundId}
                    sectionData={sectionData}
                />
            }
        }
    }
}
const mapStateToProps =state=> {


    if (state.eventData.info === null) {
        history.push('/officials/chairperson')
    } else {
        return {
            eventDetails: state.eventData,
            roundData: state.marks.roundData,
            sectionId: state.marks.sectionId,
            roundId: state.marks.roundId,
            sectionData: state.eventData.sections[state.marks.sectionId],
            multiComplete:state.marks.multiComplete
        }
    }
};


export default connect(mapStateToProps)(EventMarks);