import React from "react";
import GridContainer from "../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../components/Grid/GridItem";
import {MultiDanceSection} from "../../../../../Marks/Alogrithms/finalAlgo";
import {DancersFunction} from "../functions";
import {DSDivider} from "../../../../../components/Divider";

export const MultiDanceViewAll=(props)=>{

    const {title,dancers, finalResult}=props.data;

    const Dancers = DancersFunction(dancers);




    const renderFinalResult = (result)=>{

      if(result !== undefined){
          return (
              <div>
                  {Object.values(result).sort((a,b)=>a-b).map(place=>{

                      return Object.keys(result).map(dancer=>{

                          if(place === result[dancer]){
                              if(Dancers[dancer]!== undefined){
                                  return  <p>{place+"  "}<b>{dancer}</b>   {Dancers[dancer].names+"   "+Dancers[dancer].studio}</p>
                              }else{
                                  console.log(dancer);
                                  return <p>{place+'   '}<b>{dancer}</b></p>
                              }

                          }
                      })

                  })}
              </div>
          )
      }
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12}>
                    <h5>{title}</h5>
                </GridItem>
                <GridItem xs={12}>
                    {MultiDanceSection(props.data)}
                </GridItem>
                <GridItem xs={12}>
                    <h5>Final Result</h5>
                    {renderFinalResult(finalResult)}
                </GridItem>
            </GridContainer>
            <div style={{marginTop:'2%'}}>
            <DSDivider/>
            </div>
        </div>

    )
};