import React from "react";
import {DancersFunction} from "../functions";
import GridContainer from "../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../components/Grid/GridItem";
import {marksTable} from "../../../../../Marks/Alogrithms/roundAlgo";
import {DSDivider} from "../../../../../components/Divider";

export const ViewResultRoundAll =(props)=>{
    const {title,roundType,roundData,itemNo,dancers}=props.data;
    const {dances,results, marks}=roundData;

    const Dancers = DancersFunction(dancers);


    return (
        <div>
            <h5>{itemNo+' '+title+'-'+roundType}</h5>
            <GridContainer>
                <GridItem xs={12}>
                    {marksTable(roundData,marks)}
                </GridItem>
                <GridItem xs={12}>
                    <h5>Dancers called back:</h5>
                    {results.map(key=>{

                        return (
                            <p key={key}>{key+'  '+Dancers[key].names}</p>
                        )
                    })}
                </GridItem>
            </GridContainer>
            <DSDivider/>
        </div>
    )


};