import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Danger from "../components/Typography/Danger";
import Success from "../components/Typography/Success";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../components/CustomButtons/Button";
import {TextField, withStyles} from "@material-ui/core";
import modalStyle from "../assets/jss/material-kit-react/modalStyle";
import {ChevronLeft, ChevronRight}from '@material-ui/icons';
import CustomInput from "../components/CustomInput/CustomInput";
import Transition from "../Functions/Transistion";

class ManualMarks2 extends React.Component{

    state={
        number:'',
        error:'',
        success:'',
        // section specific data
        title:'',
        roundType:'',
        callBackLimit:'',
        loaded:false,
        // pre loaded data from section
        dances:[],
        dancers:[],
        adjudicators:[],
        // data that needs to be used to collect marks
        activeDanceNo:0,
        activeAdjNo:0,

        // marks stored here
        marks:{},
        selectedDancers:[],
        specialMarks:{}
    };

    componentDidUpdate=(prevProps,prevState)=>{

        if(!prevState.loaded && this.props.sectionData!== null){
            const {sectionData,roundData}=this.props;
            const {dances,activeList,panel, roundType, callbackLimit}=roundData;
            const {title}=sectionData;
            // load the data

            this.setState({
                title,
                roundType,
                dances,
                callbackLimit:isNaN(callbackLimit)?'':callbackLimit,
                dancers:activeList,
                adjudicators:panel.letters.split(',').sort(),
                loaded:true
            })

        }
    };

    onClose=()=>{
        this.setState({
            title:'',
            roundType:'',
            error:'',
            success:'',
            loaded:false,
            // pre loaded data from section
            dances:[],
            dancers:[],
            adjudicators:[],
            // data that needs to be used to collect marks
            activeDanceNo:0,
            activeAdjNo:0,

            T:{},
            C:{},
            I:{},
            S:{},
            total:{},
            place:{},
            // marks stored here
            marks:{},
            selectedDances:[],
            specialMarks:{}
        });
        this.props.onClose();
    };


    // helper function to check marks
    // - checks the dances stored in the marks state
    marksDances=(marks)=>{
        return   Object.keys(marks).reduce((a,v)=>{
            Object.keys(marks[v]).map(d=>{
                a+=1;
            });
            return a;
        },0)
    };

    //-calculates the dance sum
    danceSum=(adj,dances)=>{
        return adj.length * dances.length;
    };



    // for rounds and semi finals, handle the dancer being selected
    handleSelectedDancer=(dancer)=>{
        const {selectedDancers}=this.state;
        if(selectedDancers.includes(dancer)){
            // dancer already there, time to remove
            const newDancers = selectedDancers.filter(d=>d!==dancer?d:null);
            this.setState({selectedDancers: newDancers,error:'',number:''})
        }else{
            // dancer not there we can add
            selectedDancers.push(dancer);
            this.setState({selectedDancers,error:'',number:''})
        }
    };
    // handle the submiting of the round/semi from the form
    onSubmitNumber=()=>{
        const {dancers,number}=this.state;
        if(dancers.includes(number)){
            this.handleSelectedDancer(number)
        }else{
            this.setState({error:'ERROR: Number does not exist!'})
        }
    };

    // set the next position
    setNextPosition=(danceNo,adjNo, marksCheck)=>{
        const {roundType ,marks, dances, adjudicators,dancers}=this.state;
        // no marks just set the next round

        if(marksCheck=== undefined){
            if(roundType==='Semi-Final'||roundType==='Round'){
                this.setState({
                    activeDanceNo:danceNo,
                    activeAdjNo:adjNo,
                    error:'',
                    success:'',
                    selectedDancers:[],
                })
            }else{
                this.setState({
                    activeDanceNo:danceNo,
                    activeAdjNo:adjNo,
                    error:'',
                    success:'',
                    marks
                });

                if(!dances.includes('exhibition') && !dances.includes('showcase')&& !dances.includes('showdance')&& !dances.includes('solo spot')){

                    document.getElementById(dancers[0]).focus();
                }


            }

        }// marks are there and we need to find them and then set
        else{
            const dance=dances[danceNo];
            const adju = adjudicators[adjNo];
            if(roundType==='Semi-Final'||roundType==='Round'){
                this.setState({
                    activeDanceNo:danceNo,
                    activeAdjNo:adjNo,
                    error:'',
                    success:'',
                    selectedDancers:marks[adju][dance]
                });
            }else{
                this.setState({
                    activeDanceNo:danceNo,
                    activeAdjNo:adjNo,
                    error:'',
                    success:'',
                    marks
                });
                if(!dances.includes('exhibition') && !dances.includes('showcase')&& !dances.includes('showdance')&& !dances.includes('solo spot')){

                    document.getElementById(dancers[0]).focus();
                }
            }
        }

    };


    // change the dance or adjudicator - mode = dance or adjudicator, direction = forward/backwards
    changeDance=(mode, direction)=>{
        const {dances,adjudicators,activeDanceNo,activeAdjNo,selectedDancers,roundType,marks}=this.state;

        const currentDance= dances[activeDanceNo];
        const currentAdjudicator=adjudicators[activeAdjNo];

        const nextDanceNo = direction==='forward'? activeDanceNo+1 < dances.length? activeDanceNo+1:0: activeDanceNo-1 >= 0? activeDanceNo-1:0;
        const nextAdjNo = direction==='forward'? activeAdjNo+1< adjudicators.length? activeAdjNo+1:0:  activeAdjNo-1>=0? activeAdjNo-1:0;


        const nextDance=dances[nextDanceNo];
        const nextAdj = adjudicators[nextAdjNo];


        if(mode ==='dance'){
            // move to the next dance, if the next dance is greater than the available dances then move to the next adjudicator

            if(nextDanceNo!==0){
                // we can then just move the next dance
                // first we need to check if marks contain the judge and the marks for the next dance
                if(marks[currentAdjudicator] !== undefined){
                    if(marks[currentAdjudicator][nextDance]===undefined){
                        // no marks present
                        this.setNextPosition(nextDanceNo,activeAdjNo);
                    }else{
                        // marks present we need to set the marks aswell
                        this.setNextPosition(nextDanceNo,activeAdjNo,'marks');
                    }
                }else{
                    // no marks present
                    this.setNextPosition(nextDanceNo,activeAdjNo);
                }
            }else{
                // we have to set the next adjudicator aswell
                if(marks[nextAdj] !== undefined){
                    // no marks present, set the next adj and the first dance
                    if(marks[nextAdj][nextDance]===undefined){
                        this.setNextPosition(0,nextAdjNo);
                    }else{
                        this.setNextPosition(0,nextAdjNo,'marks');
                    }

                }else{
                    // marks are present we need to set the marks aswell;
                    this.setNextPosition(0,nextAdjNo);
                }
            }
        }else{
            if(nextAdjNo!==0){
                // move to the next adjudicator and current dance
                if(marks[nextAdj]!== undefined){
                    if(marks[nextAdj][currentDance] !== undefined){
                        // marks present
                        this.setNextPosition(activeDanceNo,nextAdjNo,'marks');
                    }else{
                        this.setNextPosition(activeDanceNo,nextAdjNo);
                    }
                }else{
                    // no marks just move to the next adjudicator and current dance
                    this.setNextPosition(activeDanceNo,nextAdjNo);
                }
            }else{
                if(marks[nextAdj]!== undefined){
                    if(marks[nextAdj][currentDance]!== undefined){
                        this.setNextPosition(activeDanceNo,0, 'marks');
                    }else{
                        // no marks move to the first adjudicator and first dance
                        this.setNextPosition(activeDanceNo,0);
                    }
                }else{
                    // no marks just move to the next adjudicator and first dance
                    this.setNextPosition(activeDanceNo,0);
                }
            }
        }

    };

    // save dance
    onSaveDance=()=>{
        const {dancers,dances,adjudicators,activeDanceNo,activeAdjNo,selectedDancers,roundType,callbackLimit,marks}=this.state;

        const currentDance= dances[activeDanceNo];
        const currentAdjudicator=adjudicators[activeAdjNo];



        if(roundType==='Semi-Final'|| roundType==='Round'){
            // check is enough dancers have been selected

            if(selectedDancers.length !== parseInt(callbackLimit)){
                // issue a warning
                alert('Adjudicator has not given enough callbacks!')
            }

            //add marks - first check if adjudicator has marks
            if(marks[currentAdjudicator]!== undefined){
                marks[currentAdjudicator][currentDance]=selectedDancers;
            }else{
                marks[currentAdjudicator]={[currentDance]:selectedDancers};
            }
            marks[currentAdjudicator][currentDance]=selectedDancers;
            this.setState({marks});

            const marksDances = this.marksDances(marks);

            const danceSum = this.danceSum(adjudicators,dances);



            if(danceSum===marksDances){
                alert('All Marks entered, click save all to proceed!');
                this.setState({
                    success:'All Marks entered, click save all to proceed!'
                })
            }else{
                this.changeDance('dance','forward');
            }





        }
        // other round types
        else{
            if(marks[currentAdjudicator]!== undefined){
                if(marks[currentAdjudicator][currentDance]!==undefined){
                    if(Object.values(marks[currentAdjudicator][currentDance]).length === dancers.length){
                        // we can move to next dance

                        this.changeDance('dance','forward');
                    }else{
                        this.setState({error:"ERROR:Not all marks have been entered!"})
                    }
                }else{
                    this.setState({error:"ERROR:Not all marks have been entered!"})
                }
            }else{
                this.setState({error:"ERROR:Not all marks have been entered!"})
            }
        }

    };
    // handle clear all function
    clear=()=>{
        const {dancers, roundType,dances, selectedDancers,marks,activeDanceNo,activeAdjNo, adjudicators,specialMarks}=this.state;
        const currentDance = dances[activeDanceNo];
        const currentAdj = adjudicators[activeAdjNo];

        if(currentDance ==='exhibition'|| currentDance==='showcase'|| currentDance==='showdance'|| currentDance==='solo spot'){
            // handle special dances
            if(specialMarks[currentAdj]!== undefined){
                if(specialMarks[currentAdj][currentDance]!== undefined){
                    delete specialMarks[currentAdj][currentDance];
                    delete marks[currentAdj][currentDance];
                    this.setState({marks,specialMarks,error:'',success:''});
                }
            }
        }else{
            // handle normal final dances
            if(marks[currentAdj]!== undefined){
                if(marks[currentAdj][currentDance]!==undefined){
                    delete marks[currentAdj][currentDance];
                    this.setState({marks,error:'',success:''})
                }
            }
        }

    };

    handleChange= name => event=>{
        const {dancers, roundType,dances, selectedDancers, marks, adjudicators,activeDanceNo,activeAdjNo,specialMarks}=this.state;
        const value = event.target.value!==''?event.target.value:0;
        const currentDance = dances[activeDanceNo];
        const currentAdj = adjudicators[activeAdjNo];
        if(!isNaN(value)){
            // normal round
            if(roundType==='Round' || roundType==='Semi-Final'){
                this.setState({number:value})
            }// other round types
            else{
                if(currentDance ==='exhibition'|| currentDance==='showcase'|| currentDance==='showdance'|| currentDance==='solo spot'){

                    const splitVal=name.split(',');
                    const mode=splitVal[0];
                    const dancer = splitVal[1];

                    if(value<10){
                        // check marks for adjudicator
                        if(specialMarks[currentAdj]!== undefined){
                            // check specialMarks for adjudicator and dance
                            if(specialMarks[currentAdj][currentDance]!== undefined){
                                // check specialMarks for adjudicator, dance and dancer
                                if(specialMarks[currentAdj][currentDance][dancer]!== undefined){
                                    specialMarks[currentAdj][currentDance][dancer][mode]=value;
                                }else{
                                    specialMarks[currentAdj][currentDance][dancer]={[mode]:value}
                                }
                            }else{
                                specialMarks[currentAdj][currentDance]={[dancer]:{[mode]:value}}
                            }
                        }
                        else{
                            specialMarks[currentAdj]={[currentDance]:{[dancer]:{[mode]:value}}}
                        }

                        // run the add up for totals and placings here
                        specialMarks[currentAdj][currentDance][dancer]['total']= Object.keys(specialMarks[currentAdj][currentDance][dancer]).reduce((a,v)=>{
                            if(v==='T'|| v==='C'|| v==='I'|| v==='S'){
                                a+= parseInt(specialMarks[currentAdj][currentDance][dancer][v]);
                            }
                            return a;
                        },0);


                        // work out places
                        const dancersTotals=Object.keys(specialMarks[currentAdj][currentDance]).reduce((a,v)=>{

                            a[v]=specialMarks[currentAdj][currentDance][v]['total'];

                            return a;
                        },{});

                        let finalPosition={};

                        Object.values(dancersTotals).sort((a,b)=>b-a).map((tot,i)=>{

                            Object.keys(dancersTotals).map(dan=>{
                                if(dancersTotals[dan]===tot){
                                    const mark = roundType==='Uncontested'?Math.ceil(tot/40*100):i+1;
                                    specialMarks[currentAdj][currentDance][dan]['place']=mark;
                                    finalPosition[dan]=mark;
                                }
                            });
                        });

                        // run a check to ensure no ties
                        if(roundType !== 'Uncontested'){
                            const distinctPlaces = [...new Set(Object.values(finalPosition))];
                            const distinctDancers=Object.keys(finalPosition);

                            if(distinctPlaces.length === distinctDancers.length){

                                // check marks for current adjudicator
                                if(marks[currentAdj]!== undefined){
                                    marks[currentAdj][currentDance]=finalPosition;
                                }else{
                                    marks[currentAdj]={[currentDance]:finalPosition};
                                }

                                this.setState({marks,specialMarks})


                            }else{
                                alert('ERROR:Dancers cannot be tied!');
                                //console.log(this.state.specialMarks);
                                delete marks[currentAdj][currentDance];
                                this.setState({marks,error:"ERROR:Dancers cannot be tied!"})
                            }
                        }else{
                            if(marks[currentAdj]!== undefined){
                                marks[currentAdj][currentDance]=finalPosition;
                            }else{
                                marks[currentAdj]={[currentDance]:finalPosition}
                            }
                            this.setState({specialMarks,marks})
                        }

                        const nextMode = mode==='T'?"C":mode==='C'?"I":mode==='I'?"S":'T';
                        const currentDancer = dancers.reduce((a,v,i)=>{

                            if(v===dancer){
                                a=i;
                            }
                            return a;
                        },0);
                        if(nextMode==='T'){
                            if(currentDancer+1<dancers.length){
                                document.getElementById(nextMode+dancers[currentDancer+1]).focus();
                            }else{
                                document.getElementById('saveDance').focus();
                            }
                        }else{
                            document.getElementById(nextMode+dancers[currentDancer]).focus();
                        }


                    }else{
                        this.setState({error:'ERROR:Max value for place is 9.9!'})
                    }




                }
                else{

                    if(roundType==='Uncontested'){
                        if(value<4&& value>0){
                            if(marks[currentAdj]!== undefined){
                                if(marks[currentAdj][currentDance]!==undefined){
                                    marks[currentAdj][currentDance][name]=value;
                                }else{
                                    marks[currentAdj][currentDance]={[name]:value}
                                }
                            }else{
                                marks[currentAdj]={[currentDance]:{[name]:value}}
                            }
                            this.setState({marks,error:'',success:''});
                            const currentDancer=dancers.reduce((a,v,i)=>{
                                if(v===name){
                                    a=i;
                                }

                                return a;
                            },0);
                            const nextDancer = currentDancer+1<dancers.length?dancers[currentDancer+1]:'save';
                            document.getElementById(nextDancer).focus();
                        }else{
                            this.setState({error:"ERROR:Max value for uncontested sections is 3"})
                        }
                    }
                    else{
                        // normal competitive final
                        let test=false;
                        // first we need to check if the value entered is higher than the amount of dancers
                        if(value <= dancers.length){
                            if(marks[currentAdj]!== undefined){
                                if(marks[currentAdj][currentDance]!== undefined){

                                    const marksCheck = [... new Set(Object.values(marks[currentAdj][currentDance]))];
                                    if(!marksCheck.includes(value)){
                                        marks[currentAdj][currentDance][name]=value;
                                        test=true;
                                    }
                                }else{
                                    test=true;
                                    marks[currentAdj][currentDance]={[name]:value}
                                }
                            }else{
                                test=true;
                                marks[currentAdj]={[currentDance]:{[name]:value}}
                            }

                            if(test){
                                // find a way to change tabs aswell
                                this.setState({marks,error:'', success:''});
                                const currentDancer=dancers.reduce((a,v,i)=>{
                                    if(v===name){
                                        a=i;
                                    }

                                    return a;
                                },0);
                                const nextDancer = currentDancer+1<dancers.length?dancers[currentDancer+1]:'save';
                                document.getElementById(nextDancer).focus();
                            }else{
                                this.setState({error:'ERROR-Dancers cannot have the same marks!'})
                            }
                        }
                        else{
                            // alert the user we cannot accept that value
                            this.setState({error:"ERROR: Number greater than dancers available!"})
                        }
                    }
                }
            }
        }else{
            this.setState({error:'ERROR-Please check your input!'})
        }
    };

    renderDancers=()=>{
        const {dancers, roundType,dances, selectedDancers,marks,activeDanceNo,activeAdjNo, adjudicators,specialMarks}=this.state;

        const currentDance = dances[activeDanceNo];
        const currentAdj = adjudicators[activeAdjNo];

        function getValues(dancer){

            let base ={T:'',C:'',I:'',S:'',total:'',place:""};

            if(specialMarks[currentAdj]!== undefined){
                if(specialMarks[currentAdj][currentDance]!== undefined){
                    if(specialMarks[currentAdj][currentDance][dancer]!== undefined){
                        Object.keys(base).map(key=>{
                            if(specialMarks[currentAdj][currentDance][dancer][key]!==undefined){
                                base[key]=specialMarks[currentAdj][currentDance][dancer][key];
                            }
                        })
                    }
                }
            }

            return base;
        }

        //final/contested/uncontested
        if(roundType==='Final' || roundType==='Contested'||roundType==='Uncontested'){
            if(currentDance ==='exhibition'|| currentDance==='showcase'|| currentDance==='showdance'|| currentDance==='solo spot'){

                return (
                    <GridContainer>
                        <form style={{display:'flex',flewWrap:'wrap'}} onSubmit={e => { e.preventDefault(); }} noValidate autoComplete='off'>
                            <table>
                                <thead>
                                <tr>
                                    <th>Dancer</th>
                                    <th>T</th>
                                    <th>C</th>
                                    <th>I</th>
                                    <th>S</th>
                                    <th>Total</th>
                                    <th>{roundType==='Uncontested'?'%':'Place'}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {dancers.map(dancer=>{
                                    const dancerValues=getValues(dancer);
                                    return (
                                        <tr key={dancer}>
                                            <td>
                                                {dancer}
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'T'+dancer}
                                                    label='T'
                                                    value={dancerValues.T}
                                                    onChange={this.handleChange('T,'+dancer)}
                                                    margin='normal'
                                                    fullWidth
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'C'+dancer}
                                                    label='C'
                                                    value={dancerValues.C}
                                                    onChange={this.handleChange('C,'+dancer)}
                                                    margin='normal'
                                                    fullWidth
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'I'+dancer}
                                                    label='I'
                                                    value={dancerValues.I}
                                                    onChange={this.handleChange('I,'+dancer)}
                                                    margin='normal'
                                                    fullWidth
                                                />
                                            </td>
                                            <td>
                                                <TextField
                                                    id={'S'+dancer}
                                                    label='S'
                                                    value={dancerValues.S}
                                                    onChange={this.handleChange('S,'+dancer)}
                                                    margin='normal'
                                                    fullWidth
                                                />
                                            </td>
                                            <td>
                                                {dancerValues.total}
                                            </td>
                                            <td>
                                                {dancerValues.place} {roundType==='Uncontested'?'%':''}
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </form>
                        <GridItem xs={12}>
                            <Button onClick={()=>this.onSaveDance()}  id={'saveDance'}  round  size='sm' color='success'>Save</Button>
                            <Button onClick={()=>this.clear()} round size='sm' color='primary'>Clear</Button>
                        </GridItem>
                    </GridContainer>
                )




            }else{
                // normal final
                return (
                    <GridContainer>
                        <form style={{display:'flex',flewWrap:'wrap'}} onSubmit={e => { e.preventDefault(); }} noValidate autoComplete='off'>
                            {dancers.map(dancer=>{

                                let value='';
                                if(marks[currentAdj]!==undefined){
                                    if(marks[currentAdj][currentDance]!==undefined){
                                        if(marks[currentAdj][currentDance][dancer]!==undefined){
                                            value = marks[currentAdj][currentDance][dancer];
                                        }
                                    }
                                }


                                return (
                                    <GridItem xs={dancers.length>6?1:2} key={dancer}>
                                        <TextField
                                            id={dancer}
                                            label={dancer}
                                            value={value}
                                            onChange={this.handleChange(dancer)}
                                            margin='normal'
                                            fullWidth
                                        />
                                    </GridItem>
                                )

                            })}
                        </form>
                        <GridItem xs={12}>
                            <Button onClick={()=>this.onSaveDance()}   id={'save'}  round  size='sm' color='success'>Save</Button>
                            <Button onClick={()=>this.clear()}  round size='sm' color='primary'>Clear</Button>
                        </GridItem>
                    </GridContainer>
                )
            }

        }// semiFinal or round
        else{
            return (
                <GridContainer>
                    <GridItem xs={4}>
                        {dancers.map(dancer=>{
                            return (
                                <Button
                                    key={dancer}
                                    round
                                    size='sm'
                                    block
                                    color={selectedDancers.includes(dancer)?'rose':'info'}
                                    onClick={()=>this.handleSelectedDancer(dancer)}
                                >
                                    {dancer}
                                </Button>
                            )
                        })}
                    </GridItem>
                    <GridItem xs={8}>
                        <GridContainer>
                            <GridItem xs={6}/>
                            <GridItem xs={6}>
                                <form onSubmit={e => { e.preventDefault(); }}>
                                    <CustomInput
                                        style={{justifyContent:'flex-start'}}
                                        value={this.state.number}
                                        labelText={'Number'}
                                        id={'number'}
                                        formControlProps={{
                                            fullWidth: false
                                        }}
                                        inputProps={{
                                            autoComplete:'off',
                                            maxLength:1,
                                            value:this.state.number,
                                            onChange:(this.handleChange('number')),
                                        }}
                                    />
                                    <Button
                                        block
                                        style={{bottom:0}}
                                        onClick={()=>this.onSubmitNumber()}
                                        type={'submit'}
                                        color='info'
                                        size='sm'
                                        round
                                    >OK</Button>
                                </form>
                                <Button onClick={()=>this.onSaveDance()} type={'button'} color='rose' size='sm' round block>Save Dance</Button>
                            </GridItem>

                        </GridContainer>
                    </GridItem>
                </GridContainer>
            )

        }


    };

    onSave=()=>{
        // console.log('saved called');
        const {dancers,adjudicators,specialMarks,marks, roundType, dances}=this.state;

        const marksDances = this.marksDances(marks);
        const danceSum = this.danceSum(adjudicators,dances);
        const sectionType=this.props.sectionData.type;
        if(marksDances===danceSum){
            // check what type of section we need to pass through
            if(dances.includes('exhibition')|| dances.includes('showcase')|| dances.includes('showdance')|| dances.includes('solo spot')){
                // if special section we need to then check that those sections all have marks

                // need to re factor the marks so they look like
                const cats=['T','C','I','S','total'];


                const newSpecialMarks = adjudicators.reduce((a,adj)=>{

                    a[adj]=dances.reduce((ar,dance)=>{
                        if(dance ==='exhibition'|| dance==='showcase'|| dance==='showdance'|| dance==='solo spot'){
                            ar[dance]=cats.reduce((arr,cat)=>{

                                dancers.map(dancer=>{


                                    const mark = specialMarks[adj][dance][dancer][cat];
                                    if(arr[cat]){
                                        arr[cat][dancer]=parseInt(mark);
                                    }else{
                                        arr[cat]={[dancer]:parseInt(mark)};
                                    }
                                });

                                return arr;
                            },{});
                        }

                        return ar;
                    },{});


                    return a;
                },{});



                this.props.onSave(sectionType,marks,newSpecialMarks);
            }else{

                this.props.onSave(sectionType,marks,specialMarks);
            }
        }else{
            this.setState({error:'Error: All marks have not been entered!'})
        }

    };

    render(){
        const {classes,sectionData, roundData} = this.props;
        const {callbackLimit}=roundData;
        const {error,success, selectedDancers,dances,activeDanceNo,activeAdjNo, adjudicators, roundType,title, marks, specialMarks}=this.state;

        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <GridContainer>
                            <GridItem xs={6}>
                                <h4 className={classes.modalTitle}>Manual Marking</h4>
                                <Danger>{error}</Danger>
                                <Success>{success}</Success>
                            </GridItem>
                            <GridItem xs={6}>
                                <h6 className={classes.modalTitle}>{title}</h6>
                                <p>Round Type: <b>{roundType}</b></p>
                            </GridItem>
                            {roundType ==='Round'|| roundType==='Semi-Final'?
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={4}>
                                            <h5>Selected Dancers</h5>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <h6>Callback limit: {callbackLimit}</h6>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <h6><Success>Callback left: {callbackLimit!==undefined?(callbackLimit - selectedDancers.length):''}</Success></h6>
                                        </GridItem>
                                    </GridContainer>
                                    {selectedDancers.join(',  ')}
                                </GridItem>: ''
                            }


                        </GridContainer>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <GridContainer>
                            {/*Dance*/}
                            <GridItem xs={6} container justify={'center'} alignItems={'center'} direction={'row'} >
                                <Button size={'sm'} round justIcon onClick={()=>this.changeDance('dance','backward')}>
                                    <ChevronLeft />
                                </Button>
                                <h4 style={{paddingLeft:10,paddingRight:10}}>{dances[activeDanceNo]}</h4>
                                <Button size={'sm'} round justIcon onClick={()=>this.changeDance('dance','forward')}>
                                    <ChevronRight/>
                                </Button>
                            </GridItem>
                            {/*Adjudicator*/}
                            <GridItem xs={6} container justify={'center'} alignItems={'center'} direction={'row'} >
                                <Button size={'sm'} round justIcon onClick={()=>this.changeDance('adjudicator','backward')}>
                                    <ChevronLeft />
                                </Button>
                                <h4 style={{paddingLeft:10,paddingRight:10}}>{adjudicators[activeAdjNo]}</h4>
                                <Button size={'sm'} round justIcon onClick={()=>this.changeDance('adjudicator','forward')}>
                                    <ChevronRight/>
                                </Button>
                            </GridItem>
                            {/*Render Dancers */}
                            <GridItem xs={12}>
                                {this.renderDancers()}
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='button'
                            color={'success'}>
                            Save All
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}
export default withStyles(modalStyle)(ManualMarks2)