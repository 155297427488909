import React from 'react';
import {connect}from 'react-redux';
import Dashboard from "../../../components/Dashboard";

class EventDashboard extends React.Component{

    render(){
        return <Dashboard
                    eventData={this.props.eventData}
                />
    }
}

const mapStateToProps=state=>{

    return {
        eventData:state.eventData
    }
};

export default connect(mapStateToProps)(EventDashboard)