import React from 'react';
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
//material components
import {Save, Close, AddAlert,RemoveRedEye,} from '@material-ui/icons';
import Card from "../../../components/Card/Card";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import RenderInputs from "../../../Functions/RenderInputs";
import Button from "../../../components/CustomButtons/Button";
import {addAdjudicator, updateAdjudicator, deleteAdjudicator} from "../../../Store/Actions/Scrutineer/SingleEventActions";
import Loading from "../../../components/Loading";
import Snackbar from "../../../components/Snackbar/Snackbar";
import Table from "../../../components/Table/Table";
import AdjModal from '../../../components/Modals/ADJModal';
import Modal from "../../../components/Modals/Modal";
import ReactToPrint from "react-to-print";
import ADJ_Log from "../Components/Logs/ADJLog";
import {Tooltip} from "@material-ui/core";
class ScrutinAdjudicator extends React.Component{

    state={
        loading:false,
        notif:false,
        notifMsg:'',
        adjErr:'',
        adjEditId:null,
        adjEditModal:false,
        adjEditError:'',
        deleteModal:false,
        deleteId:''
    };

    onSubmit=(formValues)=>{
        this.setState({loading:true});
       this.props.addAdjudicator(formValues, (res)=>{
           res.status === 2 ? this.setState({loading:false, adjErr:res.msg}):
               ( this.setState({loading:false,adjErr:'', notif:true, notifMsg:'Adjudicator added' })

               )
       })
    };

    renderTable =()=>{

      if(this.props.adjudicators !== undefined && this.props.adjudicators !== null){
        const adjList =[];
        const array = this.props.adjudicators;
        Object.keys(array).map((key)=>{
           const info =[
               array[key].letter,
               array[key].name,
               array[key].loggedIn.toString(),
               array[key].deviceNumber,
               (<Tooltip title={array[key].code}>
                   <p>****</p>
               </Tooltip>),
               [(
                   <div>
                       <Tooltip title={'Edit'}>
                        <Button color='info' justIcon round size='sm' onClick={()=>this.setState({adjEditId:key, adjEditModal:true})}>
                            <RemoveRedEye/>
                        </Button>
                       </Tooltip>
                       <Tooltip title={'Delete'}>
                        <Button color='danger' justIcon round size='sm' onClick={()=>this.setState({deleteModal:true, deleteId:key})}>
                            <Close/>
                        </Button>
                       </Tooltip>
                   </div>
               )]
           ];
           adjList.push(info);
        });

        return(
            <Table
                tableHeaderColor={'primary'}
                tableHead={['Letter','Name','LoggedIn','Device No','Code','Actions']}
                tableData={adjList}
            />
        )
      }else{
          return (
              <p>No Adjudicators loaded yet!</p>
          )
      }
    };
    onSave=(formValues)=>{
        this.setState({loading:true});
        formValues.id = this.state.adjEditId;
        this.props.updateAdjudicator(formValues,(res)=>{
            res.status !==1 ?
                this.setState({loading:false,adjEditError:res.msg}):
                this.setState({loading:false, adjEditError:'', notif:true,notifMsg:"Adjudicator updated", adjEditId:null, adjEditModal:false })
        })
    };
    onConfirmDelete=()=>{
      this.setState({loading:true});
      this.props.deleteAdjudicator(this.state.deleteId, res=>{
          if(res.status === 1){
              this.setState({
                  loading:false,
                  notif:true,
                  notifMsg:'Adjudicator deleted successfully',
                  deleteModal:false,
                  deleteId:''
              })
          }
      })
    };

    render() {
        return(
            <div>
                <Modal
                    heading={'Delete Adjudicator'}
                    isVisible={this.state.deleteModal}
                    onClose={()=>this.setState({deleteModal:false, deleteId:''})}
                    onSave={this.onConfirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this adjudicator?</p>}
                />
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <AdjModal
                    isVisible={this.state.adjEditModal}
                    heading={'Adjudicator'}
                    onClose={()=>this.setState({adjEditModal:false, adjEditId:null})}
                    onSave={this.onSave}
                    adj={this.state.adjEditId}
                    errorMessage={this.state.adjEditError}
                />
                <GridContainer>
                    <GridItem xs={12} md={8}>
                        <Card>
                            <CardHeader color='primary'>
                                <h4>Adjudicators</h4>
                                <p>List of adjudicators and their lists login details</p>
                            </CardHeader>
                            <CardBody>
                                {this.renderTable()}
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} md={4}>
                        <Card>
                            <CardHeader color={'success'}>
                                <h4>Add Adjudicator</h4>
                            </CardHeader>
                            <CardBody>
                                <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                                    <Field id='letter' name='letter' label='Letter' component={RenderInputs}/>
                                    <Field id='firstName' name='firstName' placeholder='First Name' label='First Name' component={RenderInputs} />
                                    <Field id='lastName' name='lastName' placeholder='Last Name' label='Last name' component={RenderInputs}/>
                                    <Button color='success' type='submit' block round size='sm'><Save/>Save</Button>
                                </form>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color={"info"}>
                                <h4>Adjudicator Log</h4>
                                <ReactToPrint
                                    trigger={()=><Button  type='button' round size='sm'>PrintLog</Button>}
                                    content={()=>this.componentRef}
                                />
                            </CardHeader>
                            <CardBody>
                                <ADJ_Log
                                    info={this.props.info}
                                    adjLog={this.props.adjLog}
                                    ref={elem=>(this.componentRef=elem)}
                                />
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const validate = formValues=>{
  const errors={};
  const {firstName, lastName, letter} =formValues;
  if(!letter){
      errors.letter = 'Please enter a letter';
  }
  if(!firstName){
      errors.firstName = 'Please enter a name';
  }
  if(!lastName){
      errors.lastName ='Please enter a last name';
  }
  return errors;
};

const form =reduxForm({
    form:'addAdjudicator',
    validate
})(ScrutinAdjudicator);

const mapStateToProps=(state, ownProps)=>{

    return {
        info:state.eventData.info,
        adjudicators:state.eventData.adjudicators,
        adjLog:state.eventData.adjLog
    }
};

export default connect(mapStateToProps,{addAdjudicator, updateAdjudicator, deleteAdjudicator})(form);