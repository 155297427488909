import React from 'react';
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Table from "../../../../components/Table/Table";


class ADJ_Log extends React.Component{

    renderAdjudicatorLog=()=>{
        const {adjLog}=this.props;

        if(adjLog!==null && adjLog!== undefined){

            const tableData=Object.keys(adjLog).reverse().reduce((a,v)=>{

                const {mode,time,deviceNo,adjudicator,letter}=adjLog[v];

                a.push([
                    letter,
                    adjudicator,
                    deviceNo,
                    new Date(time).toLocaleDateString()+' - '+new Date(time).toLocaleTimeString(),
                    mode
                ]);

                return a;
            },[]);

            const tableHead=['Letter','Adjudicator','Device No','Time','Mode'];
            return (
                <Table
                    tableHeaderColor={'info'}
                    tableData={tableData}
                    tableHead={tableHead}
                />
            )


        }else{
            return (
                <div>
                    <h5>No Adjudicator log available yet!</h5>
                </div>
            )
        }
    };


    render(){
        const {info}=this.props;
        if(info !== null && info !== undefined){
            const {title,venue,date,scrutineer,chairPerson,organizer,organization}=info;
            const {thStyle,tdStyle}=Styles;
            return(
                <div style={{paddingLeft:'2%',paddingRight:"2%"}}>
                    <GridContainer>
                        <GridItem xs={12}>
                            <table>
                                <thead>
                                <tr>
                                    <th style={thStyle}>Title</th>
                                    <td  style={tdStyle}>{title}</td>
                                </tr>
                                <tr>
                                    <th  style={thStyle}>Date</th>
                                    <td style={tdStyle}>{date}</td>
                                    <th  style={thStyle}>Organization</th>
                                    <td style={tdStyle}>{organization}</td>
                                </tr>
                                <tr>
                                    <th  style={thStyle}>Venue</th>
                                    <td style={tdStyle}>{venue}</td>
                                </tr>
                                <tr>
                                    <th  style={thStyle}>Chairperson</th>
                                    <td style={tdStyle}>{chairPerson}</td>
                                    <th  style={thStyle}>Scrutineer</th>
                                    <td style={tdStyle}>{scrutineer.name}</td>
                                    <th  style={thStyle}>Organizer</th>
                                    <td style={tdStyle}>{organizer.name}</td>
                                </tr>
                                </thead>
                            </table>
                        </GridItem>
                        <GridItem xs={12}>
                            {this.renderAdjudicatorLog()}
                        </GridItem>
                    </GridContainer>
                </div>
            )
        }else{
            return (
                <div>
                    Loading Data....
                </div>
            )
        }



    }

}
const Styles={
    thStyle:{
        textAlign:'left'
    },
    tdStyle:{
        textAlign: 'left'
    }
};
export default ADJ_Log;
