import React from 'react';
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import colors from "../../Variables";
import {Link} from "react-router-dom";

const logo = require('../../assets/img/Main_Logo.png');
const linkStyle={color:colors.dSBlue};
class SiteLinks extends React.Component{
    render(){
        const { classes, ...rest } = this.props;
        return (
            <GridContainer alignItems={'center'} justify={'center'}>
                <GridItem xs={12} container alignItems={'center'} justify={'center'} direction={'column'}>
                    <h3 className={classes.title}  style={{color:colors.dSBlue}} >Quick Links</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container alignItems={'center'} justify={'center'}>
                    <img src={logo} height={100} style={{padding:20}}/>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container alignItems={'center'} justify={'center'} direction={'column'}>
                    <Link style={linkStyle} to={'/results'} >
                        Results
                    </Link><br/>
                    <Link style={linkStyle} to={'/terms'} >
                        Terms & Conditions
                    </Link><br/>
                    <Link style={linkStyle} to={'/privacy'} >
                        Privacy Terms
                    </Link><br/>
                    <Link style={linkStyle} to={'/officials'} >
                        Officials
                    </Link><br/>
                </GridItem>
                <GridItem xs={12} sm={12} md={4} container alignItems={'center'} justify={'center'} direction={'column'}>
                    <Link style={linkStyle} to={'/live'} >
                        Live Events
                    </Link><br/>
                    <Link style={linkStyle} to={'/contact'} >
                        Contact Us
                    </Link><br/>
                    <Link style={linkStyle} to={'/about'} >
                        About
                    </Link>
                </GridItem>

            </GridContainer>
        )
    }
}
export default SiteLinks