import React from 'react';
import {connect} from 'react-redux';
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import CardHeader from "../../../../components/Card/CardHeader";
import Card from "../../../../components/Card/Card";
import Button from "../../../../components/CustomButtons/Button";
import {AddBox, ImportExport, AddAlert, Edit,Close, CloudDownload, Search} from '@material-ui/icons'
import CardBody from "../../../../components/Card/CardBody";
import SectionModal from '../../../../components/Modals/SectionModal';
import Modal from "../../../../components/Modals/Modal";
import {importSections,addSection, deleteSection, viewSection} from "../../../../Store/Actions/Scrutineer/SectionActions";
import Loading from "../../../../components/Loading";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import Table from "../../../../components/Table/Table";
import history from "../../../../history";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import {InputAdornment} from "@material-ui/core";
import ImportModal from "../../../../components/Modals/ImportModal";

class ScrutinSections extends React.Component{
    state={
      modal:false,
      modalHeading:'',
        errorMessage:'',
        loading:false,
        notif:false,
        notifMsg:'',
        deleteId:null,
        deleteModal:false,
        search:'',
        importModal:false
    };

    onSave=(formValues)=>{
        this.setState({loading:true});
        this.props.addSection(formValues,(res)=>{
            res.status !== 1 ?
            this.setState({loading:false,errorMessage:res.msg}) :
            this.setState({modal:false,loading:false,notif:true,notifMsg:'Section Added'})
        })
    };

    renderSections=()=>{
        const sections = this.props.sections;
        if(sections !== null){
            const tableData = Object.keys(sections).reduce((a,v,i)=>{
                if( sections[v]?.title?.toLocaleLowerCase().includes(this.state.search.toLocaleLowerCase())){
                    a.push([
                        i+1,
                        sections[v].title,
                        sections[v].roundNo,
                        sections[v].nextRound !== undefined ? sections[v].nextRound:'No Rounds Added',
                        sections[v].rounds !== undefined? Object.keys(sections[v].rounds).length:0,
                        sections[v].dancers !== undefined ? Object.keys(sections[v].dancers).length:0,
                        sections[v].type,
                        (<div>
                            <Button onClick={()=>this.setState({deleteModal:true,deleteId:v})} color='danger' round justIcon size={'sm'}><Close/></Button>
                            <Button onClick={()=>this.openSection(v)} color='info' round justIcon size={'sm'}><Edit/></Button>
                        </div>)
                    ]);
                }

                return a
            },[]);
            const tableHead =['No','Title', 'Round No','Next Round', 'Total Rounds','No Dancers','Type', 'Actions'];
            return (
                <Table
                    tableData={tableData}
                    tableHead={tableHead}
                    tableHeaderColor={'info'}
                />
            )

        }else {
            return (
                <div>
                    <h5>No Sections Added yet!</h5>
                </div>
            )
        }
    };

    confirmDelete=()=>{
        this.setState({loading:true});
        this.props.deleteSection(this.state.deleteId, ()=>{
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Section Deleted Successfully',
                deleteModal:false,
                deleteId:null
            })
        })
    };

    openSection=(id)=>{
        this.props.viewSection(id, ()=>{
            history.push('/scrutineer/Screens/sections/view')
        })
    };

    handleChange = name => event => {
        this.setState({search:event.target.value})
    };

    saveImport =(values,sortedSections)=>{
        this.setState({loading:true});
        this.props.importSections(values,sortedSections,()=>{
            this.setState({
                importModal:false,
                loading:false,
                notif:true,
                notifMsg:'Sections Imported'
            })
        })
    };

    render() {
        return(
            <div>
                <ImportModal
                    isVisible={this.state.importModal}
                    onClose={()=>this.setState({importModal:false})}
                    onSave={this.saveImport}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={this.state.loading}
                />
                <SectionModal
                    errorMessage={this.state.errorMessage}
                    heading={this.state.modalHeading}
                    isVisible={this.state.modal}
                    onClose={()=>this.setState({modal:false})}
                    onSave={this.onSave}
                />
                <Modal
                    errorMessag={'Are you sure you want to delete this note?'}
                    heading={'Delete Section'}
                    isVisible={this.state.deleteModal}
                    onClose={()=>this.setState({deleteModal:false, deleteId:null})}
                    onSave={this.confirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this section?</p>}
                />
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color='info'>
                                <div>
                                    <h4>Sections</h4>
                                    <div style={{textAlign:'right'}}>
                                        <a href={require('../../../../assets/DanceSuite-ImportTemplate.xlsx')}><Button color='white' size='sm' round ><CloudDownload/>Download Import Template</Button></a>
                                        <Button onClick={()=>this.setState({importModal:true})} color='rose' size='sm' round><ImportExport/>Import Sections</Button>
                                        <Button onClick={()=>this.setState({modal:true, modalHeading:'Add Section'})} color='white' size='sm' round ><AddBox/>Add Sections</Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={8}/>
                                    <GridItem xs={12} sm={4}>
                                        <CustomInput
                                            labelText="Search"
                                            id="Search"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange:(this.handleChange('search')),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Search/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </GridItem>
                                </GridContainer>
                                {this.renderSections()}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const mapStateToProps =state=>{
    let sections=null;
    if(state.eventData.sections !== null && state.eventData.sections !== undefined){
        sections = state.eventData.sections
    }

    return {sections:sections}
};

export default connect(mapStateToProps,{importSections, addSection, deleteSection, viewSection})(ScrutinSections);
