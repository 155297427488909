import React from 'react';
import {connect}from'react-redux';
import {InputAdornment, Tooltip} from "@material-ui/core";
import {PlaylistAddCheck, Close,AddAlert,List,Reorder,FilterList,ImportExport,Search} from '@material-ui/icons';
//components
import {deleteMarks, deleteMultiFinalMarks, setMarks, setMultiDanceMarks} from "../../../../Store/Actions/Scrutineer/SectionActions";
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import Table from "../../../../components/Table/Table";
import Loading from "../../../../components/Loading";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import Modal from "../../../../components/Modals/Modal";
import Danger from "../../../../components/Typography/Danger";
import Tabs from "../../../../components/CustomTabs/CustomTabs";
import CustomInput from "../../../../components/CustomInput/CustomInput";
import history from "../../../../history";

class ScrutinResults extends React.Component{

    state={
        modal:false,
        loading:false,
        notif:false,
        notifMsg:'',
        deleteRoundId:null,
        sectionId:null,
        deleteError:'',
        search:'',
        searchFinal:'',
        searchSection:'',
        deleteSectionId:null,
        deleteSectionModal:false,
        deleteSectionTitle:''
    };

    renderRounds =()=>{
      const sections  = this.props.sections;
      const search = this.state.search.toLocaleLowerCase();
      if(sections !== null && sections !== undefined){
          const tableData =Object.keys(sections).reduce((a,v)=>{

              if(sections[v].rounds !== undefined){
                  const rounds = sections[v].rounds;
                  Object.keys(rounds).map((ar)=>{
                      const info = rounds[ar];
                      if(info.marks !== undefined){
                          if(sections[v].title.toLocaleLowerCase().includes(search)
                              || info.itemNo.includes(search)
                              || info.roundType.toLocaleLowerCase().includes(search)
                              || info.status.toLocaleLowerCase().includes(search)
                              || sections[v].type.toLocaleLowerCase().includes(search)

                          ){
                              a.push([
                                  parseInt(info.itemNo),
                                  sections[v].type==='multiDance'?sections[v].title+'-'+info.dances[0]:sections[v].title,
                                  info.roundType,
                                  info.roundNo,
                                  sections[v].type,
                                  info.marks !== undefined && info.status==='ready'? Object.keys(info.marks).length+'/'+info.panel.letters.split(',').length:info.status,
                                  (<div>
                                      {
                                          <Tooltip title={"Delete Marks"}>
                                              <Button onClick={()=>this.setState({modal:true, sectionId:v, deleteRoundId:ar})}  color='danger' size='sm' justIcon round><Close/></Button>
                                          </Tooltip>
                                      }
                                      {
                                          info.marks !== undefined?(
                                              <Tooltip title='View Results'>
                                                  <Button onClick={()=>this.viewMarks(v,ar,info)} color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                              </Tooltip>
                                          ):''
                                      }
                                  </div>)
                              ])
                          }
                      }

                  })
              }

              return a;
          },[]).sort((a,b)=>a[0]-b[0]);

          const tableHead = ['Item no','Section', 'Round Type','Round No','Type', 'Status', 'Action'];
          return (
              <Table
                tableHeaderColor={'warning'}
                tableHead={tableHead}
                tableData={tableData}
              />
          )
      }
    };

    renderFinalRound=()=>{
        const sections  = this.props.sections;
        const search = this.state.searchFinal.toLocaleLowerCase();
        if(sections !== null && sections !== undefined){
            const tableData =Object.keys(sections).reduce((a,v)=>{

                if(sections[v].rounds !== undefined){
                    const rounds = sections[v].rounds;
                    Object.keys(rounds).map((ar)=>{
                        const info = rounds[ar];
                        if(info.marks !== undefined){
                            if(sections[v]?.title.toLocaleLowerCase().includes(search)
                                || info?.itemNo.includes(search)
                                || info?.roundType.toLocaleLowerCase().includes(search)
                                || info?.status.toLocaleLowerCase().includes(search)
                                || sections[v]?.type.toLocaleLowerCase().includes(search)
                            ){
                                if(info.roundType ==='Final'|| info.roundType==='Uncontested'|| info.roundType==='Contested'){
                                    a.push([
                                        parseInt(info.itemNo),
                                        sections[v].type==='multiDance'?sections[v].title+'-'+info.dances[0]:sections[v].title,
                                        info.roundType,
                                        info.roundNo,
                                        sections[v].type,
                                        info.status,
                                        (<div>
                                            {
                                                <Tooltip title={"Delete Marks"}>
                                                    <Button onClick={()=>this.setState({modal:true, sectionId:v, deleteRoundId:ar})}  color='danger' size='sm' justIcon round><Close/></Button>
                                                </Tooltip>
                                            }
                                            {
                                                info.marks !== undefined?(
                                                    <Tooltip title='View Results'>
                                                        <Button onClick={()=>this.viewMarks(v,ar,info)} color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                                    </Tooltip>
                                                ):''
                                            }
                                        </div>)
                                    ])
                                }

                            }
                        }

                    })
                }

                return a;
            },[]).sort((a,b)=>a[0]-b[0]);

            const tableHead = ['Item no','Section', 'Round Type','Round No','Type', 'Status', 'Action'];
            return (
                <Table
                    tableHeaderColor={'warning'}
                    tableHead={tableHead}
                    tableData={tableData}
                />
            )
        }
    };

    renderSections=()=>{
        const sections  = this.props.sections;
        const search = this.state.searchSection.toLocaleLowerCase();

        if(sections !== null && sections !== undefined){

            const tableData = Object.keys(sections).reduce((a,v)=>{


                const {type,title,processed,finalResult, rounds}=sections[v];

                if(type==='multiDance'){
                    if(title?.toLocaleLowerCase().includes(search)){

                        if(rounds!== undefined){

                            const marksCheck =[];
                            Object.keys(rounds).map(round=>{
                                const {processed}=rounds[round];
                                if(processed){
                                    marksCheck.push(round);
                                }
                            });

                            a.push([
                                title,
                                type,
                                processed!== undefined?processed.toString():'False',
                                (<div>
                                    {finalResult!== undefined?
                                        <Tooltip title='Delete Result'>
                                            <Button  onClick={()=>this.setState({deleteSectionId:v,deleteSectionModal:true,deleteSectionTitle:title})}  color='danger' size='sm' justIcon round><Close/></Button>
                                        </Tooltip>
                                        :''}
                                    {marksCheck.length === Object.keys(rounds).length?
                                        <Tooltip title={'View Marks'}>
                                            <Button onClick={()=>this.viewSectionMarks(v,sections[v])}  color='success' round size='sm' justIcon><PlaylistAddCheck/></Button>
                                        </Tooltip>:''
                                    }
                                </div>)
                            ])
                        }

                    }

                }

                return a;
            },[]);

            if(tableData.length >0){

                const tableHead=['Title','Type','Processed','Actions'];

                return (
                    <Table
                        tableHead={tableHead}
                        tableData={tableData}
                        tableHeaderColor={'warning'}
                    />
                )
            }else{
                return (
                    <div>No Multi Dance sections!</div>
                )
            }




        }else{
            return (<div>No Sections added yet!</div>)
        }


    };

    confirmDeleteMarks =()=>{
        const {sectionId, deleteRoundId}=this.state;
      this.setState({loading:true});
      this.props.deleteMarks(sectionId,deleteRoundId,(res)=>{
          res.status !== 1 ?
              this.setState({loading:false, deleteError:res.msg}):
              this.setState({
                  loading:false,
                  deleteRoundId: null,
                  sectionId: null,
                  notif:true,
                  notifMsg:'Marks deleted successfully!',
                  modal:false,
                  deleteError:''
              })
      })
    };

    handleChange = name=>event=>{
      const value = event.target.value;
      this.setState({[name]:value})
    };

    viewMarks=(sectionId, roundId,roundData)=>{
        this.props.setMarks(sectionId,roundId,roundData,()=>history.push('/scrutineer/Screens/results/marks'))
    };

    viewSectionMarks=(sectionId,sectionData)=>{
        this.props.setMultiDanceMarks(sectionId,sectionData,()=>{
            history.push('/scrutineer/Screens/results/marks')
        })
    };

    handleDeleteMarks=()=>{
        const {deleteSectionId}=this.state;
        this.setState({loading:true});
        this.props.deleteMultiFinalMarks(deleteSectionId,(res)=>res.status===1?
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Final result deleted successfully!',
                deleteSectionId: null,
                deleteSectionModal:false,
                deleteSectionTitle:''
            }):null
        )
    };

    handleViewAll=()=>{
      this.setState({loading:true});
      history.push('/scrutineer/Screens/results/viewAll')
    };

    render() {
        const {deleteError,modal,deleteSectionModal,deleteSectionTitle}=this.state;
        return(
            <div>
                <Modal
                    heading={'Delete Marks'}
                    isVisible={deleteSectionModal}
                    onClose={()=>this.setState({deleteSectionModal: false,deleteSectionTitle:'',deleteSectionId:null})}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    onSave={this.handleDeleteMarks}
                    content={
                        <div>
                            <p><b>Are you sure you want to delete the final results for {deleteSectionTitle}</b></p>
                            <Danger>Note-this cannot be undone!</Danger>
                        </div>
                    }
                />
                <Modal
                    errorMessage={deleteError}
                    heading={'Delete Marks'}
                    isVisible={modal}
                    onClose={()=>this.setState({modal:false, deleteRoundId:null,sectionId:null})}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    onSave={this.confirmDeleteMarks}
                    content={
                        <div>
                            <p>Are you sure you want to delete these marks?</p>
                            <Danger>This cannot be undone!</Danger>
                        </div>
                    }
                />
                <Loading
                    show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <GridContainer>
                    <GridItem xs={12}>
                        <Tabs
                            headerColor={'warning'}
                            tabs={[
                                {
                                    tabName:'Round Results',
                                    tabIcon:Reorder,
                                    tabContent:(
                                        <div>
                                            <GridContainer>
                                                <GridItem xs={12} sm={8}>
                                                    <Button onClick={()=>this.handleViewAll()} size={'sm'} color='info' round><ImportExport/>Export All</Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={4}>
                                                    <CustomInput
                                                        style={{justifyContent:'flex-start'}}
                                                        value={this.state.search}
                                                        labelText={'Search'}
                                                        id={'search'}
                                                        formControlProps={{
                                                            fullWidth: false
                                                        }}
                                                        inputProps={{
                                                            autoComplete:'off',
                                                            value:this.state.search,
                                                            onChange:(this.handleChange('search')),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Search/>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            {this.renderRounds()}
                                        </div>
                                    )
                                },
                                {
                                    tabName:'Final Results',
                                    tabIcon:FilterList,
                                    tabContent:(
                                        <div>
                                            <GridContainer>
                                                <GridItem xs={12} sm={8}/>
                                                <GridItem xs={12} sm={4}>
                                                    <CustomInput
                                                        style={{justifyContent:'flex-start'}}
                                                        value={this.state.search}
                                                        labelText={'Search'}
                                                        id={'searchFinal'}
                                                        formControlProps={{
                                                            fullWidth: false
                                                        }}
                                                        inputProps={{
                                                            autoComplete:'off',
                                                            value:this.state.searchFinal,
                                                            onChange:(this.handleChange('searchFinal')),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Search/>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            {this.renderFinalRound()}
                                        </div>
                                    )
                                },
                                {
                                    tabName:'Multi Dance Section Results',
                                    tabIcon:List,
                                    tabContent:(
                                        <div>
                                            <GridContainer>
                                                <GridItem xs={12} sm={8}/>
                                                <GridItem xs={12} sm={4}>
                                                    <CustomInput
                                                        style={{justifyContent:'flex-start'}}
                                                        value={this.state.searchSection}
                                                        labelText={'Search'}
                                                        id={'searchSection'}
                                                        formControlProps={{
                                                            fullWidth: false
                                                        }}
                                                        inputProps={{
                                                            autoComplete:'off',
                                                            value:this.state.searchSection,
                                                            onChange:(this.handleChange('searchSection')),
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <Search/>
                                                                </InputAdornment>
                                                            )
                                                        }}
                                                    />
                                                </GridItem>
                                            </GridContainer>
                                            {this.renderSections()}
                                        </div>
                                    )
                                }
                            ]}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const mapStateToProps =state=>{
    return {sections:state.eventData.sections}
};

export default connect(mapStateToProps, {deleteMarks, setMarks, setMultiDanceMarks,deleteMultiFinalMarks})(ScrutinResults);
