import React from "react";
import {Router,Route}from 'react-router-dom';
import history from "./history";

//components
import './assets/css/material-dashboard-react.css';
import Home from "./Screens/Home";
import ScrutineerHome from "./Screens/Scrutineer";
import ScrutineerDash from "./Screens/Scrutineer/EventHome";
import Chairperson from "./Screens/Chairperson";
import EventHome from "./Screens/Chairperson/EventHome";
import Privacy from "./Screens/Privacy";
import Terms from "./Screens/Terms";
import ConsentCookie from "./components/ConsentCookie";

const App =()=>{
    return (
        <div>
            <ConsentCookie/>
            <Router history={history}>
                <Route exact path={'/'} component={Home}/>
                <Route exact path={'/scrutineer'} component={ScrutineerHome}/>
                <Route path={'/scrutineer/Screens'} component={ScrutineerDash}/>
                <Route exact path={'/chairperson'} component={Chairperson}/>
                <Route path={'/chairperson/event'} component={EventHome}/>
                <Route path={'/privacy'} component={Privacy}/>
                <Route path={'/terms'} component={Terms}/>
            </Router>
        </div>
    )
}
export default App;
