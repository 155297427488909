import React from 'react';
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Footer from "../../../components/Footer/FooterMain";
import {returnFinalResult, dancesWon} from "../../Alogrithms/finalAlgo";
import PrintEventHead from "./PrintEventHead";

const rowStyle={padding:10, fontSize:25};
class PrintFinalResult extends React.Component{

    renderPositions=()=>{

        const rounds = this.props.sectionRounds;

        const dancers =this.props.dancers;
        const finalPlacingResult =this.props.sectionType !=='multiDance'?returnFinalResult(rounds):[this.props.roundData.results];

        const organizedDancers = Object.keys(dancers).reduce((a,v)=>{
            const dancer = dancers[v].number;
            a[dancer]= {
                name:dancers[v].names,
                heat : this.props.sectionType ==='social'? dancers[v].heat!== undefined?dancers[v].heat:0:0,
                teacher:this.props.sectionType==='social'? dancer[v].teacher!== undefined?dancer[v].teacher:'':''
            };

            return a;
        },{});

        const dancesResult=dancesWon(this.props.roundData);


      return (
          <div>
                  {finalPlacingResult.map((round,i)=>{

                      const sortedPlacings = Object.values(round).sort((a,b)=>a-b);
                      const sortedDancers = Object.keys(round);
                      const distinctPLaces = [...new Set(sortedPlacings)];
                      return (
                                <div>
                                    <table>

                                        <tbody>
                                        {distinctPLaces.map(place=>{
                                         return sortedDancers.map((dancer,i)=>{
                                                if(round[dancer]=== place){
                                                    return(
                                                        <tr>
                                                            <td style={rowStyle}>{place}</td>
                                                            <td style={rowStyle}><b>{dancer}</b></td>
                                                            <td style={rowStyle}>{organizedDancers[dancer].name}</td>
                                                            {this.props.sectionType ==='social'?
                                                                <td>{organizedDancers[dancer].heat}</td>:null
                                                            }
                                                            {this.props.sectionType ==='social'?
                                                                <td>{organizedDancers[dancer].teacher}</td>:null
                                                            }
                                                            {dancesResult[dancer] !== undefined?
                                                                <td>{dancesResult[dancer].join(', ')}</td>:null
                                                            }

                                                        </tr>
                                                    )
                                                }
                                            })

                                        })}
                                        </tbody>
                                    </table>
                                    <br/>
                                </div>
                      )
                  })}
          </div>
      ) ;
    };

    renderUncontested=()=>{
        const {results, activeList, roundType}=this.props.roundData;
        const dancers = Object.keys(this.props.dancers).reduce((a,v)=>{

            const dancerInfo = this.props.dancers[v];

            a[dancerInfo.number]={
                name: dancerInfo.names,
                heat : this.props.sectionType ==='social'?dancerInfo.heat!== undefined?dancerInfo.heat:null:null,
                teacher:this.props.sectionType==='social'?dancerInfo.teacher!== undefined?dancerInfo.teacher:null:null
            };

            return a;
        },{});



        return (
            <div>
                <table>
                    <thead>
                    <tr>
                        <th>Number</th>
                        <th>Result</th>
                        <th>Name</th>
                        {this.props.sectionType ==='social'?
                            <th>Heat</th>:null
                        }
                        {this.props.sectionType ==='social'?
                            <th>Teacher</th>:null
                        }
                    </tr>
                    </thead>
                    <tbody>
                        {activeList.sort((a,b)=>a-b).map(dancer=>{
                         return (
                             <tr key={dancer}>
                                 <td style={rowStyle}><b>{dancer}</b></td>
                                 <td style={rowStyle}>{results[dancer]}</td>
                                 <td style={rowStyle}>{dancers[dancer].name}</td>
                                 {this.props.sectionType ==='social'?
                                     <td>{dancers[dancer].heat}</td>:''
                                 }
                                 {this.props.sectionType ==='social'?
                                     <td>{dancers[dancer].teacher}</td>:''
                                 }

                             </tr>
                         )


                        })}
                    </tbody>
                </table>
            </div>
        )

    };

    render(){
        const {logo,title,date,venue,organization,organizer,scrutineer}=this.props.data.info;
        const {sectionTitle,panel,roundData,sectionType,}=this.props;
        return (
            <div>
                <GridContainer style={{padding:25}}>
                    <GridItem xs={12}/>
                    <GridItem xs={12}>
                        <PrintEventHead
                            logo={logo.url}
                            title={title}
                            date={date}
                            venue={venue}
                            organizer={organizer}
                            organization={organization}
                            scrutineer={scrutineer}
                            sectionTitle={sectionType==='multiDance'?
                                roundData.itemNo+' '+sectionTitle+'-'+roundData.roundType+'-'+roundData.dances[0]:
                                roundData.itemNo+' '+sectionTitle+'-'+roundData.roundType}
                            roundNo={roundData.roundNo}
                        />
                    </GridItem>
                    {roundData.roundType ==='Final'?
                        this.renderPositions():
                        this.renderUncontested()
                    }
                </GridContainer>
                <div  style={{position:'absolute',bottom:0}}>
                    <Footer/>
                </div>
            </div>
        )
    }
}export default PrintFinalResult;