import React from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import {List,ListItem} from "@material-ui/core";
// icons
import {Person, ExitToApp} from '@material-ui/icons'
import headerLinksStyle from "../../assets/jss/material-kit-react/components/headerLinksStyle.jsx";
import Button from "../CustomButtons/Button";

function ScrutinHeadLinks({ ...props }){
    const { classes, profileClick, signOutClick } = props;
    return(
        <List className={classes.list}>
            <ListItem className={classes.listItem}>
                <Button simple className={classes.navLink} onClick={profileClick}>
                    <Person/>
                    Profile
                </Button>
            </ListItem>
            <ListItem className={classes.listItem}>
                <Button simple className={classes.navLink} onClick={signOutClick}>
                    <ExitToApp/>
                    Sign out
                </Button>
            </ListItem>
        </List>
    )
}

export default withStyles(headerLinksStyle)(ScrutinHeadLinks);