import React from 'react';
import Transition from "../../Functions/Transistion";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/icons/Close";
import DialogContent from "@material-ui/core/DialogContent";
import Danger from "../Typography/Danger";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import RenderInputs from "../../Functions/RenderInputs";
import {connect} from 'react-redux';
import {Field, reduxForm} from "redux-form";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import {withStyles, Checkbox, FormControlLabel} from "@material-ui/core";
import Success from "../Typography/Success";
import {Link} from 'react-router-dom';

class RegistrationModal extends React.Component{

    state={
        checked:false,
        error:''
    };

    onSubmit=(formValues)=>{
        if(this.state.checked){
            this.props.onSave(formValues);
        }else {
            alert('Please agree to the terms and conditions!');
        }

    };

    handleChange = name => event => {
        this.setState({checked: event.target.checked });
    };

    render(){
        const {classes}=this.props;
        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <div>
                                <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                                <Danger>{this.props.errorMessage}</Danger>
                                <Success>{this.props.successMessage}</Success>
                            </div>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>

                            <GridContainer style={{textAlign:'center'}}>
                                <GridItem xs={12}>
                                    <p>IMPORTANT TO NOTE: Once you have completed registration, your registration will be under review, only after acceptance of the administration panel will you be granted access to the system.</p>
                                </GridItem>
                                <GridItem xs={2}/>
                                <GridItem xs={12} sm={8}>
                                    <Field id='firstName' type='text' placeholder='First Name' name='firstName' label='First Name' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={2}/>
                                <GridItem xs={2}/>
                                <GridItem xs={12} sm={8}>
                                    <Field id='lastName' type='text' placeholder='Last Name' name='lastName' label='Last Name' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={2}/>
                                <GridItem xs={2}/>
                                <GridItem xs={12} sm={8}>
                                    <Field id='country' type='text' placeholder='Country' name='country' label='Country' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={2}/>
                                <GridItem xs={2}/>
                                <GridItem xs={12} sm={8}>
                                    <Field id='email' type='email' placeholder='name@email.com' name='email' label='email' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={2}/>
                                <GridItem xs={2}/>
                                <GridItem xs={12} sm={8}>
                                    <Field id='password' type='password' placeholder='*********' name='password' label='Password' component={RenderInputs}/>
                                </GridItem>
                                <GridItem xs={2}/>
                                <GridItem xs={2}/>
                                <GridItem>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={this.state.checked}
                                                onChange={this.handleChange('terms')}
                                                value={'terms'}
                                                color={'default'}
                                            />
                                        }
                                        label={<Link to={'terms'}>Terms and Conditions</Link>}
                                    />
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            {this.props.successMessage === null?
                                <Button
                                    type='submit'
                                    color="success">
                                    Register
                                </Button>:null
                            }

                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}
const validate =formValues=>{
    const errors={};
    const {firstName,lastName,email,password,country}=formValues;

    if(!firstName){
        errors.firstName ='Please enter your first name!'
    }
    if(!lastName){
        errors.lastName = 'Please enter your last name!'
    }
    if(!country){
        errors.country = 'Please enter your country'
    }
    if(!password){
        errors.password = "Please enter your password"
    }
    if(!email){
        errors.email = 'please enter your email'
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email'
    }

    return errors;
};

const form = reduxForm({
    form:'registrationForm',
    validate
})(RegistrationModal);

export default connect(null)(withStyles(modalStyle)(form));