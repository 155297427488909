import React from 'react';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Footer from "../../../components/Footer/FooterMain";
import PrintEventHead from "./PrintEventHead";

const rowStyle={padding:10, fontSize:25};
class PrintMultiDanceFinalResult extends React.Component{


    renderDancers=()=>{
      const {sectionData}=this.props;
      const {finalResult,dancers}=sectionData;

      const finalists = Object.keys(finalResult);
      const orderPlacings = Object.values(finalResult).sort((a,b)=>a-b);
      const dancerDetails = Object.keys(dancers).reduce((a,v)=>{

          const {names, number,studio}=dancers[v];
          a[number]={names,studio};

          return a;
      },{});


      return (
          <table>
              <tbody>
              {orderPlacings.map(place=>{
                  return finalists.map(dan=>{
                      if(finalResult[dan]===place){
                          return (
                              <tr key={place+''+dan}>
                                  <td style={rowStyle} >{place}</td>
                                  <td style={rowStyle}><b>{dancerDetails[dan].names}</b></td>
                                  <td style={rowStyle}>{dancerDetails[dan].studio}</td>
                              </tr>
                          )
                      }
                  })
              })}
              </tbody>
          </table>
      )
    };

    render(){
        const {sectionData,eventDetails}=this.props;

        const {title,date,venue,organization,organizer,scrutineer,logo}=eventDetails.info;
        return (
            <div style={{padding:25}}>
                <GridContainer>
                    <GridItem xs={12}/>
                    <GridItem xs={12}>
                        <PrintEventHead
                            logo={logo.url}
                            title={title}
                            date={date}
                            venue={venue}
                            organizer={organizer}
                            organization={organization}
                            scrutineer={scrutineer}
                            sectionTitle={sectionData.title}
                        />
                    </GridItem>
                    <GridItem xs={12}>
                        {this.renderDancers()}
                    </GridItem>
                </GridContainer>
                <div  style={{position:'absolute',bottom:0}}>
                    <Footer/>
                </div>
            </div>
        )
    }


}
export default PrintMultiDanceFinalResult;