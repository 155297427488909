import React from 'react';
import {connect} from 'react-redux';
import {setStartList,setMarks,updateStartList,updateRound,closeSection, updateSectionDetails, addDancer, deleteDancer, updateDancer, addRound, deleteRound, setMultiDanceMarks} from "../../../../Store/Actions/Scrutineer/SectionActions";
import {List,Edit,AddAlert, AddBox,PlaylistAddCheck,GroupWork, Close, Info, People, DataUsage, CollectionsBookmark} from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";
//components
import GridContainer from "../../../../components/Grid/GridContainer";
import GridItem from "../../../../components/Grid/GridItem";
import Button from "../../../../components/CustomButtons/Button";
import Tabs from '../../../../components/CustomTabs/CustomTabs';
import history from "../../../../history";
import EditSectionModal from '../../../../components/Modals/EditSectionModal';
import Loading from "../../../../components/Loading";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import DancersModal from '../../../../components/Modals/DancersModal';
import Table from "../../../../components/Table/Table";
import Modal from "../../../../components/Modals/Modal";
import RoundModal from "../../../../components/Modals/RoundModal";
import ActiveDancersModal from "../../../../components/Modals/ActiveDancersModal";
import StartlistModal from "../../../../components/Modals/StartListModal";


class SectionView extends React.Component{

    state={
        sectionModal:false,
        loading:false,
        notif:false,
        notifMsg:'',
        updateError:'',
        dancersModal:false,
        dancerId:'',
        dancerInfo:null,
        dancerError:'',
        dancerModalHeading:'',
        deleteDancerModal:false,
        deleteDancerId:null,
        roundModal:false,
        roundError:'',
        roundHeading:'',
        deleteRound:false,
        deleteRoundId:null,
        roundData:null,
        roundEditId:null,
        startList:null,
        activeList:[],
        activeDancersModal:false,
        activeListModalHeading:'',
        activeListId:null,
        startListModal:false,
        startListRoundId:null,
        startListData:null
    };

    onCloseSection=()=>{
        this.props.closeSection(()=>{history.push('/scrutineer/Screens/sections')})
    };

    onDetailsSave=(formValues)=>{
       this.setState({loading:true});
       this.props.updateSectionDetails(formValues,res=>{
           res.status !==1? this.setState({loading:false, updateError:res.msg}):
               this.setState({
                   loading:false,
                   notif:true,
                   notifMsg:'Details updated',
                   sectionModal:false,
                   updateError:''
               })
       })
    };

    onDancerSave =(formValues)=>{
        this.setState({loading:true});
        if(this.state.dancerInfo === null){
            this.props.addDancer(formValues,(res)=>{
                res.status !== 1? this.setState({loading:false, dancerError:res.msg}):
                    this.setState({
                        loading:false,
                        dancerError:'',
                        dancerModalHeading:'',
                        dancersModal:false,
                        notif:true,
                        notifMsg:'Dancer Added',
                    })
            })
        }else{
           this.props.updateDancer(this.state.dancerId,formValues,(res)=>{
               res.status !== 1? this.setState({loading:false, dancerError:res.msg}):
                   this.setState({
                       loading:false,
                       dancerError:'',
                       dancerModalHeading:'',
                       dancersModal:false,
                       notif:true,
                       notifMsg:'Dancer Updated',
                   })
           })
        }

    };

    renderDancers=()=>{
      const {data} = this.props;
      if(data !== ''){
          if(data.dancers !== undefined){
                const sortableNumbers = Object.keys(data.dancers).reduce((a,v)=>{

                    const number = data.dancers[v].number;
                    const details = data.dancers[v];
                    details['id']=v;
                    a[number]=details;
                    return a
                },{});

                let tableData='';
                let tableHead='';

                if(data.type ==='competitive' || data.type==='multiDance'){
                     tableData = Object.keys(sortableNumbers).sort().reduce((a,v)=>{
                         const {number,names,studio,addedBy,timeStamp}=sortableNumbers[v];
                         const time = new Date(timeStamp);
                        a.push([
                            number,
                            names,
                            studio,
                            addedBy!== undefined? addedBy:'scrutineer',
                            timeStamp!==undefined? time.toLocaleTimeString()+' '+time.toDateString():'unknown',
                            (<div>
                                <Button onClick={()=>this.setState({deleteDancerId:sortableNumbers[v].id,deleteDancerModal:true})}   justIcon round size='sm' color={'danger'}><Close/></Button>
                                <Button onClick={()=>this.setState({dancerModalHeading:'Edit Dancer',dancersModal:true,dancerId:sortableNumbers[v].id,dancerInfo:sortableNumbers[v]})}  justIcon round size='sm' color={'info'}><Edit/></Button>
                            </div>)
                        ]);
                        return a;
                    },[]);
                     tableHead = ['Number','Name','Studio',"AddedBy","Time","Action"];
                }else if(data.type ==='social'){
                    tableData = Object.keys(sortableNumbers).sort().reduce((a,v)=>{
                        const {number,names,studio,heat,teacher,addedBy,timeStamp}= sortableNumbers[v];
                        const time = new Date(timeStamp);
                        a.push([
                            number,
                            names,
                            studio,
                            heat !== undefined? sortableNumbers[v].heat:'',
                            teacher !== undefined? sortableNumbers[v].teacher :'',
                            addedBy!== undefined? addedBy:'scrutineer',
                            timeStamp!==undefined? time.toLocaleTimeString()+' '+time.toDateString():'unknown',
                            (<div>
                                <Button onClick={()=>this.setState({deleteDancerId:sortableNumbers[v].id,deleteDancerModal:true})}   justIcon round size='sm' color={'danger'}><Close/></Button>
                                <Button onClick={()=>this.setState({dancerModalHeading:'Edit Dancer',dancersModal:true,dancerId:sortableNumbers[v].id,dancerInfo:sortableNumbers[v]})}  justIcon round size='sm' color={'info'}><Edit/></Button>
                            </div>)
                        ]);
                        return a;
                    },[]);
                     tableHead = ['Number','Name','Studio',"Heat","Teacher","AddedBy","Time","Action"];
                }else{

                }

                return (
                    <Table
                        tableHeaderColor='info'
                        tableHead={tableHead}
                        tableData={tableData}
                    />
                )
          }else{
              return(
                  <div>No dancers added yet!</div>
              )
          }
      }
    };

    confirmDancerDelete=()=>{
      this.setState({loading:true});
        this.props.deleteDancer(this.state.deleteDancerId,()=>this.setState({
            loading:false,
            deleteDancerModal:false,
            deleteDancerId:null,
            notif:true,
            notifMsg:'Dancer deleted Successfully',

        }))
    };

    renderRounds =()=>{

        if(this.props.data.rounds !== undefined){
            const {classes, id,}=this.props;
            const rounds = this.props.data.rounds;
            const sortedItem = Object.keys(rounds).reduce((a,v)=>{
                a.push(rounds[v].itemNo);
                return a
            },[]).sort();
            const tableData = Object.keys(rounds).reduce((a,v)=>{

                a.push(sortedItem.reduce((ar,val)=>{
                    if(val === rounds[v].itemNo ){

                        ar.push(
                            rounds[v].itemNo,
                            rounds[v].roundType,
                            rounds[v].roundNo,
                            rounds[v].dances.join(', '),
                            rounds[v].processed.toString(),
                            rounds[v].checked.toString(),
                            rounds[v].status,
                            (<div>
                                <Tooltip title="Delete Round" placement="top">
                                    <Button onClick={()=>this.setState({deleteRound:true, deleteRoundId:v})} round justIcon size='sm' color='danger'><Close/></Button>
                                </Tooltip>
                                <Tooltip title='Edit Round'  placement="top">
                                    <Button onClick={()=>this.setState({roundEditId:v,roundData:rounds[v],roundModal:true, roundHeading:'Edit Round'})} round justIcon size='sm' color='info'><Edit/></Button>
                                </Tooltip>
                                {rounds[v].marks === undefined?(
                                    <Tooltip title={'Set Start list'}>
                                        <Button
                                            onClick={()=>this.setState({startListModal:true, startListRoundId:v,startListData:rounds[v].startList!==undefined?rounds[v].startList:null})}
                                            round
                                            size='sm'
                                            justIcon
                                            color='warning'><List/></Button>
                                    </Tooltip>
                                ):null}

                                {rounds[v].startList !== undefined && rounds[v].marks === undefined ?
                                    <Tooltip title='Set Active Dancers'  placement="top" >
                                        <Button
                                            onClick={()=>this.setState({
                                                activeListId:v,
                                                startList:rounds[v].startList,
                                                activeList:rounds[v].activeList !== undefined ?rounds[v].activeList:[],
                                                activeDancersModal:true,
                                                activeListModalHeading:'Start List '+rounds[v].itemNo +'-'+rounds[v].roundType
                                            })}
                                            round
                                            justIcon
                                            size='sm'
                                            color='primary'>
                                            <GroupWork/>
                                        </Button>
                                    </Tooltip>:null
                                }
                                {rounds[v].marks !== undefined?(
                                    <Tooltip title='View Results' placement="top">
                                        <Button onClick={()=>this.viewMarks(id,v,rounds[v])}  round justIcon size='sm' color='success'><PlaylistAddCheck/></Button>
                                    </Tooltip>
                                ):''}

                            </div>)
                        )
                    }
                    return ar;
                },[]));
               return a;
            },[]);
            const tableHead = ['ItemNo', "Type","RoundNo","Dances","Processed","Checked","status","actions"];
            return(
                <Table
                    tableHead={tableHead}
                    tableData={tableData}
                    tableHeaderColor='info'
                />
            )
        }else{
            return(
                <div><h5>No Rounds added yet!</h5></div>
            )
        }
    };

    viewMarks=(sectionId, roundId,roundData)=>{
        this.props.setMarks(sectionId,roundId,roundData,()=>history.push('/scrutineer/Screens/results/marks'))
    };

    setStartList=(values)=>{
        this.setState({loading:true});
        this.props.setStartList(this.props.id,this.state.startListRoundId ,values,(res)=>{
            res.status === 1 ?
                (this.setState({
                    loading:false,
                    notif:true,
                    notifMsg:'Start list set successfully',
                    startListModal:false,
                    startListRoundId:null
                })):this.setState({loading:false})
        })
    };

    onRoundSave=(values)=>{

       this.setState({loading:true});
       if(this.state.roundData === null){
           this.props.addRound(values,()=>{
               this.setState({
                   loading:false,
                   notif:true,
                   notifMsg:'Round Added Successfully',
                   roundModal:false,
               })
           });
       }else{

           values['id']=this.state.roundEditId;
           this.props.updateRound(values,()=>{
               this.setState({
                   roundEditId:null,
                   roundData:null,
                   roundHeading:'',
                   loading:false,
                   notif:true,
                   notifMsg:'Round Updated Successfully',
                   roundModal:false,
               })
           })
       }

    };

    confirmDeleteRound =()=>{
        this.setState({loading:true});
        this.props.deleteRound(this.state.deleteRoundId,()=>{
            this.setState({
               loading:false,
               notif:true,
               notifMsg:'Round Deleted Successfully',
                deleteRound:false,
                deleteRoundId:null,
            })
        })
    };

    setActiveList=(values)=>{
        this.setState({loading:true});
        const data = {activeList: values,id:this.state.activeListId};
        const sectionId =this.props.id;
        this.props.updateStartList(data,sectionId,()=>{
            this.setState({
                loading:false,
                startList:null,
                activeList:[],
                activeDancersModal:false,
                activeListModalHeading:'',
                activeListId:null,
                notif:true,
                notifMsg:'Start list updated successfully'
            })
        })
    };

    handleCompileMarks=()=>{
        const data=this.props.data;
        const check = Object.keys(data.rounds).reduce((a,v)=>{

            const {processed,marks}=data.rounds[v];
            if(processed && marks!== undefined){
                a.push(v);
            }

            return a;
        },[]);

        if(check.length === Object.keys(data.rounds).length){
                const {id,data}=this.props;
                this.props.setMultiDanceMarks(id,data,()=>history.push('/scrutineer/Screens/results/marks'))

        }else{
            alert('All rounds have not been processed!')
        }


    };

    render(){
        const {classes}=this.props;
        const {startList,startListData,startListModal}=this.state;
        return(
            <div>
                <StartlistModal
                    startListData={startListData}
                    onSave={this.setStartList}
                    isVisible={startListModal}
                    onClose={()=>this.setState({startListModal:false})}
                />
                <ActiveDancersModal
                    startList={this.state.startList}
                    activeList={this.state.activeList}
                    heading={this.state.activeListModalHeading}
                    isVisible={this.state.activeDancersModal}
                    onClose={()=>this.setState({activeListId:null,activeListModalHeading:'',startList:null, activeList:[], activeDancersModal:false})}
                    onSave={this.setActiveList}
                />
                <RoundModal
                    errorMessage={this.state.roundError}
                    heading={this.state.roundHeading}
                    isVisible={this.state.roundModal}
                    onClose={()=>this.setState({roundEditId:null, roundModal:false, roundData:null, roundHeading:''})}
                    onSave={this.onRoundSave}
                    roundData={this.state.roundData}
                    sectionData={this.props.data}
                />
                <Loading
                show={this.state.loading}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <EditSectionModal
                    sectionData={this.props.data}
                    errorMessage={this.state.updateError}
                    heading={'Edit Section'}
                    isVisible={this.state.sectionModal}
                    onClose={()=>this.setState({sectionModal:false})}
                    onSave={this.onDetailsSave}
                />
                <Modal
                    errorMessag={'Are you sure you want to delete this dancer?'}
                    heading={'Delete Dancer'}
                    isVisible={this.state.deleteDancerModal}
                    onClose={()=>this.setState({deleteDancerModal:false, deleteDancerId:null})}
                    onSave={this.confirmDancerDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this dancer?</p>}
                />
                <Modal
                    errorMessag={'Are you sure you want to delete this round?'}
                    heading={'Delete Round'}
                    isVisible={this.state.deleteRound}
                    onClose={()=>this.setState({deleteRound:false, deleteRoundId:null})}
                    onSave={this.confirmDeleteRound}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this round?</p>}
                />
                <DancersModal
                    sectionData={this.props.data}
                    dancersData={this.state.dancerInfo}
                    errorMessage={this.state.dancerError}
                    heading={this.state.dancerModalHeading}
                    isVisible={this.state.dancersModal}
                    onClose={()=>this.setState({ dancerId:'', dancerInfo:null, dancersModal:false, dancerModalHeading:''})}
                    onSave={this.onDancerSave}
                />
                <GridContainer>
                    <GridItem xs={12} md={6}>
                     <h3>{this.props.data.title}</h3>
                    </GridItem>
                    <GridItem xs={12} md={6}>
                        <div style={{textAlign:'right', justifyContent:'center'}}>
                            <Button onClick={()=>this.onCloseSection()} color={"info"} simple round size={'sm'}><Close/>Close Section</Button>
                        </div>
                    </GridItem>
                </GridContainer>
                <GridContainer>
                    <GridItem xs={12}>
                        <Tabs
                            headerColor={'info'}
                            tabs={[
                                {
                                    tabName:'Details',
                                    tabIcon:Info,
                                    tabContent:(
                                        <div>
                                            <GridContainer>
                                                <GridItem xs={2}>
                                                    Title:
                                                </GridItem>
                                                <GridItem xs={10}>
                                                    {this.props.data.title}
                                                </GridItem>
                                                <GridItem xs={2}>
                                                    Round No:
                                                </GridItem>
                                                <GridItem xs={10}>
                                                    {this.props.data.roundNo}
                                                </GridItem>
                                                <GridItem xs={2}>
                                                    Type:
                                                </GridItem>
                                                <GridItem xs={10}>
                                                    {this.props.data.type}
                                                </GridItem>
                                                <GridItem xs={2}>
                                                    Next Round:
                                                </GridItem>
                                                <GridItem xs={10}>
                                                    {this.props.data.nextRound}
                                                </GridItem>
                                            </GridContainer>
                                            <div style={{textAlign:'right'}}>
                                                <Button onClick={()=>this.setState({sectionModal:true})}  size='sm' round><Edit/>Edit Details</Button>
                                            </div>
                                        </div>
                                    )
                                },
                                {
                                    tabName:'Dancers',
                                    tabIcon:People,
                                    tabContent:(
                                        <div>
                                            <div style={{textAlign:'right'}}>
                                                <Button onClick={()=>this.setState({dancersModal:true, dancerModalHeading:'Add New Dancer'})} color='success' round size='sm'><AddBox/>Add Dancer(s)</Button>
                                            </div>
                                            {this.renderDancers()}
                                        </div>
                                    )
                                },
                                {
                                    tabName:'Rounds',
                                    tabIcon:DataUsage,
                                    tabContent:(
                                        <div>
                                            <Button onClick={()=>this.setState({roundModal:true, roundHeading:'Add New Round'})} color='success' round size='sm'><AddBox/>Add Round</Button>
                                            {this.props.data.type==='multiDance'?
                                                <Button onClick={()=>this.handleCompileMarks()} color={'info'} size={"sm"} round><CollectionsBookmark/>Compile Marks</Button>:null
                                            }
                                            {this.renderRounds()}
                                        </div>
                                    )
                                }
                            ]}
                        />

                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const mapStateToProps=state=>{
  let Data = '';
  let id ='';
  if(state.section.data  !== null){
      Data=state.section.data;
      id=state.section.id
  }
  return {data:Data, id}
};


export default connect(mapStateToProps,{
    updateDancer,
    closeSection,
    updateSectionDetails,
    addDancer,
    deleteDancer,
    addRound,
    deleteRound,
    updateRound,
    updateStartList,
    setMarks,
    setStartList,
    setMultiDanceMarks
})(SectionView);

