import {SET_MARKS,REMOVE_SECTION, SET_MULTI_MARKS} from "../types";

const INITIAL_STATE ={
    sectionId:null,
    roundId:null,
    roundData:null,
    sectionData:null,
    multiComplete:false,
};

export default (state=INITIAL_STATE,action)=>{
    switch (action.type) {
        case SET_MARKS:
            const {sectionId, roundId, roundData}=action.payload;
            return {...state, sectionId, roundId, roundData };
        case SET_MULTI_MARKS:
            const {sectionData}=action.payload;
            return {...state,sectionId:action.payload.sectionId,sectionData,multiComplete: true};
        case REMOVE_SECTION:
            return {
                sectionId:null,
                roundId:null,
                roundData:null,
                sectionData:null,
                multiComplete:false,
            };

        default:
            return {...state}
    }
};