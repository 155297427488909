import React from 'react';
import {withStyles} from "@material-ui/core";
import modalStyle from "../assets/jss/material-kit-react/modalStyle";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Transition from "../Functions/Transistion";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Danger from "../components/Typography/Danger";
import CustomInput from "../components/CustomInput/CustomInput";
import Button from "../components/CustomButtons/Button";
import Success from "../components/Typography/Success";

class ManualMarks extends React.Component{

    state={
        error:'',
        dance:'',
        adj:'',
        marks:{},
        success:'',
        selectedDancers:[],
        sectionSet:false,
        number:'',
        T:{},
        C:{},
        I:{},
        S:{},
        total:{},
        place:{},
        specialMarks:{}
    };
    onClose=()=>{

        const roundData = this.props.roundData;
        roundData.activeList.map(dancer=>{
            delete this.state[dancer];
        });

        this.setState({
            selectedDancers:[],
            success:'',
            number:'',
            error:'',
            dance:'',
            adj:'',
            marks:{},
            sectionSet:false});
      this.props.onClose();
    };

    onSave=()=>{
        const {marks, specialMarks}=this.state;
        const sectionType=this.props.sectionData.type;
        const panelArr = this.props.roundData.panel.letters.split(',');
        if(Object.keys(marks).length === panelArr.length){
            if(this.props.roundData.roundType === 'Final' ||this.props.roundData.roundType === 'Uncontested'||this.props.roundData.roundType === 'Contested'){
                const check = Object.keys(marks).reduce((a,v)=>{
                    if(Object.keys(marks[v]).length === this.props.roundData.dances.length ){
                        a.push(1);
                    }
                    return a;
                },[]);
                if(check.length === panelArr.length){
                    this.props.onSave(sectionType,marks,specialMarks);
                }else{
                    this.setState({error:'Not all marks have been entered'})
                }
            }
            else{
               const check = Object.keys(marks).reduce((a,v)=>{
                    if(Object.keys(marks[v]).length === this.props.roundData.dances.length){
                             a.push(1);
                    }
                    return a;
                },[]);
               check.length === panelArr.length ? (
                   this.props.onSave(sectionType,marks)


               ) :this.setState({error:"All marks have not been entered!"})
            }

        }else{
            this.setState({error:'All marks have not been entered!'})
        }
    };
    componentDidUpdate=()=>{
        if(Object.keys(this.state.marks).length === 0 && !this.state.sectionSet && this.props.roundData.panel !== undefined ){
            const roundData = this.props.roundData;
            this.setState({
                number:'',
                selectedDances:[],
                success:'',
                sectionSet:true,
                adj:roundData.panel.letters.split(',')[0],
                dance:roundData.dances[0]})
        }
    };

    onSelectedDancer =(number)=>{
       const selectedDancers = this.state.selectedDancers;
       if(selectedDancers.includes(number)){
           //remove
            const newArray = selectedDancers.filter(val=>{
                if(val !== number){
                    return val
                }
            });
            this.setState({error:'',selectedDancers:newArray })
       }else{
           //add
           if(selectedDancers.length < this.props.roundData.callbackLimit){
               selectedDancers.push(number);
               this.setState({selectedDancers, error:''});
           }else{
               this.setState({error:'Call back limit reached!'})
           }

       }

    };

    onSubmitNumber=()=>{
        const {number, selectedDancers} = this.state;
        if(number.length !==''){
            if(this.props.roundData.activeList.includes(number)){
                if(!selectedDancers.includes(number)){
                    selectedDancers.push(number);
                    this.setState({selectedDancers, number:'',error:''});
                }else{
                    this.setState({number:'',error:''});
                }
            }else{
                this.setState({error:'Number does not exist!'});
            }
        }
    };

    onSaveDance=()=>{
      const {selectedDancers,number, adj,dance, marks}=this.state;
      const roundData = this.props.roundData;
      if(selectedDancers.length>0){
          // todo- load the marks with info
         if(marks[adj] === undefined){
             marks[adj]={[dance]:selectedDancers};
         }else{
             marks[adj][dance]=selectedDancers;
         }
         //check if adj has all the dances
         if(Object.keys(marks[adj]).length !== roundData.dances.length ){
             // save marks reset dancers and change dance
             const nextDance = roundData.dances[Object.keys(marks[adj]).length];
             this.setState({
                 marks,
                 selectedDancers: [],
                 dance:nextDance
             })
         }else {
             //save marks, reset dancers change dance back to first and change adj to next
             if(Object.keys(marks).length !== roundData.panel.letters.split(',').length){
                 const nextAdj = roundData.panel.letters.split(',')[Object.keys(marks).length];
                 this.setState({
                     marks,
                     selectedDancers: [],
                     dance:roundData.dances[0],
                     adj:nextAdj
                 })
             }else{
                 //console.log(marks);
                 alert('All marks entered please click save all!');
                 this.setState({
                     marks,
                     selectedDancers: [],
                     success:'All marks entered please click save all!'
                 })

             }
         }


      }else{
          this.setState({error:'No Dancers have been selected!'})
      }
    };

    onClear=()=>{
        const roundData = this.props.roundData;
        roundData.activeList.map(dancer=>{
            delete this.state[dancer];
        });
    };

    renderDancers=()=>{
      if(this.props.roundData!==''){
          const {roundType, activeList,dances} = this.props.roundData;
          if(roundType === 'Final' || roundType==='Uncontested' || roundType==='Contested'){
              const {dance}=this.state;
              if(dance !=='exhibition'&&
                  dance !=='showcase' &&
                  dance !== 'showdance'&&
                  dance !== 'solo spot'){
                  return (
                      <GridContainer>
                          {activeList.map(dancer=>{

                              return (
                                  <GridItem xs={activeList.length ===1?12:1} key={dancer}>
                                      <CustomInput
                                          value={this.state[dancer]}
                                          labelText={dancer}
                                          id={dancer}
                                          formControlProps={{
                                              fullWidth: false
                                          }}
                                          inputProps={{
                                              autoComplete: 'off',
                                              maxLength: 1,
                                              value: (this.state[dancer] !== undefined ? this.state[dancer] : ''),
                                              onChange: (this.handleChange(dancer)),
                                          }}
                                      />
                                  </GridItem>
                              )
                          })}
                          <GridItem xs={12}>
                              <Button onClick={()=>this.saveMarks()} round  size='sm' color='success'>Save</Button>
                              <Button onClick={()=>this.onClear()} round size='sm' color='primary'>Clear</Button>
                          </GridItem>
                      </GridContainer>
                  );
              }
              else{
                  const {T,C,I,S,total,place}=this.state;
                  return (
                      <div>
                          <table>
                              <thead>
                              <tr>
                                  <th>Number</th>
                                  <th>T</th>
                                  <th>C</th>
                                  <th>I</th>
                                  <th>S</th>
                                  <th>Total</th>
                                  <th>{roundType==='Uncontested'?'%':'Place'}</th>
                              </tr>
                              </thead>
                              <tbody>
                              {activeList.map(dancer=>{
                                  return (
                                      <tr key={dancer}>
                                          <td>{dancer}</td>
                                          <td>
                                              <CustomInput
                                                  value={T[dancer] !== undefined ? T[dancer] : ''}
                                                  labelText={'T'}
                                                  id={'T'+[dancer]}
                                                  formControlProps={{
                                                      fullWidth: false
                                                  }}
                                                  name={'T'}
                                                  inputProps={{
                                                      name:'T',
                                                      autoComplete: 'off',
                                                      maxLength: 1,
                                                      value: (T[dancer] !== undefined ? T[dancer] : ''),
                                                      onChange: (this.handleChangeSpecial([dancer,'T'],)),
                                                  }}
                                              />
                                          </td>
                                          <td>
                                              <CustomInput
                                                  value={C[dancer] !== undefined ? C[dancer] : ''}
                                                  labelText={'C'}
                                                  id={dancer}
                                                  formControlProps={{
                                                      fullWidth: false
                                                  }}
                                                  name={'C'}
                                                  inputProps={{
                                                      name:"C",
                                                      autoComplete: 'off',
                                                      maxLength: 1,
                                                      value: (C[dancer] !== undefined ? C[dancer] : ''),
                                                      onChange: (this.handleChangeSpecial([dancer,'C'])),
                                                  }}
                                              />
                                          </td>
                                          <td>
                                              <CustomInput
                                                  value={I[dancer] !== undefined ? I[dancer] : ''}
                                                  labelText={'I'}
                                                  id={dancer}
                                                  formControlProps={{
                                                      fullWidth: false
                                                  }}
                                                  name={'I'}
                                                  inputProps={{
                                                      name:"I",
                                                      autoComplete: 'off',
                                                      maxLength: 1,
                                                      value: (I[dancer] !== undefined ? I[dancer] : ''),
                                                      onChange: (this.handleChangeSpecial([dancer,'I'])),
                                                  }}
                                              />
                                          </td>
                                          <td>
                                              <CustomInput
                                                  value={S[dancer] !== undefined ? S[dancer] : ''}
                                                  labelText={"S"}
                                                  id={dancer}
                                                  formControlProps={{
                                                      fullWidth: false
                                                  }}
                                                  name={'S'}
                                                  inputProps={{
                                                      name:"S",
                                                      autoComplete: 'off',
                                                      maxLength: 1,
                                                      value: (S[dancer] !== undefined ? S[dancer] : ''),
                                                      onChange: (this.handleChangeSpecial([dancer,'S'])),
                                                  }}
                                              />
                                          </td>
                                          <td>
                                              {total[dancer]}
                                          </td>
                                          <td>{place[dancer]}{roundType==='Uncontested'?'%':''}</td>
                                      </tr>
                                  )


                              })}
                              </tbody>
                          </table>
                          <Button onClick={()=>this.saveMarks()} round size='sm' color='success' >Save Marks</Button>
                      </div>
                  )
              }

          }
          // normal round
          else{
              return (
                  <GridContainer>
                      <GridItem xs={12}>
                          <GridContainer>
                              <GridItem xs={4}>
                                  {activeList.map(dancer=>{
                                      return (
                                          <Button
                                              key={dancer}
                                              onClick={()=>this.onSelectedDancer(dancer)}
                                              round
                                              size='sm'
                                              block
                                              color={this.state.selectedDancers.includes(dancer)?'rose':'info'}
                                          >
                                              {dancer}
                                          </Button>
                                      )
                                  })}
                              </GridItem>
                              <GridItem xs={8}>
                                      <GridContainer>
                                          <GridItem xs={6}>
                                          </GridItem>
                                          <GridItem xs={6}>
                                              <form onSubmit={e => { e.preventDefault(); }}>
                                                  <CustomInput
                                                      style={{justifyContent:'flex-start'}}
                                                      value={this.state.number}
                                                      labelText={'Number'}
                                                      id={'number'}
                                                      formControlProps={{
                                                          fullWidth: false
                                                      }}
                                                      inputProps={{
                                                          autoComplete:'off',
                                                          maxLength:1,
                                                          value:this.state.number,
                                                          onChange:(this.handleChange('number')),
                                                      }}
                                                  />
                                                  <Button block style={{bottom:0}} onClick={()=>this.onSubmitNumber()} type={'submit'} color='info' size='sm' round>OK</Button>
                                              </form>
                                              <Button block onClick={()=>this.onSaveDance()} type={'button'} color='rose' size='sm' round>Save Dance</Button>
                                          </GridItem>
                                      </GridContainer>
                              </GridItem>
                          </GridContainer>
                      </GridItem>
                  </GridContainer>
              )
          }

      }
    };

    handleChangeSpecial=name=>event=>{
        const {roundType, activeList}=this.props.roundData;
        const dancer = name[0];
        const cat = name[1];
        const value = event.target.value;
        const {total,T,C,I,S, place}=this.state;
        if(!isNaN(value)){

            if(value < 10){
                if(value !==0){

                    const markingCat = this.state[cat];
                    markingCat[dancer]=parseInt(value);
                    const Tscore = T[dancer]!== undefined ?T[dancer]:0;
                    const Cscore = C[dancer]!== undefined?C[dancer]:0;
                    const Iscore =I[dancer]!== undefined?I[dancer]:0;
                    const Sscore=S[dancer]!==undefined?S[dancer]:0;

                    total[dancer]=Tscore+Cscore+Iscore+Sscore;
                    const Keys = Object.keys(total);
                    let placing = '';
                    if(roundType !== 'Uncontested'){
                        placing = Object.values(total).sort((a,b)=>b-a).reduce((a,v,i,b)=>{
                            const next=i+1;
                            if(b[i]!== b[next]){
                                Keys.map(key=>{
                                    if(total[key]=== v){
                                        return a[key]=i+1
                                    }
                                });
                            }
                            return a
                        },{});
                    }else{
                        const percent = (Tscore+Cscore+Iscore+Sscore)/40*100;
                        const dancerPercent=Math.ceil(percent);
                        placing = activeList.reduce((a,v)=>{

                            if(dancer === v){
                                a[v]=dancerPercent
                            }else{
                                if(place[v]){
                                  a[v]=place[v]
                                }

                            }

                            return a;
                        },{});
                    }

                    this.setState({
                        [cat]:markingCat,
                        total,
                        place:placing,
                        error:''
                    });
                }else{
                    this.setState({error:'Number cannot equal 0'})
                }


            }else{
                this.setState({error:'Categories are only out of 10!'})
            }



        }else{
            this.setState({error:'Value is not a number please check your input!'})
        }

    };


    handleChange = name => event => {
        const value = event.target.value;
        const {roundType,activeList}=this.props.roundData;
        if(!isNaN(value)){
            if(roundType === 'Final' || roundType === 'Contested' ){
                if(value.length >1){
                    this.setState({error:'Number too high'})
                }else if(value > activeList.length){
                    this.setState({error:'Number higher than dancers '});
                }else{
                    if(!Object.values(this.state).includes(value)){
                        this.setState({[name]:value, error:''})
                    }else{
                        this.setState({error:"Dancers can't have the same mark!"});
                    }

                }
            }else if(roundType==='Uncontested'){
                if(value.length>1){
                    this.setState({error:'Number too high'})
                }else if(value >3){
                    this.setState({error:'Only placing between 1 and 3 are accepted for an uncontested round!'})
                }else{
                    if(value === 0){
                        this.setState({error:'Placing cant be 0!'})
                    }else{
                        this.setState({[name]:value, error:''});
                    }
                }

            }else{
                this.setState({number:value});
            }
        }else {
            this.setState({error:'Value is not a number please check your input!'})
        }


    };

    saveMarks=()=>{
        const {marks, dance, adj,T,C,I,S,total,place, specialMarks}=this.state;
        const roundData = this.props.roundData;
        const {roundType,activeList,dances}=this.props.roundData;

            if(roundData.roundType ==='Final' || roundData.roundType ==='Uncontested' ||roundData.roundType ==='Contested'){
                if(dance !=='exhibition'&& dance !=='showcase' && dance !== 'showdance'&& dance !== 'solo spot'){
                    const scores = roundData.activeList.reduce((a,v,i)=>{
                        if(roundData.activeList.includes(Object.keys(this.state)[i])){
                            a[v]=this.state[v]
                        }
                        return a;
                    },{});
                    if(Object.keys(scores).length === roundData.activeList.length){
                        if(marks[adj] === undefined ){
                            marks[adj]={[dance]:scores};
                        }else{
                            marks[adj][dance]=scores;
                        }
                        //check if all dances from adj have marks entered, if not set for next round
                        if(Object.keys(marks[adj]).length !== roundData.dances.length ){
                            const danceNum =Object.keys(marks[adj]).length;
                            roundData.activeList.map(dancer=>{
                                delete this.state[dancer];
                            });

                            this.setState({
                                error:'',
                                marks,
                                dance:roundData.dances[danceNum],

                            });

                            //check if all judges have marks entered, if not then set for next round
                        }else if(Object.keys(marks).length !== roundData.panel.letters.split(',').length){
                            const nextJudge = roundData.panel.letters.split(',')[Object.keys(marks).length];
                            if(nextJudge !== undefined){
                                roundData.activeList.map(dancer=>{
                                    delete this.state[dancer];
                                });
                                this.setState({
                                    error:'',
                                    marks,
                                    dance:roundData.dances[0],
                                    adj:nextJudge,
                                });


                            }

                        }else{
                            alert('All Marks entered, click save all!');
                            this.setState({success:"All Marks entered, click save all!"})
                        }

                    }else{
                        this.setState({error:'Not all dancers have marks!'})
                    }
                }
                else {
                    // handle special round
                    const dancersLength = activeList.length;
                    const placeCheck = [...new Set(Object.values(place))];
                    let error = false;

                    Object.keys(T).length === dancersLength ?
                        Object.keys(C).length === dancersLength ?
                            Object.keys(I).length === dancersLength ?
                                Object.keys(S).length === dancersLength ?
                                    roundType !=='Uncontested'?
                                        placeCheck.length === activeList.length?
                                            error = true
                                            :this.setState({error:'Dancers cannot be tied'})
                                        :error=true
                                    : this.setState({error: 'Check all dancers have a mark for Show'})
                                : this.setState({error: 'Check all dancers have a mark for Image'})
                            : this.setState({error: 'Check all dancers have a mark for Choreography'})
                        : this.setState({error: 'Check all dancers have a mark for Technique'});

                    if (error) {
                        if (marks[adj] === undefined) {
                            marks[adj] = {[dance]: place};
                        } else {
                            marks[adj][dance] = place;
                        }

                        if (specialMarks[adj] === undefined) {
                            specialMarks[adj] = {[dance]: {T, C, I, S, total}}
                        } else {
                            specialMarks[adj][dance] = {T, C, I, S, total}
                        }


                        if (Object.keys(marks[adj]).length !== roundData.dances.length) {
                            const danceNum = Object.keys(marks[adj]).length;
                            this.setState({
                                error: '',
                                T: {},
                                C: {},
                                I: {},
                                S: {},
                                total: {},
                                place: {},
                                marks,
                                dance: roundData.dances[danceNum],
                                specialMarks
                            })
                        } else if (Object.keys(marks).length !== roundData.panel.letters.split(',').length) {
                            const nextJudge = roundData.panel.letters.split(',')[Object.keys(marks).length];
                            if (nextJudge !== undefined) {
                                roundData.activeList.map(dancer => {
                                    delete this.state[dancer];
                                });
                                this.setState({
                                    T: {},
                                    C: {},
                                    I: {},
                                    S: {},
                                    total: {},
                                    place: {},
                                    error: '',
                                    marks,
                                    dance: roundData.dances[0],
                                    adj: nextJudge,
                                });
                            }
                        } else {
                            alert('All Marks entered, click save all!');
                            this.setState({success: "All Marks entered, click save all!"})
                        }
                    }
                }

            }
    };


    render(){
        const {classes} = this.props;
        return (
            <div>

                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <GridContainer>
                            <GridItem xs={6}>
                                <h4 className={classes.modalTitle}>Manual Marking</h4>
                                <Danger>{this.state.error}</Danger>
                                <Success>{this.state.success}</Success>
                            </GridItem>
                            <GridItem xs={6}>
                                <h6 className={classes.modalTitle}>{this.props.sectionData.title}</h6>
                                <p>Dance: <b>{this.state.dance}</b>        Adjudicator: <b>{this.state.adj}</b></p>
                                <p>Round Type: <b>{this.props.roundData.roundType}</b></p>
                            </GridItem>
                            {this.props.roundData.roundType !=='Final' && this.props.roundData.roundType !=='Uncontested'&& this.props.roundData.roundType !=='Contested'?
                                <GridItem xs={12}>
                                    <GridContainer>
                                        <GridItem xs={4}>
                                            <h5>Selected Dancers</h5>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <h6>Callback limit: {this.props.roundData.callbackLimit}</h6>
                                        </GridItem>
                                        <GridItem xs={4}>
                                            <h6><Success>Callback left: {(this.props.roundData.callbackLimit - this.state.selectedDancers.length)}</Success></h6>
                                        </GridItem>
                                    </GridContainer>
                                    {this.state.selectedDancers.join('  ')}
                                </GridItem>: ''
                            }

                        </GridContainer>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        {this.renderDancers()}
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='button'
                            color={'success'}>
                            Save All
                        </Button>
                    </DialogActions>
                </Dialog>

            </div>
        )
    }
}
export default withStyles(modalStyle)(ManualMarks);