import React from 'react';
import {connect} from 'react-redux';
import {InputAdornment} from "@material-ui/core";
import Search from "@material-ui/icons/Search";

// components
import Card from "../../../components/Card/Card";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Table from "../../../components/Table/Table";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInput";

class EventSections extends React.Component{

    state={
        search:''
    };

    renderSections=()=>{
      if(this.props.sections !== null){
          const sections = this.props.sections;
          const search = this.state.search.toLocaleLowerCase();
          const tableData = Object.keys(sections).reduce((a,v)=>{
                const section = sections[v];
                let dancerAmount ='';
                let roundAmount = '';

                const {title, roundNo,nextRound,rounds, dancers, type}=section;

                if(dancers !== undefined){
                     dancerAmount =  Object.keys(sections[v].dancers).length;
                }
                if(rounds !== undefined){
                    roundAmount = Object.keys(sections[v].rounds).length
                }

                if(title?.toLocaleLowerCase().includes(search) || type.toLocaleLowerCase().includes(search)){
                    a.push([
                        title,
                        roundNo,
                        nextRound,
                        roundAmount,
                        dancerAmount,
                        type
                    ]);
                }




              return a;
          },[]);
          const tableHead = ['Title','RoundNo','Next Round', 'No. of rounds', 'No of Dancers','Section Type'];
          return (
              <Table
                tableHead={tableHead}
                tableData={tableData}
                tableHeaderColor={'rose'}
              />
          )
      }
    };

    handleChange = name => event => {
        this.setState({[name]:event.target.value})
    };

    render(){
        return (
            <div>
                <Card>
                    <CardHeader color={'rose'}>
                        <h4>Sections</h4>
                    </CardHeader>
                    <CardBody>
                        <GridContainer>
                            <GridItem xs={12} md={8}/>
                            <GridItem xs={12} md={4}>
                                <CustomInput
                                    labelText="Search"
                                    id="Search"
                                    formControlProps={{
                                        fullWidth: true
                                    }}
                                    inputProps={{
                                        onChange:(this.handleChange('search')),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Search/>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </GridItem>
                        </GridContainer>
                        {this.renderSections()}
                    </CardBody>
                </Card>
            </div>
        )
    }
}
const mapStateToProps = state=>{
    return {sections:state.eventData.sections}
};


export default connect(mapStateToProps)(EventSections);
