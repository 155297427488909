import {CLEAR_ALL_EVENTS, LOADED_EVENTS} from "../types";

const INITIAL_STATE={
    events:null
};


export default (state=INITIAL_STATE, action)=>{
    switch(action.type){
        case LOADED_EVENTS:
            return {events:action.payload};
        case CLEAR_ALL_EVENTS:
            return {events: null};
        default:return {...state}
    }
}