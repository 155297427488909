import React from 'react';
import {connect}from 'react-redux';
import Dashboard from "../../../components/Dashboard";

class ScrutinDashboard extends React.Component{

    render() {
        const { classes, ...rest } = this.props;
        return(
            <div>
                <Dashboard
                    eventData={this.props.eventData}
                />
            </div>
        )
    }
}

const mapStateToProps=state=>{
    return {
        eventData:state.eventData,
    }
};
export default connect(mapStateToProps)(ScrutinDashboard);