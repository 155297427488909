import React from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import {TextField} from "@material-ui/core";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import Danger from "../Typography/Danger";
//functions
import Transition from "../../Functions/Transistion";

class NotesModal extends React.Component{
    state={
        note:'',
        error:'',
        editMode:false
    };

    editModeSet=()=>{
        if(!this.state.editMode && this.props.noteData !== ''){
            this.setState({note:this.props.noteData, editMode:true})
        }
    };

    onSave=()=>{
        this.state.note ===''?
            this.setState({error:'Note cannot be left blank!'}):
            this.props.onSave(this.state.note);
    };
    onClose=()=>{
        this.setState({note:'', error:'', editMode:false});
        this.props.onClose();
    };

    onChange=(name)=>(event)=>{
        this.setState({note:event.target.value})
    };

    render(){
        //console.log(this.state.note);
        const {classes}=this.props;
        this.editModeSet();
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={()=>this.onClose()}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">

                    <DialogTitle
                        id="classic-modal-slide-title"
                        disableTypography
                        className={classes.modalHeader}>
                        <IconButton
                            className={classes.modalCloseButton}
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            onClick={this.props.onClose}>
                            <Close className={classes.modalClose} />
                        </IconButton>
                        <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                    </DialogTitle>
                    <DialogContent
                        id="modal-slide-description"
                        className={classes.modalBody}>
                        <GridContainer>
                            <GridItem xs={12} md={12}>
                                <Danger>{this.state.error}</Danger>
                                <Danger>{this.props.error}</Danger>
                                <TextField
                                    style={{width:500}}
                                    id="note"
                                    label="Note"
                                    multiline
                                    rows="4"
                                    onChange={this.onChange('note')}
                                    value={this.state.note}
                                    margin="normal"
                                    variant="outlined"
                                />
                            </GridItem>
                        </GridContainer>
                    </DialogContent>
                    <DialogActions
                        className={classes.modalFooter +" " +classes.modalFooterCenter}>
                        <Button
                            type='button'
                            onClick={()=>this.onClose()}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={()=>this.onSave()}
                            type='button'
                            color='success'>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}
export default withStyles(modalStyle)(NotesModal)

