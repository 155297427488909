import React from "react";
import {DancersFunction, SocialDancerFunction} from "../functions";
import GridContainer from "../../../../../components/Grid/GridContainer";
import GridItem from "../../../../../components/Grid/GridItem";
import {marksTableFinal} from "../../../../../Marks/Alogrithms/finalAlgo";
import {unContestedTable, UncontestFinalSpecialTable} from "../../../../../Marks/Alogrithms/uncontestedAlgo";
import {SpecialDanceTable} from "../../../../../Marks/Functions";
import {DSDivider} from "../../../../../components/Divider";

export const SocialViewAllMarls =(props)=>{

    const {title,roundType,roundData,itemNo,dancers}=props.data;
    const {dances, specialMarks,results}=roundData;

    const Dancers = SocialDancerFunction(dancers);



    const renderMarks=(roundData)=>{
        const {roundType,dances}=roundData;
        if(roundType==='Contested'){
            // treat as normal final
            return marksTableFinal(roundData);
        }else if(roundType==='Uncontested'){
            if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
                return UncontestFinalSpecialTable(roundData);
            }else{
                return unContestedTable(roundData)
            }
        }
    };

    const renderSpecialMarks=(specialMarks)=>{
        if(specialMarks!== undefined){
            return (SpecialDanceTable(specialMarks));
        }
    };


    return (
        <div>
            <h5>{itemNo+' '+title+'-'+roundType}</h5>
            <GridContainer>
                <GridItem xs={12}>
                    {renderMarks(roundData)}
                </GridItem>
                <GridItem xs={12}>
                    {
                        dances.includes('exhibition') ||
                        dances.includes('showcase')||
                        dances.includes('showdance')||
                        dances.includes('solo spot') ?
                            <GridItem xs={12}>
                                <h3>TCIS Marks</h3>
                                <p>T-Technique C-Choreography I-Image S-Show </p>
                                {renderSpecialMarks(specialMarks)}
                            </GridItem>:null
                    }
                </GridItem>
                <GridItem xs={12}>
                    <h5>Final Result</h5>
                    <div>
                        {
                            Object.values(results).sort((a,b)=>a-b).map(place=>{

                                return  Object.keys(results).map(dancer=>{
                                    if(place===results[dancer]){
                                        return (
                                            <p>
                                                {place+"  "}<b>{dancer}</b>   {Dancers[dancer].names+"   "+Dancers[dancer].studio+"   "+Dancers[dancer].teacher}
                                            </p>
                                        )
                                    }
                                })

                            })
                        }
                    </div>
                </GridItem>
            </GridContainer>
            <DSDivider/>
        </div>
    )


};