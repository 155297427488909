import React from 'react';
import {connect}from 'react-redux';
import {reduxForm, Field} from "redux-form";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import Close from "@material-ui/core/SvgIcon/SvgIcon";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../../components/CustomButtons/Button";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import RenderInputs from "../../Functions/RenderInputs";
import Transition from "../../Functions/Transistion";


class MarshalModal extends React.Component{

    onSubmit=formValues=>{
        this.props.onSave(formValues)
    };
    render() {
        const {classes} = this.props;
        return(
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={this.props.isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={this.props.onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={this.props.onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{this.props.heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <Field id='name' type={'text'} name='name' label='Marshall Name' component={RenderInputs} />
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={this.props.onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }
}
const validate =formValues=>{
    const errors = {};

    if(formValues.marshall){
        formValues.marshall = "Please ensure the marshall's name is not empty!"
    }

    return errors;
};

const form =reduxForm({
    form:'marshallForm',
    validate,
    enableReinitialize:true
})(MarshalModal);

const mapStateToProps =state=>{
    return {
        initialValues:state.eventData.info.marshall
    }
};

export default connect(mapStateToProps)(withStyles(modalStyle)(form));