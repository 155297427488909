import React from "react";
import history from "../../history";
import {connect} from 'react-redux';
import {logUserOut} from "../../Store/Actions/Auth";
import {clearEvent} from "../../Store/Actions/Scrutineer/EventActions";
import {Tooltip} from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Hidden from "@material-ui/core/Hidden";
// @material-ui/icons
import {ExitToApp,Close} from '@material-ui/icons';
// core components
import Button from "../CustomButtons/Button.js";
import headerLinksStyle from "../../assets/jss/material-dashboard-react/components/headerLinksStyle.js";
class HeaderLinks extends React.Component {

    navigation=(path)=>{
        path ==='signOut'? this.props.logUserOut(()=>history.push('/')):
            this.props.clearEvent(()=>history.push(`/${this.props.role}`))
    };

  render() {
    const { classes } = this.props;

    return (
      <div>
          <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-label="close"
              className={classes.buttonLink}
              onClick={()=>this.navigation('signOut')}
          >
              <Tooltip title='Sign Out' placement='bottom'>
                  <ExitToApp className={classes.icons} />
              </Tooltip>
            <Hidden mdUp implementation="css">
              <p className={classes.linkText}>Sign Out</p>
            </Hidden>
          </Button>
          <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-label="close"
              className={classes.buttonLink}
              onClick={()=>this.navigation('close')}
          >
              <Tooltip title='Close Event' placement='bottom'>
                  <Close className={classes.icons} />
              </Tooltip>
              <Hidden mdUp implementation="css">
                  <p className={classes.linkText}>Close Event</p>
              </Hidden>
          </Button>
      </div>
    );
  }
}
const mapStateToProps =state=>{
  return {role:state.Auth.role}
};

export default connect(mapStateToProps, {logUserOut, clearEvent})(withStyles(headerLinksStyle)(HeaderLinks));
