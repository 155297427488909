import React from 'react';
import {connect}from 'react-redux';
import history from "../../history";

import Tabs from "../../components/CustomTabs/CustomTabs";
import {Close, CloudDone, AddBox, Archive, FilterList, List, RemoveRedEye, Search,AddAlert} from '@material-ui/icons';

//components
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/FooterMain";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Table from "../../components/Table/Table";
import ProfileModal from '../../components/Modals/ProfileModal';
import {BrandLink} from "../../components/BrandLink";
import Modal from "../../components/Modals/Modal";
import ScrutinHeaderLinks from "../../components/Header/ScrutineerHeaderLinks";
import Button from "../../components/CustomButtons/Button";
import EventModal from "../../components/Modals/EventModal";
import {InputAdornment, Tooltip} from "@material-ui/core";

import {updateProfile, checkLogin, logUserOut} from "../../Store/Actions/Auth";
import {createEvent, getEvents, setEvent, deleteEvent, archiveEvent} from "../../Store/Actions/Scrutineer/EventActions";
import Loading from "../../components/Loading";
import CustomInput from "../../components/CustomInput/CustomInput";
import Snackbar from "../../components/Snackbar/Snackbar";

const TableHeadData =['Name','Date', 'Organization','Status','Action'];

class ScrutineerHome extends React.Component{

    componentDidMount() {
        const {role, isSignedIn}=this.props;

        if(!isSignedIn){
            this.props.checkLogin('scrutineer',(res)=>{
                res.status !==1 && role!=='scrutineer' ? history.push('/'):this.props.getEvents();
            });
        }else{
            this.props.getEvents();
        }

    }

    state={
        modal:false,
        error:'',
        loading:false,
        eventModal:false,
        eventError:'',
        deleteId:null,
        deleteModal:false,
        notif:false,
        notifMsg:'',
        search:'',
        archiveId:null,
        archiveModal:false,
        archiveHeading:'',
        unArchiveModal:false,
    };

    onSubmit=(formValues)=>{
        this.setState({loading:true});
        formValues.role=this.props.role;
        this.props.updateProfile(formValues,(res)=>{
            res.status ===1 ?
                this.setState({
                    loading:false,
                    modal:false,
                    error:'',
                    notif:true,
                    notifMsg:'Profile Updated Successfully'
                }):this.setState({loading:false,error:res.msg});
        })
    };
    saveEvent=(formValues)=>{
        this.setState({loading:true});
        this.props.createEvent(formValues,(res)=>{
            res.status === 2? this.setState({loading:false, eventError:res.msg}):history.push(`/scrutineer/event/${res.key}`)
        });
    };

    openEvent=(id, title)=>{
        this.props.setEvent(id, title, ()=>history.push('/scrutineer/Screens/dashboard'))
    };

    renderTable=()=>{
        if(this.props.events !== null && this.props.events.length >0){
            const tableData = [];
            const search = this.state.search.toLocaleLowerCase();
            this.props.events.reverse().map(event=>{

                if(event?.title?.toLocaleLowerCase().includes(search) ||
                    event?.organization.toLocaleLowerCase().includes(search)||
                    event?.date.toLocaleLowerCase().includes(search)
                ){
                    if(event.status !=='archive' && event.status !=='complete'){
                        const eventArray =[
                            [event.title],
                            [event.date],
                            [event.organization],
                            [event.status],
                            [(<div>
                                <Tooltip title='Archive Event' placement='top'>
                                    <Button onClick={()=>this.setState({archiveHeading:event.title ,archiveId:event.id, archiveModal:true})} color='warning'  size='sm' round justIcon><Archive/></Button>
                                </Tooltip>
                                <Tooltip title='Open Event' placement='top'>
                                    <Button color='info' size='sm' justIcon round onClick={()=>this.openEvent(event.id, event.title)} ><RemoveRedEye/></Button>
                                </Tooltip>
                            </div>)]
                        ];
                        tableData.push(eventArray)
                    }

                }


            });
            return(
                <Table
                    tableHeaderColor='info'
                    tableHead={TableHeadData}
                    tableData={tableData}
                />
            )
        }else{
            return (<h5>No events yet.</h5>)
        }
    };

    renderArchiveTable=()=>{
        if(this.props.events !== null){
            const tableData = [];
            this.props.events.map(event=>{
                if(event.status ==='archive'){
                    const eventArray =[
                        [event.title],
                        [event.date],
                        [event.organization],
                        [event.status],
                        [(<div>
                            <Tooltip title='Delete Event' placement='top'>
                                <Button color='danger' size='sm' round justIcon onClick={()=>this.setState({deleteId:event.id, deleteModal:true})}><Close/></Button>
                            </Tooltip>
                            <Tooltip title='Unarchive Event' placement='top'>
                                <Button onClick={()=>this.setState({archiveHeading:event.title ,archiveId:event.id, unArchiveModal:true})} color='warning'  size='sm' round justIcon><Archive/></Button>
                            </Tooltip>
                        </div>)]
                    ];
                    tableData.push(eventArray)
                }
            });
            return(
                <Table
                    tableHeaderColor='info'
                    tableHead={TableHeadData}
                    tableData={tableData}
                />
            )
        }else{
            return (<h5>No events yet.</h5>)
        }
    };

    renderCompleteTable=()=>{
        if(this.props.events !== null){
            const tableData = [];
            this.props.events.map(event=>{
                if(event.status ==='complete'){
                    const eventArray =[
                        [event.title],
                        [event.date],
                        [event.organization],
                        [event.status],
                        [(<div>
                            <Tooltip title='Delete Event' placement='top'>
                                <Button color='danger' size='sm' round justIcon onClick={()=>this.setState({deleteId:event.id, deleteModal:true})}><Close/></Button>
                            </Tooltip>
                            <Tooltip title='Open Event' placement='top'>
                                <Button color='info' size='sm' justIcon round onClick={()=>this.openEvent(event.id, event.title)} ><RemoveRedEye/></Button>
                            </Tooltip>
                        </div>)]
                    ];
                    tableData.push(eventArray)
                }
            });
            return(
                <Table
                    tableHeaderColor='info'
                    tableHead={TableHeadData}
                    tableData={tableData}
                />
            )
        }else{
            return (<h5>No events yet.</h5>)
        }
    };

    confirmDelete =()=>{
        this.setState({loading:true});
        this.props.deleteEvent(this.state.deleteId, ()=>{
            this.props.getEvents();
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Event deleted successfully',
                deleteId:null,
                deleteModal:false
            })
        })
    };
    handleChange = name => event => {

        this.setState({[name]:event.target.value})
    };

    confirmArchive =()=>{
        this.setState({loading:true});
        this.props.archiveEvent(this.state.archiveId,'archive',()=>{
            this.props.getEvents();
            this.setState({
                loading:false,
                archiveId:null,
                archiveModal:false,
                archiveHeading:''
            })
        })
    };
    confirmUnarchive=()=>{
        this.setState({loading:true});
        this.props.archiveEvent(this.state.archiveId,'not-started',()=>{
            this.props.getEvents();
            this.setState({
                loading:false,
                archiveId:null,
                unArchiveModal:false,
                archiveHeading:''
            })
        })
    };

    render(){
        const { classes, ...rest } = this.props;
        return(
            <div>
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Modal
                    heading={'Unarchive Event'}
                    isVisible={this.state.unArchiveModal}
                    onClose={()=>this.setState({unArchiveModal:false, archiveId:null})}
                    onSave={this.confirmUnarchive}
                    saveButtonColor={'rose'}
                    saveButtonText={'Unarchive'}
                    content={
                        <div>
                            <p>{this.state.archiveHeading}</p>
                            <p>Are you sure you want to unarchive this Event?</p>
                        </div>
                    }
                />
                <Modal
                    heading={'Archive Event'}
                    isVisible={this.state.archiveModal}
                    onClose={()=>this.setState({archiveModal:false, archiveId:null})}
                    onSave={this.confirmArchive}
                    saveButtonColor={'rose'}
                    saveButtonText={'Archive'}
                    content={
                        <div>
                            <p>{this.state.archiveHeading}</p>
                            <p>Are you sure you want to archive this Event?</p>
                        </div>
                    }
                />
                <Modal
                    heading={'Delete Event'}
                    isVisible={this.state.deleteModal}
                    onClose={()=>this.setState({deleteModal:false, deleteId:null})}
                    onSave={this.confirmDelete}
                    saveButtonColor={'danger'}
                    saveButtonText={'Delete'}
                    content={<p>Are you sure you want to delete this Event?</p>}
                />
                <Loading
                    show={this.state.loading}
                />
                <ProfileModal
                    isVisible={this.state.modal}
                    heading={'Scrutineer Profile'}
                    onClose={()=>this.setState({modal:false, error:''})}
                    errorMessage={this.state.error}
                    profileData={{email:this.props.email,firstName:this.props.firstName, lastName:this.props.lastName}}
                    onSubmit={this.onSubmit}
                />
                <EventModal
                    isVisible={this.state.eventModal}
                    heading={'New Event'}
                    errorMessage={this.state.eventError}
                    onClose={()=>this.setState({eventModal:false})}
                    onSubmit={this.saveEvent}
                />
                <Header
                    absolute
                    color={"white"}
                    brand={<BrandLink/>}
                    rightLinks={
                        <ScrutinHeaderLinks
                            profileClick={()=>this.setState({modal:true})}
                            signOutClick={()=>this.props.logUserOut(()=>history.push('/officials'))}
                        />
                    }
                    {...rest}
                />
                <GridContainer >
                    <GridItem xs={1}/>
                    <GridItem xs={10}>
                        <GridContainer>
                            <GridItem xs={12} style={{marginTop:80, justifyContent:'center'}} >
                                <h3>Welcome back {this.props.firstName}</h3>
                            </GridItem>
                            <GridItem xs={12} md={12}>
                                <Tabs
                                    headerColor='info'
                                    tabs={[
                                        {
                                            tabName:'Events',
                                            tabIcon:List,
                                            tabContent:(
                                                <div>
                                                    <GridContainer>
                                                        <GridItem xs={12} sm={8}>
                                                            <Button
                                                                onClick={()=>this.setState({eventModal:true})}
                                                                round
                                                                size={'sm'}
                                                                color={'rose'}
                                                            ><AddBox/> Add New Event</Button>
                                                        </GridItem>
                                                        <GridItem xs={12} sm={4}>
                                                            <CustomInput
                                                                labelText="Search"
                                                                id="Search"
                                                                formControlProps={{
                                                                    fullWidth: true
                                                                }}
                                                                inputProps={{
                                                                    onChange:(this.handleChange('search')),
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Search/>
                                                                        </InputAdornment>
                                                                    )
                                                                }}
                                                            />
                                                        </GridItem>
                                                    </GridContainer>
                                                    {this.renderTable()}
                                                </div>
                                            )
                                        },
                                        {
                                            tabName:'Completed Events',
                                            tabIcon:CloudDone,
                                            tabContent:(
                                                <div>
                                                    {this.renderCompleteTable()}
                                                </div>
                                            )
                                        },
                                        {
                                            tabName:'Archived Events',
                                            tabIcon:FilterList,
                                            tabContent:(
                                                <div>{this.renderArchiveTable()}</div>
                                            )
                                        }
                                    ]}
                                />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={1}/>
                </GridContainer>
                <Footer/>
            </div>
        )
    }
}


const mapStateToProps=state=>{
    const {isSignedIn,firstName,lastName,email,role}=state.Auth;

    return{
        isSignedIn,
        firstName,
        lastName,
        email,
        role,
        events:state.events.events,
    }
};
export default connect(mapStateToProps,{
    updateProfile,
    checkLogin,
    logUserOut,
    createEvent,
    getEvents,
    setEvent,
    deleteEvent,
    archiveEvent
})(ScrutineerHome);
