import React from 'react';
// components
import Footer from "../../../components/Footer/FooterMain";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import {SpecialDanceTable} from "../../Functions/index";
import PrintEventHead from "./PrintEventHead";

class PrintFinalMarks extends React.Component{

    renderSpecialDanceMark=()=>{
        const {specialMarks}=this.props.roundData;
        if(specialMarks!== undefined){
            return (SpecialDanceTable(specialMarks));
        }
    };

    render(){
        const {logo,title,date,venue,organization,organizer,scrutineer}=this.props.data.info;
        const {roundData,sectionTitle,panel,sectionType}=this.props;
        return (
            <div style={{padding:25}}>
                <GridContainer>
                    <GridItem xs={12}/>
                    <GridItem xs={12}>
                      <PrintEventHead
                          logo={logo.url}
                          title={title}
                          date={date}
                          venue={venue}
                          organizer={organizer}
                          organization={organization}
                          scrutineer={scrutineer}
                          sectionTitle={sectionType==='multiDance'?
                              roundData.itemNo+' '+sectionTitle+'-'+roundData.roundType+'-'+roundData.dances[0]:
                              roundData.itemNo+' '+sectionTitle+'-'+roundData.roundType}
                          roundNo={roundData.roundNo}
                      />
                    </GridItem>
                    <GridItem xs={12}>
                        <h6>Panel -  {roundData.panel.panel}</h6>
                        {panel}
                    </GridItem>
                    <GridItem xs={12}>
                        {this.props.marksTable}
                    </GridItem>
                    {roundData.dances.includes('exhibition') ||
                    roundData.dances.includes('showcase')||
                    roundData.dances.includes('showdance')||
                    roundData.dances.includes('solo spot') ?
                        <GridItem xs={12}>
                            <h5>TCIS Marks</h5>
                            <p>T-Technique C-Choreography I-Image S-Show </p>
                            {this.renderSpecialDanceMark()}
                        </GridItem>:''
                    }
                    <GridItem xs={12}>
                        <p>Processed @: {new Date(roundData.processedTime).toString()}</p>
                    </GridItem>
                </GridContainer>
                <br/>
                <div  style={{position:'absolute',bottom:0}}>

                    <Footer/>
                </div>
            </div>
        )
    }
}
export default PrintFinalMarks;