

export const DancersFunction=(dancers)=>{
    return  Object.keys(dancers).reduce((a,v)=>{

        const {number,names,studio}=dancers[v];

        a[number]={
            names,
            studio
        };

        return a;
    },{});
};

export const SocialDancerFunction=(dancers)=>{
    return Object.keys(dancers).reduce((a,v)=>{
       const {number,names,studio,teacher}=dancers[v];


       a[number]={
           names,
           studio,
           teacher:teacher !== undefined? teacher:''
       };
       return a
    },{})
};