import React from 'react';
import {connect}from 'react-redux';
import { Switch, Route} from "react-router-dom";
import history from "../../history";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Navbar from "../../components/Navbars/Navbar.jsx";
import Footer from "../../components/Footer/FooterMain.jsx";
import Sidebar from "../../components/Sidebar/Sidebar.js";

//routes
import EventRoutes from "./EventRoutes";
import EventMarks from './Views/EventMarks';
import dashboardStyle from "../../assets/jss/material-dashboard-react/layouts/dashboardStyle.js";
import image from "../../assets/img/bg-1.jpg";
import logo from "../../assets/img/icon_only-white.png";
import {getEventData} from "../../Store/Actions/Scrutineer/EventActions";

const switchRoutes = (
    <Switch>
        {EventRoutes.map((prop, key) => {
            return (
                <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                    exact
                />
            );

        })
        }
        <Route path='/chairperson/event/results/marks' component={EventMarks}/>
    </Switch>
);
class EventHome extends React.Component{



    state={
        image: image,
        color: "blue",
        hasImage: true,
        fixedClasses: "dropdown show",
        mobileOpen: false
    };
    handleDrawerToggle = () => {
        this.setState({ mobileOpen: !this.state.mobileOpen });
    };
    resizeFunction = () => {
        if (window.innerWidth >= 960) {
            this.setState({ mobileOpen: false });
        }
    };
    componentDidMount() {
        if(this.props.eventId !== null && this.props.auth.isSignedIn){
            this.props.getEventData(this.props.eventId)
        }else{
            history.push('/chairperson');
        }
        if (navigator.platform.indexOf("Win") > -1) {
            const ps = new PerfectScrollbar(this.refs.mainPanel);
        }
        window.addEventListener("resize", this.resizeFunction);
    }
    componentDidUpdate(e) {
        if (e.history.location.pathname !== e.location.pathname) {
            this.refs.mainPanel.scrollTop = 0;
            if (this.state.mobileOpen) {
                this.setState({ mobileOpen: false });
            }
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeFunction);
    }


    render(){
        const { classes, ...rest } = this.props;
        return (
            <div className={classes.wrapper}>
                <Sidebar
                    routes={EventRoutes}
                    logoText={"Dance Suite"}
                    logo={logo}
                    image={this.state.image}
                    handleDrawerToggle={this.handleDrawerToggle}
                    open={this.state.mobileOpen}
                    color={this.state.color}
                    {...rest}
                />
                <div className={classes.mainPanel} ref="mainPanel">
                    <Navbar
                        eventTitle={this.props.eventTitle}
                        routes={EventRoutes}
                        handleDrawerToggle={this.handleDrawerToggle}
                        {...rest}
                    />
                    <div className={classes.content}>
                        <div className={classes.container}>{switchRoutes}</div>
                    </div>
                    <Footer/>
                </div>
            </div>
        );
    }
}
const mapStateToProps =state=>{
    return {
        auth:state.Auth,
        eventId:state.eventId.eventId,
        eventTitle:state.eventId.eventTitle
    }
};

export default connect(mapStateToProps, {getEventData})(withStyles(dashboardStyle)(EventHome));
