import React from "react";
import classNames from 'classnames';
import withStyles from "@material-ui/core/styles/withStyles";
import {connect}from 'react-redux';
//actions
import {authLogin, authRegister,forgotPassword} from "../Store/Actions/Auth/";
import history from "../history";
//icons
import {ExitToApp, HowToReg} from '@material-ui/icons';

//core components
import Header from "../components/Header/Header";
import HeaderLinks from "../components/Header/HeaderLinks";
import Footer from "../components/Footer/FooterMain";
import GridContainer from "../components/Grid/GridContainer";
import GridItem from "../components/Grid/GridItem";
import Button from "../components/CustomButtons/Button";


import colors from '../Variables/';
import landingPageStyle from "../assets/jss/material-kit-react/views/landingPage";
import Parallax from "../components/Parallax/Parallax";
import LoginCard from '../components/Modals/LoginModal';
import {BrandLinkWhite} from "../components/BrandLink";
import RegistrationModal from "../components/Modals/RegistrationModal";
import Loading from "../components/Loading";
import ForgotPasswordModal from '../components/Modals/ForgotPasswordModal';
import {DSDivider} from "../components/Divider";
import SiteLinks from "../components/SitelInks";
import CenterHeader from "../components/Header/CenterHeader";
import WhiteFooter from "../components/Footer/WhiteFooter";


class Home extends React.Component{

    state={
        modal:false,
        role:'',
        modalHeading:'',
        error:'',
        loading:false,
        regModal:false,
        regHeading:'',
        regRole:'',
        regError:null,
        regSuccess:null,
        forgotPasswordModal:false,
        resetError:'',
        resetSuccess:''
    };

    onSubmit=(formValues)=>{
        formValues.role = this.state.role;
        this.setState({loading:true});
        this.props.authLogin(formValues,(response)=>{
            response.status === 1 ?
                history.push(`/${this.state.role}`):this.setState({loading:false, error:response.msg});
        })
    };

    onRegister=(formValues)=>{

        this.setState({loading:true});
        formValues.role=this.state.role;
        this.props.authRegister(formValues,(res)=>{
            res.status !== 1 ?
                this.setState({loading:false, regError:res.errMsg}):
                this.setState({loading:false, regSuccess:res.success})
        })
    };

    onReset=(formValues)=>{
        this.setState({loading:true});
        this.props.forgotPassword(formValues,(res)=>{
            res.status !==1?
                this.setState({loading:false,resetError:res.msg}):
                this.setState({
                    loading:false,
                    resetSuccess:'Check your inbox for a link to reset your password.',
                })
        })
    };

    render(){
        const { classes, ...rest } = this.props;
        const {regError,regHeading,regModal, loading, regSuccess,forgotPasswordModal, resetError, resetSuccess}=this.state;
        return (
            <div>
                <ForgotPasswordModal
                    isVisible={forgotPasswordModal}
                    onClose={()=>this.setState({forgotPasswordModal: false, resetError: '', resetSuccess: ''})}
                    onSave={this.onReset}
                    heading={'Reset Password'}
                    errorMessage={resetError}
                    successMessage={resetSuccess}
                />
                <LoginCard
                    onPassword={()=>this.setState({modalHeading:'',modal: false,error:'',forgotPasswordModal:true})}
                    isVisible={this.state.modal}
                    heading={this.state.modalHeading}
                    onClose={()=>this.setState({modal:false, modalHeading:'', error:''})}
                    onSubmit={this.onSubmit}
                    errorMessage={this.state.error}
                />
                <Loading
                    show={loading}
                />
                <RegistrationModal
                    isVisible={regModal}
                    onClose={()=>this.setState({regHeading: '', regModal:false, regError: ''})}
                    heading={regHeading}
                    onSave={this.onRegister}
                    errorMessage={regError}
                    successMessage={regSuccess}
                />
                <CenterHeader/>
                <Parallax small filter image={require("../assets/img/backgrounds/officalBg.jpg")}>
                    <div className={classes.container}>
                        <GridContainer justify={'center'} alignItems={'center'} >
                            <h1><b>Officials</b></h1>
                        </GridContainer>
                    </div>
                </Parallax>
                <div className={classes.section}>
                    <div className={classes.main}>
                        <GridContainer>
                            <GridItem xs={12}  style={{marginLeft:'2%',marginRight:'2%'}}>
                                <GridContainer>
                                    <GridItem xs={12} md={6} container alignItems={'center'} justify={'center'} direction='column'>
                                        <div>
                                            <h2 style={{color:colors.dSBlue}}>Scrutineer</h2>
                                            <p> The scrutineer has complete control over all the events they create and manage. Assign adjudicators,create sections, add dancers, review marks and get an overall view of the event with the powerful dashboard tab. </p>
                                            <div>
                                                <Button size='sm' color={"info"} onClick={()=>this.setState({modal:true, modalHeading:'Scrutineer Login', role:'scrutineer'})}>
                                                    <ExitToApp/>
                                                    Login
                                                </Button>
                                                <Button size='sm' color={'rose'} onClick={()=>this.setState({regModal: true, regHeading: 'Register as a Scrutineer', role:'scrutineer'})}>
                                                    <HowToReg/>
                                                    Register
                                                </Button>
                                            </div>
                                        </div>
                                    </GridItem>
                                    <GridItem xs={12} md={6}  container alignItems={'center'} justify={'center'} direction='column'>
                                        <img alt={'dance-suite-officials-scrutineer'} style={{maxWidth:'100%'}} src={require('../assets/img/Officals-scrutineer.png')}/>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>
                            <GridItem xs={12}>
                                <DSDivider/>
                            </GridItem>
                            <GridItem xs={12}  style={{marginLeft:'2%',marginRight:'2%'}}>
                                <GridContainer>
                                    <GridItem xs={12} md={6}  container alignItems={'center'} justify={'center'} direction='column'>
                                        <img alt={'dance-suite-officials-chairperson'} style={{maxWidth:'100%'}} src={require('../assets/img/Officials-Chaiperson.png')}/>
                                    </GridItem>
                                    <GridItem xs={12} md={6}  container alignItems={'center'} justify={'center'} direction='column'>
                                        <div>
                                            <h2 style={{color:colors.dSBlue}}>Chairperson</h2>
                                            <p>The chairperson has access to all the information in the event that the scrutineer has allocated them to, including the same powerful overview dashboard, approve marks and view all the information relating to the event.</p>
                                            <div>
                                                <Button size='sm' color='info' onClick={()=>this.setState({modal:true, modalHeading:'Chairperson Login', role:'chairperson'})}>
                                                    <ExitToApp/>
                                                    Login
                                                </Button>
                                                <Button size='sm' color={'rose'} onClick={()=>this.setState({regModal: true, regHeading: 'Register as a Chairperson', role:'chairperson'})}>
                                                    <HowToReg/>
                                                    Register
                                                </Button>
                                            </div>
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            </GridItem>

                        </GridContainer>
                    </div>
                </div>
                <WhiteFooter/>
            </div>
        )
    }
}
export default connect(null,{authLogin, authRegister, forgotPassword})(withStyles(landingPageStyle)(Home));