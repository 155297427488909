import React from 'react';
import {SketchPicker}from 'react-color';
import Button from "../../../../components/CustomButtons/Button";


class ColorSelector extends React.Component{

    state={
        color:'',
        type:''
    };

    componentWillMount=()=>{
        const {color,type}=this.props;
        this.setState({color,type})
    };

    handleChange=(color)=>{
        this.setState({color:color.hex})
    };

    render(){
        const {color,type}=this.state;
        return(
            <div>
                <SketchPicker
                    color={color}
                    onChangeComplete={this.handleChange}
                />
                <Button onClick={()=>this.props.onSave(color,type)} size='sm' color={'success'} round>Save Color</Button>
            </div>

        )
    }
}
export default ColorSelector;
