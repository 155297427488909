import React from 'react';
import Footer from "../../../components/Footer/FooterMain";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Image from "material-ui-image/lib/components/Image/Image";

class PrintRoundResult extends React.Component{

    render(){
        const {info}=this.props.data;
        const {roundData, sectionTitle}=this.props;
        return (
            <div>
                <GridContainer style={{padding:25}}>
                    <GridItem xs={12}/>
                    <GridItem xs={6} style={{textAlign:'center'}}>
                        <div style={{maxHeight: '250px', maxWidth: '250px',}}>
                            <Image
                                aspectRatio={'2/1'}
                                style={{
                                    objectFit:'scale-down',
                                }}
                                src={info.logo.url}/>

                        </div>
                    </GridItem>
                    <GridItem xs={6}>
                        <GridContainer>
                            <GridItem xs={6} style={{textAlign:'right'}}>
                                Title:<br/>
                                Date:<br/>
                                Venue:<br/>
                                Organization:<br/>
                                Organizer:<br/>
                                Scrutineer:

                            </GridItem>
                            <GridItem xs={6}>
                                <b>{info.title}</b><br/>
                                {info.date}<br/>
                                {info.venue}<br/>
                                {info.organization}<br/>
                                {info.organizer.name}<br/>
                                {info.scrutineer.name+ ' '+info.scrutineer.contact}
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                        <GridContainer>
                            <GridItem xs={6}>
                                <h4>{roundData.itemNo +' '+sectionTitle+"-"+roundData.roundType}</h4>
                            </GridItem>
                            <GridItem xs={6}>
                                <h4>Round No: {roundData.roundNo}</h4>
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12}>
                        <h5>{this.props.callback.length}  Dancers recalled:</h5>
                        <table>
                            <tbody>
                                {this.props.callback.map(item=>{
                                    return (
                                        <tr key={'callback'+item}>
                                            <td><b>{item[0]}</b></td>
                                            <td><b>{item[1]}</b></td>
                                        </tr>
                                    )
                                })
                                }

                            </tbody>
                        </table>
                    </GridItem>
                </GridContainer>
                <div  style={{position:'absolute',bottom:0}}>
                    <Footer/>
                </div>
            </div>
        )
    }
}
export default PrintRoundResult;