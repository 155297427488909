import React from 'react';
import {reduxForm,Field} from "redux-form";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import DialogContent from "@material-ui/core/DialogContent";
import Danger from "../Typography/Danger";
import GridContainer from "../Grid/GridContainer";
import GridItem from "../Grid/GridItem";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "../CustomButtons/Button";
import Transition from "../../Functions/Transistion";
import RenderInputs from "../../Functions/RenderInputs";
import withStyles from "@material-ui/core/styles/withStyles";
import modalStyle from "../../assets/jss/material-kit-react/modalStyle";
import Success from "../Typography/Success";

class ForgotPasswordModal extends React.Component{

    onSubmit=(formValues)=>{
        this.props.onSave(formValues)
    };

    render(){
        const {classes, isVisible, onClose, heading, errorMessage,successMessage }=this.props;
        return (
            <div>
                <Dialog
                    classes={{
                        root: classes.center,
                        paper: classes.modal
                    }}
                    open={isVisible}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={onClose}
                    aria-labelledby="modal-slide-title"
                    aria-describedby="modal-slide-description">
                    <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                        <DialogTitle
                            id="classic-modal-slide-title"
                            disableTypography
                            className={classes.modalHeader}>
                            <IconButton
                                className={classes.modalCloseButton}
                                key="close"
                                aria-label="Close"
                                color="inherit"
                                onClick={onClose}>
                                <Close className={classes.modalClose} />
                            </IconButton>
                            <h4 className={classes.modalTitle}>{heading} </h4>
                        </DialogTitle>
                        <DialogContent
                            id="modal-slide-description"
                            className={classes.modalBody}>
                            <Danger>{errorMessage}</Danger>
                            <Success>{successMessage}</Success>
                            <GridContainer>
                                <GridItem xs={12} sm={12}>
                                    <p>Forgot your password? No problem, simply enter your email below and well send you a link to reset it.</p>
                                    <Field id='email' type='email' placeholder='name@email.com' name='email' label='Email' component={RenderInputs}/>
                                </GridItem>
                            </GridContainer>
                        </DialogContent>
                        <DialogActions
                            className={classes.modalFooter +" " +classes.modalFooterCenter}>
                            <Button
                                type='button'
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                type='submit'
                                color="success">
                                Save
                            </Button>
                        </DialogActions>
                    </form>
                </Dialog>
            </div>
        )
    }

}
const validate=formValues=>{
    const errors={};
    const {email}=formValues;

    if(!email){
        errors.email='Please enter your email!'
    }else if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)){
        errors.email='Please enter a valid email'
    }

    return errors;
};


const form =reduxForm({
    form:'forgotPassword',
    validate
})(ForgotPasswordModal);

export default (withStyles(modalStyle)(form))