import {LOAD_SECTION, REMOVE_SECTION} from "../types";

const INITIAL_STATE={
    data:null,
    id:null
};

export default (state=INITIAL_STATE, action)=> {
    switch (action.type) {
        case LOAD_SECTION:
            return {...state, data: action.payload.data, id: action.payload.id};
        case REMOVE_SECTION:
            return {data: null, id: null};
        default:
            return {...state}

    }

}