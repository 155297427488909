import React from 'react';
import {connect}from 'react-redux';
import {Forward,AddAlert,Close,PlaylistAddCheck,LocalPrintshop} from '@material-ui/icons';
import ReactToPrint from "react-to-print";
// components
import history from "../../history";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Modal from "../../components/Modals/Modal";
import Snackbar from "../../components/Snackbar/Snackbar";
import Loading from '../../components/Loading';
import Button from "../../components/CustomButtons/Button";
import Tabs from "../../components/CustomTabs/CustomTabs";

//algorithms
import {renderMarksDetails} from "../Alogrithms/roundAlgo";
import {marksTableFinal, returnResultsFinal} from "../Alogrithms/finalAlgo";
import {unContestedTable, uncontestFinalMarks, UncontestFinalSpecialTable} from "../Alogrithms/uncontestedAlgo";
import {SpecialDanceTable, RenderPanelData} from "../Functions";
import {processFinalMarks, removeMarks} from "../../Store/Actions/Scrutineer/SectionActions";


import PrintFinalMarks from "../Components/PrintComponents/printFinalMarks";
import PrintFinalResult from "../Components/PrintComponents/printFinalResult";


class MarksSocialComponent extends React.Component{

    state={
        loading:false,
        notif:false,
        notifMsg:'',
        finalModal:false,
        result:null
    };

    closeMarks=()=>{
        this.props.removeMarks(()=>history.push('/scrutineer/Screens/results'))
    };


    renderMarks=()=>{
      const {roundType,dances}=this.props.roundData;
      const {roundData}=this.props;
        if(roundType==='Contested'){
            // treat as normal final
            return marksTableFinal(roundData);
        }else if(roundType==='Uncontested'){
            if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
                return UncontestFinalSpecialTable(roundData);
            }else{
                return unContestedTable(roundData)
            }
        }
    };

    renderSpecialDanceMark=()=>{
        const {specialMarks}=this.props.roundData;
        if(specialMarks!== undefined){
            return (SpecialDanceTable(specialMarks));
        }
    };

    renderFinalModalContent=()=>{
        const {roundData}=this.props;
        if(roundData.roundType ==='Contested'){
            const returnedResults = returnResultsFinal(roundData);
            return (returnedResults.table);
        }else{
            return <p>Confirm uncontested results?</p>
        }

    };

    saveFinalProcessing=()=>{
        const {roundData,sectionId,roundId}=this.props;
        let result ='';
        if(roundData.roundType ==='Contested'){
            result = returnResultsFinal(roundData).result;

        }else if(roundData.roundType ==='Uncontested'){
            // get final result only
            result = uncontestFinalMarks(roundData)
        }

        this.setState({loading:true});

        this.props.processFinalMarks(result,roundId,sectionId,()=>{
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Round processed successfully',
                finalModal:false
            })
        });


    };


    render(){
        const {roundData,title, eventDetails,dancers, sectionRounds, adjudicators, sectionType}=this.props;
        const panelLength = roundData.panel.letters.split(',').length;
        return(
            <div>
                <Modal
                    isVisible={this.state.finalModal}
                    onClose={()=>this.setState({finalModal:false})}
                    content={this.renderFinalModalContent()}
                    heading={'Final Result - '+this.props.title}
                    onSave={()=>this.saveFinalProcessing()}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={this.state.loading}
                />
                <GridContainer>
                    <GridItem xs={8}>
                        <h4>{roundData.itemNo}  {title}-{roundData.roundType}</h4>
                    </GridItem>
                    <GridItem xs={4}>
                        {roundData.results === undefined && Object.keys(roundData.marks).length === panelLength  ?
                            <Button onClick={()=>this.setState({finalModal:true})}
                                    size='sm'
                                    round color={'primary'}
                            >
                                <Forward/>Process Results
                            </Button>:Object.keys(roundData.marks).length !== panelLength?
                                <p>{Object.keys(roundData.marks).length+'/'+panelLength} marks received</p>:''
                        }
                        <Button onClick={()=>this.closeMarks()} size='sm' round><Close/>Close Marks</Button>
                    </GridItem>
                </GridContainer>
                <Tabs
                    headerColor={'info'}
                    tabs={[
                        {
                            tabName:'Details',
                            tabIcon:PlaylistAddCheck,
                            tabContent:(
                                <div>
                                    {this.renderMarks()}
                                    <GridContainer>
                                        {roundData.dances.includes('exhibition') ||
                                        roundData.dances.includes('showcase')||
                                        roundData.dances.includes('showdance')||
                                        roundData.dances.includes('solo spot') ?
                                            <GridItem xs={12}>
                                                <h3>TCIS Marks</h3>
                                                <p>T-Technique C-Choreography I-Image S-Show </p>
                                                {this.renderSpecialDanceMark()}
                                            </GridItem>:''
                                        }

                                        <GridItem xs={12}>
                                            <h4> Adjudicator Log</h4>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <td>Letter</td>
                                                    <td>Device</td>
                                                    <td>Time</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderMarksDetails(roundData)}
                                                </tbody>
                                            </table>
                                            {roundData.processed  ?
                                                <h5>Processed @ {new Date(roundData.processedTime).toString()}</h5>:''
                                            }
                                            {this.props.roundData.checked ?
                                                <h5>Checked @ {new Date(roundData.checkTime).toString()}</h5>:''
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            )
                        },
                        {
                            tabName:'Print Marks',
                            tabIcon:LocalPrintshop,
                            tabContent:(roundData.processed ?
                                (
                                    <div>
                                        <ReactToPrint
                                            trigger={() =><Button type='button' round size='sm' >Print</Button>}
                                            content={() => this.componentRef}
                                        />{
                                        roundData.roundType === 'Contested'?
                                            <PrintFinalMarks
                                                ref={el => (this.componentRef = el)}
                                                data={eventDetails}
                                                panel={RenderPanelData(roundData,adjudicators)}
                                                roundData={roundData}
                                                sectionTitle={title}
                                                marksTable={marksTableFinal(roundData)}
                                            />:
                                            <PrintFinalMarks
                                                ref={el => (this.componentRef = el)}
                                                data={eventDetails}
                                                panel={RenderPanelData(roundData,adjudicators)}
                                                roundData={roundData}
                                                sectionTitle={title}
                                                marksTable={this.renderMarks()}
                                            />

                                    }

                                    </div>
                                ):<div>Results not Processed yet</div>)
                        },
                        {
                            tabName:'Print Result List',
                            tabIcon:LocalPrintshop,
                            tabContent:(this.props.roundData.processed ?
                                    (<div>
                                        <ReactToPrint
                                            trigger={()=><Button  type='button' round size='sm'>Print</Button>}
                                            content={()=>this.componentRef}
                                        />{
                                            <PrintFinalResult
                                                sectionType={sectionType}
                                                dancers={dancers}
                                                data={eventDetails}
                                                sectionTitle={title}
                                                roundData={roundData}
                                                sectionRounds={sectionRounds}
                                                ref={ele=>(this.componentRef=ele)}
                                            />
                                    }

                                    </div>):<div>Results not Processed yet</div>

                            )
                        }
                    ]}
                />
            </div>
        )
    }
}
export default connect(null,{removeMarks, processFinalMarks})(MarksSocialComponent);