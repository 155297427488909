import React from 'react';
import {connect}from 'react-redux';
import ReactToPrint from "react-to-print";
import {Forward,AddAlert,Close,PlaylistAddCheck,LocalPrintshop} from '@material-ui/icons';

// components
import history from "../../history";
import {removeMarks,processFinalMarks,processRoundMark} from "../../Store/Actions/Scrutineer/SectionActions";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Modal from "../../components/Modals/Modal";
import Snackbar from "../../components/Snackbar/Snackbar";
import Loading from "../../components/Loading";
import Button from "../../components/CustomButtons/Button";
import Tabs from "../../components/CustomTabs/CustomTabs";
import RoundProcessModal from "../../components/Modals/RoundProcessModal";

// algorithms & functions
import {marksTableFinal, returnResultsFinal} from "../Alogrithms/finalAlgo";
import {marksTable, renderDancers, renderMarksDetails} from "../Alogrithms/roundAlgo";
import {unContestedTable, uncontestFinalMarks, UncontestFinalSpecialTable} from "../Alogrithms/uncontestedAlgo";
import {RenderPanelData, SpecialDanceTable} from "../Functions";

import PrintRoundMarks from "../Components/PrintComponents/printRoundMarks";
import PrintFinalMarks from "../Components/PrintComponents/printFinalMarks";
import PrintRoundResult from "../Components/PrintComponents/printRoundResult";
import PrintFinalResult from "../Components/PrintComponents/printFinalResult";
import MarksheetsModal from "../../components/Modals/MarksheetsModal";

class MarksCompetitiveComponent extends React.Component{
    state={
        loading:false,
        notif:false,
        notifMsg:'',
        roundModal:false,
        totals:null,
        rounds:null,
        finalModal:false,
        result:null,
        pdfModal:null,
    };

    closeMarks=()=>{
        this.props.removeMarks(()=>history.push('/scrutineer/Screens/results'))
    };

    totals=()=>{
        const {marks, roundData}=this.props;
        return roundData.activeList.reduce((a,v)=>{

            Object.keys(roundData.marks).map(adj=>{
                roundData.dances.map(dance=>{

                    if(marks[adj][dance].includes(v)){
                        if(!a[v]){
                            a[v]=1
                        }else{
                            a[v]+=1
                        }
                    }
                })
            });

            return a;
        },{})
    };

    saveRound=(callbackArray, nextRound, placingResult)=>{
        const roundId =  this.props.roundId;
        const sectionId = this.props.sectionId;
        const nextRoundNo = nextRound.split('-')[0];
        const rounds = this.props.sectionRounds;
        const nextRoundId =Object.keys(rounds).find(el=>{

            if(parseInt(rounds[el].roundNo) === parseInt(nextRoundNo)){
                return el
            }
        });


        this.setState({loading:true});
        this.props.processRoundMark(callbackArray, nextRoundId,roundId,sectionId,placingResult,()=>{
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Round processed successfully',
                roundModal:false
            })
        })


    };

    renderPanel=()=>{
        const {roundData,adjudicators}=this.props;
        return RenderPanelData(roundData,adjudicators );
    };


    renderFinalModalContent=()=>{
        const {roundData}=this.props;
        if(roundData.roundType ==='Final'){
            const returnedResults = returnResultsFinal(roundData);
            return (returnedResults.table);
        }else{
            return <p>Confirm uncontested results?</p>
        }

    };

    renderFinalMarks=()=>{
        const {roundData}=this.props;
        if(roundData.roundType === 'Final'){
            return marksTableFinal(roundData)
        }else{
            return this.renderUncontestedMark();
        }

    };

    renderUncontestedMark=()=>{

        const {dances}=this.props.roundData;

        if(dances.includes('showdance')||dances.includes('showcase')||dances.includes('exhibition')||dances.includes('solo spot')){
           return UncontestFinalSpecialTable(this.props.roundData);
        }else{
            return unContestedTable(this.props.roundData)
        }


    };

    renderMarks =()=>{
        const {roundType, marksLog}=this.props.roundData;
        const {title, marks, roundData}=this.props;
        if(roundType ==='Final'){
            //set marks for final
            return (
                this.renderFinalMarks()
            )
        }else if(roundType ==='Uncontested'){
            return(this.renderUncontestedMark());
        }
        else{
            // set marks for semi-final & round

            const rounds = this.props.sectionRounds;

            const roundCheck = Object.keys(rounds).reduce((a,v)=>{
                if(rounds[v].roundNo > roundData.roundNo){
                    a.push(rounds[v].roundNo+'-'+rounds[v].roundType)
                }
                return a;
            },[]);

            const totals =this.totals();

            if(this.state.totals === null){
                this.setState({totals:totals, rounds:roundCheck})
            }


            return (
                <div>

                    <GridContainer>
                        <GridItem xs={8}>
                            <h4>{roundData.itemNo} {title} - {roundType}</h4>
                        </GridItem>
                        <GridItem xs={4}>
                            <h5>Panel  {roundData.panel.panel} </h5>
                        </GridItem>
                        <GridItem xs={10}>
                            {marksTable(roundData,roundData.marks)}
                        </GridItem>
                    </GridContainer>

                </div>
            )
        }
    };

    saveFinalProcessing=()=>{
        const {roundData,sectionId,roundId}=this.props;
        let result ='';
        if(roundData.roundType ==='Final'){
            result = returnResultsFinal(roundData).result;

        }else if(roundData.roundType ==='Uncontested'){
            // get final result only
            result = uncontestFinalMarks(roundData)
        }

        this.setState({loading:true});

        this.props.processFinalMarks(result,roundId,sectionId,()=>{
            this.setState({
                loading:false,
                notif:true,
                notifMsg:'Final processed successfully',
                finalModal:false
            })
        });


    };


    renderSpecialDanceMark=()=>{
        const {specialMarks}=this.props.roundData;
        if(specialMarks!== undefined){
             return (SpecialDanceTable(specialMarks));
        }
    };


    render(){
        const {roundData,sectionType,title,eventDetails}=this.props;
        const panelLength = roundData?.panel?.letters.split(',').length;
        return (
            <div>
                <MarksheetsModal
                    visible={!!this.state.pdfModal}
                    content={this.state.pdfModal}
                    onClose={()=>{
                        this.setState({pdfModal:null})
                    }}
                />
                <Modal
                    isVisible={this.state.finalModal}
                    onClose={()=>this.setState({finalModal:false})}
                    content={this.renderFinalModalContent()}
                    heading={'Final Result - '+this.props.title}
                    onSave={()=>this.saveFinalProcessing()}
                />
                <Snackbar
                    place='br'
                    color='success'
                    icon={AddAlert}
                    message={this.state.notifMsg}
                    open={this.state.notif}
                    close
                    closeNotification={()=>this.setState({notif:false,notifMsg:''})}
                />
                <Loading
                    show={this.state.loading}
                />
                <RoundProcessModal
                    roundData={this.props.roundData}
                    nextRound={this.state.rounds}
                    totals={this.state.totals}
                    isVisible={this.state.roundModal}
                    onClose={()=>this.setState({roundModal:false})}
                    onSave={this.saveRound}
                    heading={this.props.title+'-'+this.props.roundData.roundType}
                />
                <GridContainer>
                    <GridItem xs={8}>
                        <h4>{sectionType==='multiDance'? roundData.itemNo+' '+title+'-'+roundData.roundType+'-'+roundData.dances[0]:
                            roundData.itemNo+' '+title+'-'+roundData.roundType}</h4>
                      </GridItem>
                    <GridItem xs={4}>
                        {roundData.results === undefined && Object.keys(roundData.marks).length === panelLength  ?
                            <Button onClick={()=>
                                roundData.roundType === 'Final' || roundData.roundType === 'Uncontested'?
                                    this.setState({finalModal:true}):this.setState({roundModal:true})}
                                    size='sm'
                                    round color={'primary'}
                            >
                                <Forward/>Process Results
                            </Button>:Object.keys(roundData.marks).length !== panelLength?
                                <p>{Object.keys(roundData.marks).length+'/'+panelLength} marks received</p>:''
                        }
                        <Button onClick={()=>this.closeMarks()} size='sm' round><Close/>Close Marks</Button>
                    </GridItem>
                </GridContainer>
                <Tabs
                    headerColor={'info'}
                    tabs={[
                        {
                            tabName:'Details',
                            tabIcon:PlaylistAddCheck,
                            tabContent:(
                                <div>
                                    {this.props.roundData.roundType !=='Final' && this.props.roundData.roundType!=='Uncontested'?<h5>Dancers called back: {this.props.roundData.results !== undefined ? this.props.roundData.results.join(',  '):'Not-Processed'}</h5>:''}
                                    {this.renderMarks()}
                                    <GridContainer>
                                        {this.props.roundData.dances.includes('exhibition') ||
                                        this.props.roundData.dances.includes('showcase')||
                                        this.props.roundData.dances.includes('showdance')||
                                        this.props.roundData.dances.includes('solo spot') ?
                                            <GridItem xs={12}>
                                                    <h3>TCIS Marks</h3>
                                                    <p>T-Technique C-Choreography I-Image S-Show </p>
                                                     {this.renderSpecialDanceMark()}
                                            </GridItem>:''
                                        }

                                        <GridItem xs={12}>
                                            <h4> Adjudicator Log</h4>
                                        </GridItem>
                                        <GridItem xs={12}>
                                            <table>
                                                <thead>
                                                <tr>
                                                    <td>Letter</td>
                                                    <td>Device</td>
                                                    <td>Time</td>
                                                    <td>Mark Sheet</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {renderMarksDetails(this.props.roundData, (pdfContent)=>{
                                                    this.setState({pdfModal:pdfContent})
                                                })}
                                                </tbody>
                                            </table>
                                            {this.props.roundData.processed  ?
                                                <h5>Processed @ {new Date(roundData.processedTime).toString()}</h5>:''
                                            }
                                            {this.props.roundData.checked ?
                                                <h5>Checked @ {new Date(roundData.checkTime).toString()}</h5>:''
                                            }
                                        </GridItem>
                                    </GridContainer>
                                </div>
                            )
                        },
                        {
                            tabName:'Print Marks',
                            tabIcon:LocalPrintshop,
                            tabContent:(this.props.roundData.processed ?
                                (
                                    <div>
                                        <ReactToPrint
                                            trigger={() =><Button type='button' round size='sm' >Print</Button>}
                                            content={() => this.componentRef}
                                            debug={true}
                                        />{
                                        roundData.roundType !== 'Final' && roundData.roundType !== 'Uncontested'? (
                                                <PrintRoundMarks
                                                    sectionType={sectionType}
                                                    panel={this.renderPanel()}
                                                    marks={marksTable(roundData,roundData.marks)}
                                                    roundData={roundData}
                                                    sectionTitle={title}
                                                    data={eventDetails}
                                                    ref={el => (this.componentRef = el)}  />
                                            ):
                                            <PrintFinalMarks
                                                ref={el => (this.componentRef = el)}
                                                data={eventDetails}
                                                panel={this.renderPanel()}
                                                roundData={roundData}
                                                sectionTitle={title}
                                                marksTable={this.renderFinalMarks()}
                                                sectionType={sectionType}
                                            />
                                    }

                                    </div>
                                ):<div>Results not Processed yet</div>)
                        },
                        {
                            tabName:'Print Result List',
                            tabIcon:LocalPrintshop,
                            tabContent:(this.props.roundData.processed ?
                                    (<div>
                                        <ReactToPrint
                                            trigger={()=><Button  type='button' round size='sm'>Print</Button>}
                                            content={()=>this.componentRef}
                                        />{
                                        this.props.roundData.roundType !== 'Final' && this.props.roundData.roundType !== 'Uncontested'?
                                            <PrintRoundResult
                                                sectionType={sectionType}
                                                callback={renderDancers(this.props.dancers,this.props.roundData)}
                                                data={this.props.eventDetails}
                                                sectionTitle={this.props.title}
                                                roundData={this.props.roundData}
                                                ref={ele=>(this.componentRef=ele)}
                                            />:
                                            <PrintFinalResult
                                                sectionType={sectionType}
                                                dancers={this.props.dancers}
                                                data={this.props.eventDetails}
                                                sectionTitle={this.props.title}
                                                roundData={this.props.roundData}
                                                sectionRounds={this.props.sectionRounds}
                                                ref={ele=>(this.componentRef=ele)}
                                            />
                                    }

                                    </div>):<div>Results not Processed yet</div>

                            )
                        }
                    ]}
                />
            </div>
        )
    }
}
export default connect(null,{removeMarks, processFinalMarks,processRoundMark})(MarksCompetitiveComponent);
