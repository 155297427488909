import React from 'react';
import {connect} from 'react-redux';
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CardHeader from "../../../components/Card/CardHeader";
import CardBody from "../../../components/Card/CardBody";
import Card from "../../../components/Card/Card";
import Table from "../../../components/Table/Table";
import {InputAdornment} from "@material-ui/core";
import Search from "@material-ui/icons/Search";
import CustomInput from "../../../components/CustomInput/CustomInput";

class ScrutinEntries extends React.Component{

    state={
      search:''
    };

    renderEntries =()=>{
        const sections = this.props.sections;
        const search = this.state.search.toLocaleLowerCase();
      if(sections !==''){
          const tableData = Object.keys(sections).reduce((a,v)=>{

              if(sections[v].dancers !== undefined){
                  Object.keys(sections[v].dancers).map(dancer=>{

                      const number = sections[v].dancers[dancer].number;
                      const names = sections[v].dancers[dancer].names;
                      const studio = sections[v].dancers[dancer].studio;
                      const title = sections[v].title;

                      if(number.includes(search) || names.toLocaleLowerCase().includes(search) ||
                      studio.toLocaleLowerCase().includes(search) || title.toLocaleLowerCase().includes(search)
                      ){
                          a.push([
                              number,
                              names,
                              studio,
                              title])
                      }


                  })
              }
              return a;
          },[]);
          const tableHead = ['No','Name','Studio', 'Section'];

          return (
              <Table
                tableHead={tableHead}
                tableData={tableData}
                tableHeaderColor={'rose'}
              />
          )
      }
    };
    handleChange = name => event => {
        this.setState({[name]:event.target.value})
    };


    render() {
        return(
            <div>
                <GridContainer>
                    <GridItem xs={12}>
                        <Card>
                            <CardHeader color='rose'>
                                <h4>Entries</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} md={8}/>
                                    <GridItem xs={12} md={4}>
                                        <CustomInput
                                            labelText="Search"
                                            id="Search"
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                            inputProps={{
                                                onChange:(this.handleChange('search')),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Search/>
                                                    </InputAdornment>
                                                )
                                            }}
                                        />
                                    </GridItem>
                                    <GridItem xs={12}>
                                        {this.renderEntries()}
                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        )
    }
}
const mapStateToProps = state =>{
    let sections ='';
    if(state.eventData.loaded){
        if(state.eventData.sections !== undefined){
            sections = state.eventData.sections;
        }
    }

  return {
      sections:sections
  }
};
export default connect(mapStateToProps)(ScrutinEntries);