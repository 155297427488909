import {database} from "../../../Firebase";
import {
    DANCER_ADDED,
    LOAD_SECTION,
    REMOVE_MARKS,
    REMOVE_SECTION,
    SECTION_ADDED,
    SET_MARKS,
    SET_MULTI_MARKS
} from "../../types";


//add Sections
export const addSection =(formValues, callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections`);
    ref.push({
        title:formValues.title ,
        type:formValues.type,
        roundNo:0,
    }, err=>{if(err){callback({status:2,msg:err.message})}})
        .then(()=>{
            dispatch({type:SECTION_ADDED});
            callback({status:1})
        })

};

//delete section
export const deleteSection =(id,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/sections/${id}`);
    ref.remove().then(()=>callback({status:1}));
};
//update section details
export const updateSectionDetails =(formValues,callback)=>(dispatch,getState)=>{
    const eventId=getState().eventId.eventId;
    const sectionId = getState().section.id;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}`);
    ref.update({
        roundNo:formValues.roundNo,
        title:formValues.title,
        type:formValues.type,
        nextRound:formValues.nextRound !==undefined ? formValues.nextRound :0
    },err=>{if(err){callback({status:2, msg:err.message})}}).then(()=>{
        const sectionData = getState().eventData.sections[sectionId];
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data:sectionData}});
        callback({status:1});
    })
};

//load section view
export const viewSection = (id,callback)=>(dispatch, getState)=>{
    const data= getState().eventData.sections[id];
    dispatch({type:LOAD_SECTION, payload:{id:id,data:data}});
    callback();
};
//close section
export const closeSection = (callback)=>(dispatch)=>{
    dispatch({type:REMOVE_SECTION});
    callback();
};

//add dancer
export const addDancer = (formValues, callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const sectionId = getState().section.id;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}/dancers`);

    ref.push({
        addedBy:'scrutineer',
        timeStamp:Date.now(),
        number:formValues.number,
        names:formValues.names,
        studio:formValues.studio !==undefined ? formValues.studio:'',
        heat:formValues.heat !== undefined? formValues.heat:null,
        teacher:formValues.teacher !== undefined? formValues.teacher:null
    },err=>{if(err){callback({status:2,msg:err.message})}}).then(()=>{
        dispatch({type:DANCER_ADDED});
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callback({status:1});
    })
};

//delete dancer
export const deleteDancer =(id,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const sectionId = getState().section.id;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}/dancers/${id}`);
    ref.remove().then(()=>{
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callback();
    })
};
//update dancer
export const updateDancer =(id,formValues,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const sectionId = getState().section.id;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}/dancers/${id}`);
    ref.update({
        number:formValues.number,
        names:formValues.names,
        studio:formValues.studio !==undefined ? formValues.studio:'',
        heat:formValues.heat !== undefined? formValues.heat:null,
        teacher:formValues.teacher !== undefined? formValues.teacher:null
    },err=>{if(err){callback({status:2,msg:err.message})}}).then(()=>{
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callback({status:1});
    })
};

//add round
export const addRound = (values,callback)=>(dispatch,getState)=>{
    const panels = getState().eventData.panels;
    const panelNumber = values.panel.split('-');
    const panel = Object.keys(panels).reduce((a,v,i)=>{
        if(parseInt(panelNumber[0]) === i+1){
            a['id']=v;
            a['number']=panelNumber[0];
            a['letters']=panelNumber[1];
            a['panel']=values.panel
        }
        return a
    },{});

    const eventId = getState().eventId.eventId;
    const sectionId = getState().section.id;
    const roundNum =  getState().section.data.rounds !== undefined ?
        Object.keys(getState().section.data.rounds).length+1: 1;

    const sectionDancers = getState().section.data.dancers;

    const startList =  roundNum ===1?
        Object.keys(sectionDancers).reduce((a,v)=>{
            a.push(sectionDancers[v].number);
            return a;
        },[]): [];

    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds`);
    ref.push({
        dances:values.selectedDances,
        roundType:values.roundType,
        panel:panel,
        itemNo:values.itemNo,
        status:values.status,
        callbackLimit: values.roundType !== 'Final'? values.callBackLimit:'Not-required',
        roundNo:roundNum,
        startList:startList.sort(),
        processed:false,
        checked:false
    }).then(()=>{
        if(roundNum === 1){
            const sectionRef = database.ref(`/events/${eventId}/sections/${sectionId}`);
            sectionRef.update({
                roundNo:1,
                nextRound:1 +'-'+values.roundType
            }).then(()=>{
                dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});

                callback();
            })
        }else{
            dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
            callback();
        }

    })
};
//delete round
export const deleteRound = (id,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const sectionId = getState().section.id;
    const ref= database.ref(`events/${eventId}/sections/${sectionId}/rounds/${id}`);
    ref.remove().then(()=>{
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callback()});
};
//update round
export const updateRound = (values,callback)=>(dispatch,getState)=>{
    const panels = getState().eventData.panels;
    const panelNumber = values.panel.split('-');
    let panel = Object.keys(panels).reduce((a,v,i)=>{
        if(parseInt(panelNumber[0]) === i+1){
            a['id']=v;
            a['number']=panelNumber[0];
            a['letters']=panelNumber[1];
            a['panel']=values.panel
        }
        return a
    },{});

    const eventId = getState().eventId.eventId;
    const sectionId = getState().section.id;



    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${values.id}`);
    ref.update({
        dances:values.selectedDances,
        roundType:values.roundType,
        panel:panel=== undefined?'1-ABC':panel,
        itemNo:values.itemNo,
        status:values.status,
        callbackLimit: values.roundType !== 'Final'? values.callBackLimit:'Not-required'
    }).then(()=>{
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callback();
    })
};

//set start list for round
export const setStartList =(sectionId, roundId,values,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
    ref.update({startList:values}).then(()=>{
        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callback({status:1})
    })

};

//updateActiveList for Round
export const updateStartList = (values,sectionId,callBack)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${values.id}`);
    ref.update({
        activeList:values.activeList,
        status:'ready'
    }).then(()=>{

        dispatch({type:LOAD_SECTION,payload: {id:sectionId,data: getState().eventData.sections[sectionId]}});
        callBack()
    })
};

//import sections
export const importSections =(values,sortedSections,callback)=> async (dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref =database.ref(`/events/${eventId}/sections`);
    const sectionsKeyArray = Object.keys(values);

    const sections ={};

    const key=Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    const secondKey=Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    sectionsKeyArray.forEach((section,i)=>{
        const dancers ={};

        values[section].forEach((dancer,index)=>{
            dancers["-"+secondKey+index]=dancer
        });



        sections["-"+key+sortedSections[section].number]={
            title:section,
            type:sortedSections[section].type,
            roundNo:0,
            dancers: dancers
        };
    });
    ref.update(sections).then(()=>callback());
};

//add manual marks
export const addManualMarks = (sectionId,roundId, marks,specialMarks,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);

    const marksLog = Object.keys(marks).reduce((a,v)=>{

        a[v]={time: Date.now(),device:'manual'};

        return a;
    },{});

    ref.update({
        marks:marks,
        marksLog,
        specialMarks:specialMarks !== undefined?specialMarks:null
    }).then(()=>{

        const roundRef = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
        roundRef.update({
            status: 'processing'
        }).then(()=>callback({status:1}))
    });

};
// delete marks
export const deleteMarks = (sectionId, roundId, callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
    ref.child('marks').remove().then(()=>{
        ref.child('results').remove();
        ref.child('callbacksLeft').remove();
        ref.child('marksLog').remove();

        const roundRef = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
        roundRef.update({
            processed:false,
            status:'ready'
        }, err=>{if(err){callback({status:2,msg:err.message})}}).then(()=>callback({status:1}))
    }).catch(err=>callback({status:2,msg:err.message}))
};

//set marks for store
export const setMarks = (sectionId, roundId,roundData, callback)=>(dispatch)=>{
    dispatch({type:SET_MARKS, payload:{sectionId, roundId, roundData}});
    callback();
};
//remove marks from store
export const removeMarks =(callback)=>(dispatch)=> {
    dispatch({type:REMOVE_MARKS});
    callback();
};

// set marks for multidance sections
export const setMultiDanceMarks=(sectionId,sectionData,callback)=>(dispatch)=>{
    dispatch({type:SET_MULTI_MARKS,payload:{sectionId,sectionData}});
    callback();
};

//process round marks
export const processRoundMark =(callbackNums,nextRoundId,roundId,sectionId,placingResult,callback)=>(dispatch,getState)=>{

    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
    ref.update({
        results:callbackNums,
        processed:true,
        processedTime: Date.now(),
        status:'checking',
        checked:false,
        placingResult
    }).then(()=>{
        const nextRoundRef =database.ref(`events/${eventId}/sections/${sectionId}/rounds/${nextRoundId}`);
        nextRoundRef.update({
            startList:callbackNums,
            status:'active',
        }).then(()=>{
            const nextRoundData = getState().eventData.sections[sectionId].rounds[nextRoundId];
            const sectionRef = database.ref(`events/${eventId}/sections/${sectionId}`);
            sectionRef.update({
                roundNo:nextRoundData.roundNo,
                nextRound:nextRoundData.roundNo +'-'+nextRoundData.roundType
            }).then(()=>{
                const roundData = getState().eventData.sections[sectionId].rounds[roundId];
                dispatch({type:SET_MARKS, payload:{sectionId, roundId, roundData}});
                callback();
            });


        })
    })


};

//process final marks
export const processFinalMarks=(result,roundId,sectionId,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}/rounds/${roundId}`);
    ref.update({
        results:result,
        processed:true,
        processedTime: Date.now(),
        status:'checking',
        checked:false,
    }).then(()=>{
        const roundData = getState().eventData.sections[sectionId].rounds[roundId];
        dispatch({type:SET_MARKS, payload:{sectionId, roundId, roundData}});
        callback();
    })
};

export const processMultiFinalMarks=(result,sectionId,callback)=>(dispatch, getState)=>{
    const eventId = getState().eventId.eventId;

    const ref = database.ref(`events/${eventId}/sections/${sectionId}`);
    ref.update({
        processed:true,
        processedTime:Date.now(),
        checked:false,
        finalResult:result
    }, err=>err?callback({status:2,msg:err.message}):null)
        .then(()=>{
            const sectionData=getState().eventData.sections[sectionId];
            dispatch({type:SET_MULTI_MARKS,payload:{sectionId,sectionData}});
            callback({status:1});
        })
};

export const deleteMultiFinalMarks=(sectionId,callback)=>(dispatch,getState)=>{
    const eventId = getState().eventId.eventId;
    const ref = database.ref(`events/${eventId}/sections/${sectionId}`);
    ref.child('finalResult').remove();
    ref.child('processed').remove();
    ref.child('checked').remove();
    ref.child('processedTime').remove();

    callback({status:1});
};
